import { defineStore } from "pinia";
import {
  getSimilarValueChains,
  getSimilarCompaniesFromInputOutput,
  getSimilarFromInputOutputModel,
  getSimilarityMatrix,
} from "@/api/similarity";

import {
  Company,
  Input,
  Output,
  ModelStatus,
  ValueChainSummary,
  CandidateSummary,
  Descriptor,
  ModelSummary,
  Process,
} from "@/types";

import {
  chartFirstLayer,
  chartFourthLayer,
  chartSecondLayer,
  chartThirdLayer,
  createModel,
  getModel,
  listGraphs,
  updateGraphName,
} from "@/api/graph";
import { getValueChainSummary, listValueChains } from "@/api/value_chains";
import { useGlobalDatasStore } from "@/stores/global_datas";
import { useClusterEditStore } from "./cluster_edit";
import { useCompaniesListStore } from "./companies_list";
import { useGraphsListStore } from "./graphs_list";
import { useLoader } from "@/stores/loader";

export class ChartRegionResiData {
  labels = [];

  datasets = [
    {
      label: [],
      backgroundColor: [
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
        "#4BEA88",
      ],
      data: [],
      borderRadius: 2,
      code: ["", ""],
    },
  ];
}

export class ChartRegionResiData2 {
  labels = [];

  datasets = [
    {
      label: [],
      backgroundColor: [
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
        "#06B348",
      ],
      data: [],
      borderRadius: 2,
      code: ["", ""],
    },
  ];
}

export class ChartRegionResiData3 {
  labels = [];

  datasets = [
    {
      label: [],
      backgroundColor: [
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
        '#2A7948',
      ],
      data: [],
      borderRadius: 2,
      code: ["", ""],
    },
  ];
}

export const useGraphStore = defineStore({
  id: "graph",
  state: () => ({
    addedByMe: 0,
    graphId: -1,
    title: "",
    crtInput: new Input(),
    crtOutput: new Output(),
    crtProcess: new Process(),
    isModel: false,
    confidential: false,
    isModified: false,
    modelStatus: new ModelStatus(),

    chartData: new ChartRegionResiData(),
    chartData2: new ChartRegionResiData2(),
    chartData3: new ChartRegionResiData3(),

    chartNutData: new ChartRegionResiData(),
    chartSecNutData: new ChartRegionResiData2(),
    chartThirdNutData: new ChartRegionResiData3(),

    chartDataNetworkResi: new ChartRegionResiData(),
    chartDataNetworkResi2: new ChartRegionResiData2(),
    chartDataNetworkResi3: new ChartRegionResiData3(),

    chartNutResiData: new ChartRegionResiData(),
    chartSecNutResiData: new ChartRegionResiData2(),
    chartThirdNutResiData: new ChartRegionResiData3(),

    chartDataReg: new ChartRegionResiData(),
    chartDataReg2: new ChartRegionResiData2(),
    chartDataReg3: new ChartRegionResiData3(),
    
    chartRegionData: new ChartRegionResiData(),
    chartSecRegionData: new ChartRegionResiData2(),
    chartThirdRegionData: new ChartRegionResiData3(),

    chartDataRegResi: new ChartRegionResiData(),
    chartDataRegResi2: new ChartRegionResiData2(),
    chartDataRegResi3: new ChartRegionResiData3(),

    chartRegionResiData: new ChartRegionResiData(),
    chartSecRegionResiData: new ChartRegionResiData2(),
    chartThirdRegionResiData: new ChartRegionResiData3(),

    networkChart: false,
    networkChart2: false,
    networkChart3: false,

    networkResiChart: false,
    networkResiChart2: false,
    networkResiChart3: false,

    regionChart: false,
    regionChart2: false,
    regionChart3: false,

    regionResiChart: false,
    regionResiChart2: false,
    regionResiChart3: false,

    visibleList: false,
    visibleSolution: false,
    visibleResiList: false,
    visibleResiSolution: false,
    visibleRegionResiList: false,
    visibleRegionResiSolution: false,
    visibleListRegion: false,
    visibleSolutionRegion: false,
    visibleListType: false,
    visibleSolutionType: false,
    coreID: null,
    chartRing: "<<<---Click on the chart Rings",
    chartSecRing: "",
    chartThirdRing: "",
    chartResiRing: "<<<---Click on the chart Rings",
    chartSecResiRing: "",
    chartThirdResiRing: "",
    chartRegionRing: "<<<---Click on the chart Rings",
    chartSecRegionRing: "",
    chartThirdRegionRing: "",
    chartRegionResiRing: "<<<---Click on the chart Rings",
    chartSecRegionResiRing: "",
    chartThirdRegionResiRing: "",
    chartCompanies: -1 as number,
    chartEmployees: -1 as number,
    chartRevenue: -1 as number,
    chartResiCompanies: -1 as number,
    chartResiEmployees: -1 as number,
    chartResiRevenue: -1 as number,
    chartCompaniesRegion: -1 as number,
    chartEmployeesRegion: -1 as number,
    chartRevenueRegion: -1 as number,
    chartRegionResiCompanies: -1 as number,
    chartRegionResiEmployees: -1 as number,
    chartRegionResiRevenue: -1 as number,

    naceSearchCode: null as null | string,
    clusterRegion: "",

    showBiolinkModal: false,
    addBiolinkName: "",
    addBiolinkDescription: "",
    biolinkCompanyA: {},
    biolinkCompanyB: {},
    applyModelName: "",
    applyModelRegion: "",
    applySolutionName: "",
    loaded: false,
    loadedAddMore: true,
    nbPages: 1,
    editNode: false,
    crtPage: 1,
    isProject: false,
    totalCount: 0,
    currentValueChain: new ValueChainSummary(),
    addEditInput: new Input(),
    isInputReadonly: false,
    isCompanyReadonly: false,
    addEditOutput: new Output(),
    isOutputReadonly: false,
    valueChains: [] as ValueChainSummary[],
    candidates: [] as CandidateSummary[],
    models: [] as ModelSummary[],
    showVirtual: false,
    showSolutionProvider: false,
    showedCompany: new Company(),
    graphGeoCenter: {} as { [id: string]: number } | null,
    nodesCoordinates: {} as { [id: string]: any },
    nodeCompanyModal: new Company(),
    editnodeModal: false,
    last_modal_type: "company",
    modal_full_height: false,
    propertyModified: false,
    isNewEditGraph: false,
    modified: false,

    newVirtualCompany: new Company(),
    newValueChain: new Descriptor(),

    //Input / output modals related
    openAddEditInputModal: false,
    openCompanyEditModal: false,
    openAddEditOutputModal: false,

    selectedNode: -1 as number,
    selectedCompany: -1 as number,
    alternativeSearch: false,
    suggestedIO: false,
    suggestedIOModel: false,
    showAdvancedSearch: false,
    checked: true,
    checkedType: true,
    // Determine which button has been clicked for the modal
    isAddValueChainClicked: false,
    isAddProcessClicked: false,

    // filter
    sortColumn: "iop_sum",
    sortOrder: "desc",
    typeSelected: null,
    countrySelected: "",
    naceSearch: null,
    textSearch: "" as string,
    keywordSearch: null,
    filteredCompanyId: null,

    // similarity
    similarityMatrix: [],
    similarityMode: false,
    originValueChainSocketClick: -1,
    socketClickedId: -1,
    socketClickedName: "",
    socketClickedType: "",
    modelClickedId: -1,
    selectedNodeDatas: new ValueChainSummary(),

    // loader (spinner)
    loader: useLoader(),
  }),
  getters: {
    getTitle: (state) => state.title,
    getIsModified: (state) => state.isModified,
    getValueChains: (state) => state.valueChains,
  },
  actions: {
    async loadMore() {
      return this.fetchValueChains(this.crtPage + 1, true);
    },
    async loadMoreValueChains() {
      return this.fetchValueChains(this.crtPage + 1, true);
    },
    resetFilters() {
      this.typeSelected = null;
      this.countrySelected = "";
      this.naceSearch = null;
      this.textSearch = "";
      this.keywordSearch = null;
      this.loadedAddMore = true;
      this.crtPage = 1;
      this.filteredCompanyId = null;
    },
    resetFiltersAndEmptyList() {
      this.resetFilters();
      this.valueChains.splice(0);
      this.applyModelName = "";
      this.applyModelRegion = "";
      this.applySolutionName = "";
    },
    removeSolutionNaceEntry(to_delete: string) {
      const index = this.newValueChain.additionnal_naces.indexOf(to_delete);
      if (index > -1) {
        // only splice array when item is found
        this.newValueChain.additionnal_naces.splice(index, 1); // 2nd parameter means remove one item only
      }
    },
    addSolutionNaceEntry() {
      this.newValueChain.additionnal_naces.push("");
    },
    removeCompanyNaceEntry(to_delete: string) {
      const index = this.newVirtualCompany.additionnal_naces.indexOf(to_delete);
      if (index > -1) {
        // only splice array when item is found
        this.newVirtualCompany.additionnal_naces.splice(index, 1); // 2nd parameter means remove one item only
      }
    },
    isBiolink() {
      return this.isModel == false;
    },
    addCompnyNaceEntry() {
      this.newVirtualCompany.additionnal_naces.push("");
    },
    setAddBtnsState(btnId: number) {
      if (btnId === 1) {
        this.isAddValueChainClicked = true;
        this.isAddProcessClicked = false;
        return;
      }

      if (btnId === 2) {
        this.isAddValueChainClicked = false;
        this.isAddProcessClicked = true;
        return;
      }
    },
    unselectNode() {
      console.log("unselectNode");
      this.resetFilters();
      this.valueChains = [];
      this.selectedNode = -1;
      this.selectedCompany = -1;
      this.alternativeSearch = false;
      this.suggestedIO = false;
    },
    resetBtnClick() {
      this.isAddValueChainClicked = false;
      this.isAddProcessClicked = false;
    },
    async fetchValueChains(
      crtPage: number,
      loadMore = false,
      isVcModal = false
    ) {
      if (this.selectedNode != -1) {
        this.selectedNodeDatas = await getValueChainSummary(this.selectedNode);
      }
      // if we are in infinite scroll and the requested page is greater than the maximum page, we do not do anything
      if (loadMore && crtPage > this.nbPages) {
        this.loadedAddMore = true;
        this.loaded = true;
        this.loader.done();
        return;
      }

      // current page is updated
      this.crtPage = crtPage;

      if (loadMore) this.loadedAddMore = false;
      else this.loaded = false;

      // no need to specify order for similar companies, by default ordered by similarity
      let params: Record<string, unknown>;
      if (this.similarityMode) {
        if (this.alternativeSearch) {
          params = {
            page: crtPage,
            perPage: 20,
            vcId: this.selectedNode,
          };
        } else {
          params = {
            page: crtPage,
            perPage: 20,
            inOutId: this.socketClickedId,
            inOutType: this.socketClickedType,
            vcId: this.originValueChainSocketClick,
          };
        }
      } else
        params = {
          page: crtPage,
          perPage: 20,
          sortColumn: this.sortColumn,
          sortOrder: this.sortOrder,
        };
      // location
      if (this.getLocation() != null) {
        params["latitude"] = this.getLocation()["latitude"];
        params["longitude"] = this.getLocation()["longitude"];
      }

      if (this.filteredCompanyId !== null) {
        params["companyId"] = this.filteredCompanyId;
      }

      if (this.isAddProcessClicked) {
        params["processFilter"] = 1;
      }

      if (this.typeSelected !== null) {
        params["typeFilter"] = this.typeSelected;
      }
      if (this.countrySelected) {
        params["regionFilter"] = this.countrySelected;
      }
      if (this.naceSearch !== null) {
        params["naceFilter"] = this.naceSearch;
      }
      if (this.keywordSearch !== null && this.keywordSearch != "") {
        params["keywordFilter"] = this.keywordSearch;
      }
      if (this.textSearch !== null && this.textSearch.length > 2) {
        // replace double comma and trim comma
        params["textFilter"] = this.textSearch
          .replace(",,", ",")
          .replace(/^,+|,+$/g, "");
      }

      params["virtual"] = this.showVirtual ? 1 : 0;
      params["solutionProvider"] = this.showSolutionProvider ? 1 : 0;

      let value_chains = null;
      console.log("SENDING DATA: ", params);
      if (this.similarityMode) {
        if (this.alternativeSearch) {
          value_chains = await getSimilarValueChains(params);
        } else if (this.suggestedIO || this.showSolutionProvider)
          value_chains = await getSimilarCompaniesFromInputOutput(params);
        else {
          value_chains = await getSimilarFromInputOutputModel(params);
        }
      } else {
        value_chains = await listValueChains(params);
      }

      // if we are in the infinite, we add the companies and if not, we replace the current companies
      if (loadMore && !isVcModal) {
        this.valueChains.push(...value_chains.items);
      } else {
        this.valueChains.splice(0);
        this.valueChains.push(...value_chains.items);
      }

      this.nbPages = value_chains.nb_pages;
      this.crtPage = value_chains.page;
      this.totalCount = value_chains.total_count;

      if (loadMore) this.loadedAddMore = true;
      else this.loaded = true;

      //load candidates
      //this.candidates = getCandidates(this.graphId);
    },
    getLocation() {
      // if current node has location (company or has candidates) -> return that
      if (this.selectedNode.toString() in this.nodesCoordinates) {
        return this.nodesCoordinates[this.selectedNode.toString()];
      }
      // else if user has location -> return that
      const globalStore = useGlobalDatasStore();
      if ("latitude" in globalStore.logged_user) {
        return {
          latitude: globalStore.logged_user.latitude,
          longitude: globalStore.logged_user.longitude,
        };
      }
      // else if graph center is set -> return that
      if (
        this.graphGeoCenter != null &&
        Object.keys(this.graphGeoCenter).length > 0
      ) {
        return this.graphGeoCenter;
      }
      // else -> return null
      return null;
    },

    async fetchModels(crtPage: number) {
      // current page is updated
      this.crtPage = crtPage;
      this.loaded = false;

      // no need to specify order for similar companies, by default ordered by similarity
      const params: Record<string, unknown> = {
        page: crtPage,
        perPage: 20,
        model: 1,
      };

      if (this.textSearch !== null && this.textSearch.length > 2) {
        // replace double comma and trim comma
        params["searchText"] = this.textSearch
          .replace(",,", ",")
          .replace(/^,+|,+$/g, "");
      }

      let models = null;
      models = await listGraphs(params);

      this.models.splice(0);
      this.models.push(...models.items);

      this.nbPages = models.nb_pages;
      this.crtPage = models.page;
      this.totalCount = models.total_count;

      this.loaded = true;
    },

    async addGraphModel(id_graph: number) {
      console.log("GRAPH ID:", this.graphId);
      console.log("MODEL STATUS Contributor:", this.modelStatus.contributor);
      console.log("MODEL STATUS ID:", this.modelStatus.id_model_status);
      console.log(
        "MODEL STATUS has Valoristaion:",
        this.modelStatus.has_valorisation
      );
      console.log(
        "MODEL STATUS has Cost Saving:",
        this.modelStatus.has_cost_saving
      );
      console.log(
        "MODEL STATUS has Pos Env Impact:",
        this.modelStatus.has_pos_env_impact
      );
      console.log(
        "MODEL STATUS has Soc Impact:",
        this.modelStatus.has_soc_impact
      );
      console.log("MODEL STATUS status:", this.modelStatus.status);
      console.log("MODEL STATUS TRL:", this.modelStatus.trl);
      console.log(
        "MODEL STATUS support request:",
        this.modelStatus.support_request
      );
      await updateGraphName(id_graph, {name: this.title})
      return await createModel(id_graph, this.modelStatus);
    },

    getModel(graphId: number) {
      console.log("GRAPHID:", graphId);
      getModel(graphId).then((biolinkModel) => {
        console.log("GETTING MODEL NOW");
        if (biolinkModel === null || biolinkModel.error) {
          return;
        }

        console.log(biolinkModel, "GETTING MODEL NOW");
        this.modelStatus.contributor = {
          id_contributor: biolinkModel.contributor_ref.id_contributor_ref,
          contributor_name: biolinkModel.contributor_ref.contributor_name,
          id_contributor_ref_type:
            biolinkModel.contributor_ref.id_contributor_ref_type,
        };
        this.modelStatus.id_model_status =
          biolinkModel.biolink_model.id_model_status;
        this.modelStatus.has_valorisation = biolinkModel.biolink_model
          .has_valorisation
          ? true
          : false;
        this.modelStatus.has_cost_saving = biolinkModel.biolink_model
          .has_cost_saving
          ? true
          : false;
        this.modelStatus.has_pos_env_impact = biolinkModel.biolink_model
          .has_pos_env_impact
          ? true
          : false;
        this.modelStatus.has_soc_impact = biolinkModel.biolink_model
          .has_soc_impact
          ? true
          : false;
        this.modelStatus.status = biolinkModel.biolink_model.status_name;
        this.modelStatus.trl = biolinkModel.biolink_model.trl;
        this.modelStatus.application_classification =
          biolinkModel.biolink_model.application_classification;
        this.modelStatus.processes_classification =
          biolinkModel.biolink_model.processes_classification;
        this.modelStatus.support_request =
          biolinkModel.biolink_model.support_request;
        this.modelStatus.support_other_info =
          biolinkModel.biolink_model.support_other_info;
        console.log("MODEL STATUS Contributor:", this.modelStatus.contributor);
        console.log("MODEL STATUS ID:", this.modelStatus.id_model_status);
        console.log(
          "MODEL STATUS has Valoristaion:",
          this.modelStatus.has_valorisation
        );
        console.log(
          "MODEL STATUS has Cost Saving:",
          this.modelStatus.has_cost_saving
        );
        console.log(
          "MODEL STATUS has Pos Env Impact:",
          this.modelStatus.has_pos_env_impact
        );
        console.log(
          "MODEL STATUS has Soc Impact:",
          this.modelStatus.has_soc_impact
        );
        console.log("MODEL STATUS status:", this.modelStatus.status);
        console.log("MODEL STATUS TRL:", this.modelStatus.trl);
      });
    },
    resetModel() {
      this.modelStatus = new ModelStatus();
    },
    setTitle(title: string) {
      this.title = title;
    },
    onEditInputClicked(input: Input, readonly: boolean) {
      console.log("Add edit set triggered");
      console.log(input);
      this.isInputReadonly = readonly;
      this.addEditInput = input;
      this.openAddEditInput();
    },
    onEditCompany(company: Company, readonly: boolean) {
      console.log(company, "compnaaaaaaaa");
      this.isCompanyReadonly = readonly;
      this.showedCompany = company;
      this.openCompanyEdit();
    },
    onEditOutputClicked(output: Output, readonly: boolean) {
      console.log("Add edit set triggered");
      console.log(output);
      this.isOutputReadonly = readonly;
      this.addEditOutput = output;
      this.openAddEditOutput();
    },
    setIsModified(modified: boolean) {
      this.isModified = modified;
    },
    setGraphId(graphId: number) {
      this.graphId = graphId;
    },
    setShowBiolinkModal(value: boolean) {
      this.showBiolinkModal = value;
    },
    setBiolinkCompanyA(value: object) {
      this.biolinkCompanyA = value;
    },
    setBiolinkCompanyB(value: object) {
      this.biolinkCompanyB = value;
    },
    setAddBiolinkName(value: string) {
      this.addBiolinkName = value;
    },
    setAddBiolinkDescription(value: string) {
      this.addBiolinkDescription = value;
    },
    setCurrentValueChain(value: object) {
      // @ts-ignore
      this.currentValueChain = value;
    },
    _isRealCoordinates(lat: number, lon: number) {
      return lat != null && lon != null && (lat != 0 || lon != 0);
    },
    addNode(nodeId: number, address: any) {
      if (this._isRealCoordinates(address.latitude, address.longitude)) {
        this.nodesCoordinates[nodeId.toString()] = {
          latitude: address.latitude,
          longitude: address.longitude,
        };
        this.graphGeoCenter = this.computeCenter(this.nodesCoordinates);
      }
    },
    removeNode(nodeId: number) {
      console.log("REMOVENODE1", this.graphGeoCenter);
      delete this.nodesCoordinates[nodeId.toString()];
      for (let i = 0; i < this.candidates.length; i = i + 1) {
        const c = this.candidates[i];
        if (c.id_vc == nodeId && c.id_gr == this.graphId) {
          this.candidates.splice(i, 1);
        }
      }
      this.graphGeoCenter = this.computeCenter(this.nodesCoordinates);
      console.log("REMOVENODE2", this.graphGeoCenter);
    },
    addValueChain(vc: any) {
      this.addNode(vc.id, [
        vc.company.address.latitude,
        vc.company.address.longitude,
      ]);
    },
    updateCenterCandidate(parentIdGr: number, parentIdVc: number) {
      if (
        parentIdVc.toString() in this.nodesCoordinates &&
        !this.nodesCoordinates[parentIdVc.toString()].fromCandidates
      ) {
        return;
      }

      //compute node's new center
      const vcCand = this.getCandidates(parentIdVc, parentIdGr);
      if (Object.keys(vcCand).length < 1) {
        //no more candidates
        delete this.nodesCoordinates[parentIdVc.toString()];
        return;
      }
      console.log("CURRENT CANDIDATES", vcCand);
      const vcCenter = this.computeCenter(vcCand);
      this.nodesCoordinates[parentIdVc.toString()] = vcCenter;
      this.nodesCoordinates[parentIdVc.toString()]["fromCandidates"] = true;
      //recompute graph center
      this.graphGeoCenter = this.computeCenter(this.nodesCoordinates);
    },
    //called when loading the graph
    async loadGraph(graph: any) {
      const graphObj = JSON.parse(graph.data);
      console.log("GRAPHOBJ Nodessss", graphObj);
      if (!Object.prototype.hasOwnProperty.call(graphObj, "nodes")) {
        this.graphGeoCenter = { latitude: 0, longitude: 0 };
        this.nodesCoordinates = {};
        return;
      }
      const nodes = graphObj.nodes;
      const convNodes = {} as any;
      Object.keys(nodes).forEach((key) => {
        const coord = nodes[key].data;
        if (this._isRealCoordinates(coord.latitude, coord.longitude)) {
          //set company centers
          convNodes[key] = {
            latitude: coord.latitude,
            longitude: coord.longitude,
            fromCandidates: false,
          };
        } else if (nodes[key].candidates.length > 0) {
          //set candidate centers
          const cand = {} as any;
          let candidatesHaveAddress = false;
          for (let i = 0; i < nodes[key]["candidates"].length; i = i + 1) {
            console.log("CANDIDATES LOAD", nodes[key]["candidates"][i]);
            if (
              this._isRealCoordinates(
                nodes[key]["candidates"][i].latitude,
                nodes[key]["candidates"][i].longitude
              )
            ) {
              cand[i.toString()] = {
                latitude: nodes[key]["candidates"][i].latitude,
                longitude: nodes[key]["candidates"][i].longitude,
              };
              candidatesHaveAddress = true;
            }
          }
          if (candidatesHaveAddress) {
            convNodes[key] = this.computeCenter(cand);
            convNodes[key]["fromCandidates"] = true;
          }
        }
        if (nodes[key].candidates.length > 0) {
          for (let i = 0; i < nodes[key]["candidates"].length; i = i + 1) {
            this.addCandidate(
              graph.id,
              parseInt(key),
              nodes[key]["candidates"][i],
              nodes[key]["candidates"][i].name,
              0
            );
          }
        }
      });
      console.log("GRAPHSTORE CANDIDATEEEES", this.candidates);
      //set graph center
      this.graphGeoCenter = this.computeCenter(convNodes);
      this.nodesCoordinates = convNodes;
      console.log("COORDINATES", this.nodesCoordinates);
    },
    //shape of points: {key: {latitude: xx, longitude:xx}}, in degrees
    computeCenter(points: any) {
      if (Object.keys(points).length == 1) {
        return {
          latitude: points[Object.keys(points)[0]].latitude,
          longitude: points[Object.keys(points)[0]].longitude,
        };
      } else if (Object.keys(points).length < 1) {
        return null;
      }
      let x = 0;
      let y = 0;
      let z = 0;
      console.log("POINTS", points);
      Object.keys(points).forEach((key) => {
        if (
          this._isRealCoordinates(
            parseFloat(points[key].latitude),
            parseFloat(points[key].longitude)
          )
        ) {
          const latitude = (parseFloat(points[key].latitude) * Math.PI) / 180;
          const longitude = (parseFloat(points[key].longitude) * Math.PI) / 180;
          //this.nodesCoordinates[key] = [latitude,longitude];
          x += Math.cos(latitude) * Math.cos(longitude);
          y += Math.cos(latitude) * Math.sin(longitude);
          z += Math.sin(latitude);
        }
      });
      const total = Object.keys(points).length;
      x = x / total;
      y = y / total;
      z = z / total;
      const centralLongitude = Math.atan2(y, x);
      const centralSquareRoot = Math.sqrt(x * x + y * y);
      const centralLatitude = Math.atan2(z, centralSquareRoot);
      return {
        latitude: (centralLatitude * 180) / Math.PI,
        longitude: (centralLongitude * 180) / Math.PI,
      };
    },
    openAddEditInput() {
      this.openAddEditInputModal = true;
    },
    openCompanyEdit() {
      this.openCompanyEditModal = true;
    },
    closeAddEditInput() {
      this.openAddEditInputModal = false;
      this.isInputReadonly = false;
    },
    openAddEditOutput() {
      this.openAddEditOutputModal = true;
    },
    closeAddEditOutput() {
      this.openAddEditOutputModal = false;
      this.isOutputReadonly = false;
    },
    onAddInputClicked(value_chain: object) {
      console.log("add edit vc");
      console.log(this.addEditInput);
      this.setCurrentValueChain(value_chain);
      console.log("Current VC:", this.currentValueChain);
      this.addEditInput = new Input();
      //this.addEditInput.value_chain_id = value_chain.id;
      console.log("add edit vc");
      console.log(this.addEditInput);
      this.openAddEditInput();
    },
    onAddOutputClicked(value_chain: object) {
      this.setCurrentValueChain(value_chain);
      this.addEditOutput = new Output();
      this.openAddEditOutput();
    },

    addInputNaceEntry() {
      this.addEditInput.naces.push("");
      //this.modified = true;
    },
    removeInputNaceEntry(to_delete: string) {
      const index = this.addEditInput.naces.indexOf(to_delete);
      if (index > -1) {
        // only splice array when item is found
        this.addEditInput.naces.splice(index, 1); // 2nd parameter means remove one item only
      }
    },
    addOutputNaceEntry() {
      this.addEditOutput.naces.push("");
      //this.modified = true;
    },
    removeOutputNaceEntry(to_delete: string) {
      const index = this.addEditOutput.naces.indexOf(to_delete);
      if (index > -1) {
        // only splice array when item is found
        this.addEditOutput.naces.splice(index, 1); // 2nd parameter means remove one item only
      }
    },
    //returns the candidates of a specific value chain
    getCandidates(vcId: number, grId: number) {
      const ret = {} as { [id: string]: CandidateSummary };
      for (const c of this.candidates) {
        if (c.id_vc == vcId && c.id_gr == grId) ret[c.id_c.toString()] = c;
      }
      return ret;
    },
    addCandidate(
      parentIdGr: number,
      parentIdVc: number,
      candidate: any,
      companyName: string,
      similarity: number
    ) {
      this.candidates.push({
        id_gr: parentIdGr,
        id_vc: parentIdVc,
        id_c: candidate.id != null ? candidate.id : candidate.idCandidate,
        latitude:
          candidate.latitude != null
            ? candidate.latitude
            : candidate.company_latitude,
        longitude:
          candidate.longitude != null
            ? candidate.longitude
            : candidate.company_longitude,
        company_name: companyName,
        similarity: similarity,
      });

      this.updateCenterCandidate(parentIdGr, parentIdVc); //uses the already updated candidates list
    },
    removeCandidate(vcId: number, candId: number) {
      console.log(this.candidates);
      for (let i = 0; i < this.candidates.length; i = i + 1) {
        if (
          this.candidates[i].id_vc == vcId &&
          this.candidates[i].id_c == candId
        ) {
          console.log(this.candidates[i]);
          this.candidates.splice(i, 1);
          break;
        }
      }
      this.updateCenterCandidate(this.graphId, vcId); //uses the already updated candidates list
    },
    exportCandidates() {
      const exported = [];
      for (let i = 0; i < this.candidates.length; i++) {
        const idv = this.candidates[i].id_vc;
        const idg = this.candidates[i].id_gr;
        const idc = this.candidates[i].id_c;
        exported.push({ idv, idg, idc });
      }
      return exported;
    },
    async getSimilarities(crtValueChainId: number) {
      //[[ida, idb, similarity (<- combination of descriptors and nace similarity, as %) ]]
      const rawMatrix = (await getSimilarityMatrix(
        crtValueChainId
      )) as unknown as number[][];
      const matrix = {};
      for (let i = 0; i < rawMatrix.length; i = i + 1) {
        const row = rawMatrix[i];
        const id = row[0] == crtValueChainId ? row[1] : row[0];
        (matrix as any)[id] = row[2];
      }
      return matrix;
    },
    async setKeywordSearch(value: string) {
      // @ts-ignore
      this.keywordSearch = value;
      await this.fetchValueChains(1);
    },
    async setNaceFilter(value: string) {
      // @ts-ignore
      this.naceSearch = value;
      await this.fetchValueChains(1);
    },
    async setCountryFilter(value: string) {
      // @ts-ignore
      this.countrySelected = value;
      await this.fetchValueChains(1);
    },
    async setCompanyTypeFilter(value: string) {
      // @ts-ignore
      this.typeSelected = value;
      await this.fetchValueChains(1);
    },
    async setCompanyIdFilter(value: number) {
      // @ts-ignore
      this.filteredCompanyId = value;
      await this.fetchValueChains(1);
    },
    async setTextSearch(value: string, model = false) {
      // @ts-ignore
      this.textSearch = value.trim();
      if (model === true) {
        await this.fetchModels(1);
      } else {
        await this.fetchValueChains(1);
      }
    },
    async setTextSearchModel(value: string) {
      this.setTextSearch(value, true);
    },
    async fetchAndEditGraphNodeModal(company: any, full_height = false) {
      company.additionnal_naces =
        company.additionnal_naces &&
        company.additionnal_naces.map((item: { nace_code: string }) => {
          return item.nace_code;
        });
      company.keywords =
        company.keywords &&
        company.keywords.map((item: { keyword: string }) => {
          return item.keyword;
        });

      company.company_type = company._company_type_ref;
      company.employees_range = company._employees_range_ref;

      this.nodeCompanyModal = company;
      this.newValueChain.inputs = company.inputs;
      this.newValueChain.inputs &&
        this.newValueChain.inputs.forEach((input: any) => {
          return input.general_name;
        });
      this.newValueChain.outputs = company.outputs;
      this.newValueChain.outputs &&
        this.newValueChain.outputs.forEach((output: any) => {
          return output.general_name;
        });
      this.newValueChain.processes = company.process;
      this.newValueChain.processes &&
        this.newValueChain.processes.forEach((process: any) => {
          return process.general_name;
        });
      this.editnodeModal = true;
      this.last_modal_type = "edit_node";
      this.modal_full_height = full_height;
    },

    async hideEditGraphNodeModal() {
      this.editnodeModal = false;
      this.nodeCompanyModal = new Company();
      this.newValueChain = new Descriptor();
    },
    addInput() {
      this.newValueChain.inputs.push(this.crtInput);
      this.modified = true;
    },
    addOutput() {
      this.newValueChain.outputs.push(this.crtOutput);
      this.modified = true;
    },
    addProcess() {
      this.newValueChain.processes.push(this.crtProcess);
      this.modified = true;
    },
    editInput(descriptorIndex: number, inputIndex: number) {
      this.newValueChain.inputs[inputIndex] = this.crtInput;
      this.modified = true;
    },
    editOutput(descriptorIndex: number, outputIndex: number) {
      this.newValueChain.outputs[outputIndex] = this.crtOutput;
      this.modified = true;
    },
    editProcess(descriptorIndex: number, processIndex: number) {
      this.newValueChain.processes[processIndex] = this.crtProcess;
      this.modified = true;
    },

    async fetchChartNetworkData(
      index: number,
      code: string,
      country?: string,
      region?: string,
      city?: string
    ) {
      this.visibleList = false;
      this.visibleSolution = false;
      this.networkChart = false;
      this.networkChart2 = false;
      this.networkChart3 = false;
      await useClusterEditStore().fetchOurCluster();
      this.loader.add();
      useCompaniesListStore().clusterFilter = useClusterEditStore().cluster.id;

      let clusterLayer;
      const params: Record<string, unknown> = {
        cluster_id: useClusterEditStore().cluster.id,
      };

      if (country) params["country"] = country;
      if (region) params["region"] = region;
      if (city) params["city"] = city;
      if (index == 1) {
        clusterLayer = await chartFirstLayer(params);
        this.chartData.datasets[0] = this.chartNutData.datasets[0];
      }
      if (index == 2) {
        clusterLayer = await chartSecondLayer(params, code);
        this.naceSearchCode = code;
        this.chartData2.datasets[0] = this.chartSecNutData.datasets[0];
      }

      if (index == 3) {
        clusterLayer = await chartThirdLayer(params, code);
        this.naceSearchCode = code.toString();
        this.chartData3.datasets[0] = this.chartThirdNutData.datasets[0];
      }

      if (index == 4) {
        clusterLayer = await chartFourthLayer(params, code);
        this.naceSearchCode = code.toString();
      }

      if (
        clusterLayer.chart_data &&
        clusterLayer.chart_data.length != 0 &&
        index != 4
      ) {
        const dataLabel = clusterLayer.chart_data?.map(
          (item: { name: string }) => {
            return item.name;
          }
        );

        index === 1 && Object.assign(this.chartData.datasets[0], { label: dataLabel });
        index === 2 && Object.assign(this.chartData2.datasets[0], { label: dataLabel });
        index === 3 && Object.assign(this.chartData3.datasets[0], { label: dataLabel });

        const dataValue = clusterLayer.chart_data?.map(
          (item: { value: number }) => {
            return item.value;
          }
        );

        index === 1 && Object.assign(this.chartData.datasets[0], { data: dataValue });
        index === 2 && Object.assign(this.chartData2.datasets[0], { data: dataValue });
        index === 3 && Object.assign(this.chartData3.datasets[0], { data: dataValue });

        const dataCode = clusterLayer.chart_data?.map(
          (item: { code: string }) => {
            return item.code;
          }
        );

        index === 1 && Object.assign(this.chartData.datasets[0], { code: dataCode });
        index === 2 && Object.assign(this.chartData2.datasets[0], { code: dataCode });
        index === 3 && Object.assign(this.chartData3.datasets[0], { code: dataCode });
      }

      this.chartCompanies = clusterLayer.nb_companies;
      this.chartEmployees = clusterLayer.nb_employees;
      this.chartRevenue = clusterLayer.total_revenue;
      this.networkChart = true;
      this.networkChart2 = true;
      this.networkChart3 = true;
      this.loader.done();
    },

    async fetchChartNetworkCompanies(country = "", region = "", city = "") {
      useCompaniesListStore().naceSearch = this.naceSearchCode;

      this.visibleList = true;
      this.visibleSolution = false;

      useCompaniesListStore().isResidual = null;
      useCompaniesListStore().citySelected = city;
      useCompaniesListStore().regionSelected = region;
      useCompaniesListStore().countrySelected = country;
      useCompaniesListStore().clusterFilter = useClusterEditStore().cluster.id;
      useCompaniesListStore().fetchCompanies();
    },
    async fetchChartNetworkSolutions() {
      useGraphsListStore().naceSearch = this.naceSearchCode;
      useGraphsListStore().isModel = 1;

      this.visibleSolution = true;
      this.visibleList = false;
      // if (!this.naceSearchCode) {
        useGraphsListStore().fetchGraphs();
      // } else {
      //   useGraphsListStore().fetchGraphs(); // TODO check this
      //   // useGraphsListStore().fetchGraphWithNaces(this.naceSearchCode);
      // }
    },

    async fetchChartRegionData(
      index: number,
      code: string,
      country?: string,
      region?: string,
      city?: string
    ) {
      this.visibleListRegion = false;
      this.visibleSolutionRegion = false;
      this.regionChart = false;
      this.regionChart2 = false;
      this.regionChart3 = false;
      this.loader.add();
      await useClusterEditStore().fetchOurCluster();
      this.clusterRegion = useClusterEditStore().cluster.address.city;

      let clusterLayer;
      const params: Record<string, unknown> = {
      };

      if (country) params["country"] = country;
      if (region) params["region"] = region;
      if (city) params["city"] = city;
      if (index == 1) {
        clusterLayer = await chartFirstLayer(params);
        this.chartDataReg.datasets[0] = this.chartRegionData.datasets[0];
      }
      if (index == 2) {
        clusterLayer = await chartSecondLayer(params, code);
        this.naceSearchCode = code;
        this.chartDataReg2.datasets[0] = this.chartSecRegionData.datasets[0];
      }

      if (index == 3) {
        clusterLayer = await chartThirdLayer(params, code);
        this.naceSearchCode = code.toString();
        this.chartDataReg3.datasets[0] = this.chartThirdRegionData.datasets[0];
      }

      if (index == 4) {
        clusterLayer = await chartFourthLayer(params, code);
        this.naceSearchCode = code.toString();
      }

      if (
        clusterLayer.chart_data &&
        clusterLayer.chart_data.length != 0 &&
        index != 4
      ) {
        const dataLabel = clusterLayer.chart_data?.map(
          (item: { name: string }) => {
            return item.name;
          }
        );

        index === 1 && Object.assign(this.chartDataReg.datasets[0], { label: dataLabel });
        index === 2 && Object.assign(this.chartDataReg2.datasets[0], { label: dataLabel });
        index === 3 && Object.assign(this.chartDataReg3.datasets[0], { label: dataLabel });

        const dataValue = clusterLayer.chart_data?.map(
          (item: { value: number }) => {
            return item.value;
          }
        );

        index === 1 && Object.assign(this.chartDataReg.datasets[0], { data: dataValue });
        index === 2 && Object.assign(this.chartDataReg2.datasets[0], { data: dataValue });
        index === 3 && Object.assign(this.chartDataReg3.datasets[0], { data: dataValue });

        const dataCode = clusterLayer.chart_data?.map(
          (item: { code: string }) => {
            return item.code;
          }
        );

        index === 1 && Object.assign(this.chartDataReg.datasets[0], { code: dataCode });
        index === 2 && Object.assign(this.chartDataReg2.datasets[0], { code: dataCode });
        index === 3 &&Object.assign(this.chartDataReg3.datasets[0], { code: dataCode });
      }

      this.chartCompaniesRegion = clusterLayer.nb_companies;
      this.chartEmployeesRegion = clusterLayer.nb_employees;
      this.chartRevenueRegion = clusterLayer.total_revenue;
      this.regionChart = true;
      this.regionChart2 = true;
      this.regionChart3 = true;
      this.loader.done();
    },

    async fetchChartRegionCompanies(
      country: string,
      region: string,
      city: string | null
    ) {
      useCompaniesListStore().naceSearch = this.naceSearchCode;

      this.visibleListRegion = true;
      this.visibleSolutionRegion = false;
      this.clusterRegion = country;

      useCompaniesListStore().isResidual = null;
      useCompaniesListStore().countrySelected = country;
      useCompaniesListStore().regionSelected = region;
      useCompaniesListStore().citySelected = city;
      useCompaniesListStore().fetchCompanies();
    },
    async fetchChartRegionSolutions(
      // country: string,
      // region: string,
      // city: string
    ) {
      useGraphsListStore().isModel = 1;
      useGraphsListStore().naceSearch = this.naceSearchCode;
      // useGraphsListStore().countrySearch = country;
      // useGraphsListStore().regionSearch = region;
      // useGraphsListStore().citySearch = city;

      this.visibleSolutionRegion = true;
      this.visibleListRegion = false;
      // if (this.naceSearchCode == null) {
        useGraphsListStore().fetchGraphs();
      // } else {
      //   useGraphsListStore().fetchGraphs(); //check this
      //   // useGraphsListStore().fetchGraphWithNaces(this.naceSearchCode);
      // }
    },
    async fetchChartNetworkResiData(
      index: number,
      code: string,
      country?: string,
      region?: string,
      city?: string
    ) {
      this.visibleResiList = false;
      this.visibleResiSolution = false;
      this.networkResiChart = false;
      this.networkResiChart2 = false;
      this.networkResiChart3 = false;
      this.loader.add();
      await useClusterEditStore().fetchOurCluster();

      let clusterLayer;
      const params: Record<string, unknown> = {
        cluster_id: useClusterEditStore().cluster.id,
      };
      params["is_residual"] = 1;
      if (country) params["country"] = country;
      if (region) params["region"] = region;
      if (city) params["city"] = city;
      if (index == 1) {
        clusterLayer = await chartFirstLayer(params);
        this.chartDataNetworkResi.datasets[0] =
          this.chartNutResiData.datasets[0];
      }
      if (index == 2) {
        clusterLayer = await chartSecondLayer(params, code);
        this.naceSearchCode = code;
        this.chartDataNetworkResi2.datasets[0] =
          this.chartSecNutResiData.datasets[0];
      }

      if (index == 3) {
        clusterLayer = await chartThirdLayer(params, code);
        this.naceSearchCode = code;
        this.chartDataNetworkResi3.datasets[0] =
          this.chartThirdNutResiData.datasets[0];
      }

      if (index == 4) {
        clusterLayer = await chartFourthLayer(params, code);
        this.naceSearchCode = code;
      }

      if (
        clusterLayer.chart_data &&
        clusterLayer.chart_data.length != 0 &&
        index != 4
      ) {
        const dataLabel = clusterLayer.chart_data?.map(
          (item: { name: string }) => {
            return item.name;
          }
        );

        index === 1 && Object.assign(this.chartDataNetworkResi.datasets[0], {
          label: dataLabel,
        });
        index === 2 && Object.assign(this.chartDataNetworkResi2.datasets[0], {
          label: dataLabel,
        });
        index === 3 && Object.assign(this.chartDataNetworkResi3.datasets[0], {
          label: dataLabel,
        });

        const dataValue = clusterLayer.chart_data?.map(
          (item: { value: number }) => {
            return item.value;
          }
        );

        index === 1 && Object.assign(this.chartDataNetworkResi.datasets[0], {
          data: dataValue,
        });
                index === 2 && Object.assign(this.chartDataNetworkResi2.datasets[0], {
          data: dataValue,
        });
                index === 3 && Object.assign(this.chartDataNetworkResi3.datasets[0], {
          data: dataValue,
        });

        const dataCode = clusterLayer.chart_data?.map(
          (item: { code: string }) => {
            return item.code;
          }
        );

        index === 1 && Object.assign(this.chartDataNetworkResi.datasets[0], {
          code: dataCode,
        });
                index === 2 && Object.assign(this.chartDataNetworkResi2.datasets[0], {
          code: dataCode,
        });
                index === 3 && Object.assign(this.chartDataNetworkResi3.datasets[0], {
          code: dataCode,
        });
      }

      this.chartResiCompanies = clusterLayer.nb_companies;
      this.chartResiEmployees = clusterLayer.nb_employees;
      this.chartResiRevenue = clusterLayer.total_revenue;
      this.networkResiChart = true;
      this.networkResiChart2 = true;
      this.networkResiChart3 = true;
      this.loader.done();
    },

    async fetchChartNetworkResiCompanies(country = "", region = "", city = "") {
      useCompaniesListStore().naceSearch = this.naceSearchCode;

      this.visibleResiList = true;
      this.visibleResiSolution = false;

      useCompaniesListStore().isResidual = 1;
      useCompaniesListStore().countrySelected = country;
      useCompaniesListStore().regionSelected = region;
      useCompaniesListStore().citySelected = city;
      useCompaniesListStore().clusterFilter = useClusterEditStore().cluster.id;
      useCompaniesListStore().feedstock = 1;
      useCompaniesListStore().fetchCompanies();
    },
    async fetchChartNetworkResiSolutions() {
      useGraphsListStore().isModel = 1;
      useGraphsListStore().naceSearch = this.naceSearchCode;
      // useGraphsListStore().countrySearch = country;
      // useGraphsListStore().regionSearch = region;
      // useGraphsListStore().citySearch = city;

      this.visibleResiList = false;
      this.visibleResiSolution = true;
      // if (!this.naceSearchCode) {
      //   useGraphsListStore().fetchGraphs();
      // } else {
        useGraphsListStore().fetchGraphs(); // CHECK THIS
        // useGraphsListStore().fetchGraphWithNaces(this.naceSearchCode);
      // }
    },

    async fetchChartRegionResiData(
      index: number,
      code: string,
      country?: string,
      region?: string,
      city?: string
    ) {
      this.visibleRegionResiList = false;
      this.visibleRegionResiSolution = false;
      this.regionResiChart = false;
      this.regionResiChart2 = false;
      this.regionResiChart3 = false;
      this.loader.add();
      await useClusterEditStore().fetchOurCluster();
      this.clusterRegion = useClusterEditStore().cluster.address.country;

      let clusterLayer;
      const params: Record<string, unknown> = {
      };
      params["is_residual"] = 1;
      if (country) params["country"] = country;
      if (region) params["region"] = region;
      if (city) params["city"] = city;
      if (index == 1) {
        clusterLayer = await chartFirstLayer(params);
        this.chartDataRegResi.datasets[0] =
          this.chartRegionResiData.datasets[0];
      }
      if (index == 2) {
        clusterLayer = await chartSecondLayer(params, code);
        this.naceSearchCode = code;
        this.chartDataRegResi2.datasets[0] =
          this.chartSecRegionResiData.datasets[0];
      }

      if (index == 3) {
        clusterLayer = await chartThirdLayer(params, code);
        this.naceSearchCode = code;
        this.chartDataRegResi3.datasets[0] =
          this.chartThirdRegionResiData.datasets[0];
      }

      if (index == 4) {
        clusterLayer = await chartFourthLayer(params, code);
        this.naceSearchCode = code;
      }

      if (
        clusterLayer.chart_data &&
        clusterLayer.chart_data.length != 0 &&
        index != 4
      ) {
        const dataLabel = clusterLayer.chart_data?.map(
          (item: { name: string }) => {
            return item.name;
          }
        );

        index === 1 &&
          Object.assign(this.chartDataRegResi.datasets[0], {
            label: dataLabel,
          });
        index === 2 &&
          Object.assign(this.chartDataRegResi2.datasets[0], {
            label: dataLabel,
          });
        index === 3 &&
          Object.assign(this.chartDataRegResi3.datasets[0], {
            label: dataLabel,
          });
        const dataValue = clusterLayer.chart_data?.map(
          (item: { value: number }) => {
            return item.value;
          }
        );
        index === 1 &&
          Object.assign(this.chartDataRegResi.datasets[0], { data: dataValue });
        index === 2 &&
          Object.assign(this.chartDataRegResi2.datasets[0], {
            data: dataValue,
          });
        index === 3 &&
          Object.assign(this.chartDataRegResi3.datasets[0], {
            data: dataValue,
          });

        const dataCode = clusterLayer.chart_data?.map(
          (item: { code: string }) => {
            return item.code;
          }
        );

        index === 1 &&
          Object.assign(this.chartDataRegResi.datasets[0], { code: dataCode });
        index === 2 &&
          Object.assign(this.chartDataRegResi2.datasets[0], { code: dataCode });
        index === 3 &&
          Object.assign(this.chartDataRegResi3.datasets[0], { code: dataCode });
      }

      this.chartRegionResiCompanies = clusterLayer.nb_companies;
      this.chartRegionResiEmployees = clusterLayer.nb_employees;
      this.chartRegionResiRevenue = clusterLayer.total_revenue;
      this.regionResiChart = true;
      this.regionResiChart2 = true;
      this.regionResiChart3 = true;
      this.loader.done();
    },

    async fetchChartRegionResiCompanies(
      country: string,
      region: string,
      city: string | null
    ) {
      useCompaniesListStore().naceSearch = this.naceSearchCode;

      this.visibleRegionResiList = true;
      this.visibleRegionResiSolution = false;
      this.clusterRegion = country;

      useCompaniesListStore().isResidual = 1;   
      useCompaniesListStore().countrySelected = country;
      useCompaniesListStore().regionSelected = region;
      useCompaniesListStore().clusterFilter = null;
      useCompaniesListStore().citySelected = city;
      useCompaniesListStore().feedstock = 1;
      useCompaniesListStore().fetchCompanies();
    },
    async fetchChartRegionResiSolutions(
      // country: string,
      // region: string,
      // city: string
    ) {
      useGraphsListStore().isModel = 1;
      useGraphsListStore().naceSearch = this.naceSearchCode;
      // useGraphsListStore().countrySearch = country;
      // useGraphsListStore().regionSearch = region;
      // useGraphsListStore().citySearch = city;

      this.visibleRegionResiSolution = true;
      this.visibleRegionResiList = false;
      // if (!this.naceSearchCode) {
        useGraphsListStore().fetchGraphs();
        // useGraphsListStore().fetchGraphWithNaces(this.naceSearchCode);
    },

    async resetClick() {
      this.chartRing = "";
      this.chartSecRing = "";
      this.chartThirdRing = "";
      this.chartData = new ChartRegionResiData();
      this.chartData2 = new ChartRegionResiData2();
      this.chartData3 = new ChartRegionResiData3();
      this.chartNutData = new ChartRegionResiData();
      this.chartSecNutData = new ChartRegionResiData2();
      this.chartThirdNutData = new ChartRegionResiData3();
      await this.fetchChartNetworkData(1, "");
      this.naceSearchCode = "";
    },

    async resetResiClick() {
      this.chartResiRing = "";
      this.chartSecResiRing = "";
      this.chartThirdResiRing = "";
      this.chartDataNetworkResi = new ChartRegionResiData();
      this.chartDataNetworkResi2 = new ChartRegionResiData2();
      this.chartDataNetworkResi3 = new ChartRegionResiData3();
      this.chartNutResiData = new ChartRegionResiData();
      this.chartSecNutResiData = new ChartRegionResiData2();
      this.chartThirdNutResiData = new ChartRegionResiData3();
      await this.fetchChartNetworkResiData(1, "");
      this.naceSearchCode = "";
    },
    async resetRegionClick() {
      this.chartRegionRing = "";
      this.chartSecRegionRing = "";
      this.chartThirdRegionRing = "";
      this.chartDataReg = new ChartRegionResiData();
      this.chartDataReg2 = new ChartRegionResiData2();
      this.chartDataReg3 = new ChartRegionResiData3();
      this.chartRegionData = new ChartRegionResiData();
      this.chartSecRegionData = new ChartRegionResiData2();
      this.chartThirdRegionData = new ChartRegionResiData3();

      await this.fetchChartRegionData(1, "");
      this.naceSearchCode = "";
    },

    async resetRegionResiClick() {
      this.chartRegionResiRing = "";
      this.chartSecRegionResiRing = "";
      this.chartThirdRegionResiRing = "";
      this.chartDataRegResi = new ChartRegionResiData();
      this.chartDataRegResi2 = new ChartRegionResiData2();
      this.chartDataRegResi3 = new ChartRegionResiData3();
      this.chartRegionResiData = new ChartRegionResiData();
      this.chartSecRegionResiData = new ChartRegionResiData2();
      this.chartThirdRegionResiData = new ChartRegionResiData3();

      await this.fetchChartRegionResiData(1, "");
      this.naceSearchCode = "";
    },
    async fetchFirstActivity(
      newActivity: string,
      country?: string,
      region?: string,
      city?: string
    ) {
      // reset second and third layers
      this.chartSecNutData = new ChartRegionResiData2();
      this.chartThirdNutData = new ChartRegionResiData3();

      newActivity = newActivity.split(/[0-9]/)[0];

      this.chartRing = newActivity;
      console.log(this.chartRing, "  this.chartRing");
      const index = this.chartNutData.datasets[0].label.findIndex(
        (item) => item === newActivity.trim()
      );
      const code = this.chartNutData.datasets[0].code[index];
      this.chartNutData.datasets[0].backgroundColor.fill("#4BEA88");
      this.chartNutData.datasets[0].backgroundColor[index] = "#209B4F";

      await useGraphStore().fetchChartNetworkData(
        2,
        code,
        country,
        region,
        city
      );
    },
    async fetchSecActivity(
      newActivity: string,
      country?: string,
      region?: string,
      city?: string
    ) {
      // reset third layer
      this.chartThirdNutData = new ChartRegionResiData3();

      newActivity = newActivity.split(/[0-9]/)[0];
      this.chartSecRing = newActivity;

      const index = this.chartSecNutData.datasets[0].label.findIndex(
        (item) => item === newActivity.trim()
      );
      const code = this.chartSecNutData.datasets[0].code[index];
      this.chartSecNutData.datasets[0].backgroundColor.fill("#06B348");
      this.chartSecNutData.datasets[0].backgroundColor[index] = "#225937";

      await useGraphStore().fetchChartNetworkData(
        3,
        code,
        country,
        region,
        city
      );
    },
    async fetchThirdActivity(
      newActivity: string,
      country?: string,
      region?: string,
      city?: string
    ) {
      newActivity = newActivity.split(/[0-9]/)[0];
      this.chartThirdRing = newActivity;
      const index = this.chartThirdNutData.datasets[0].label.findIndex(
        (item) => item === newActivity.trim()
      );
      console.log(newActivity, index, "newActivity");
      const code = this.chartThirdNutData.datasets[0].code[index];
      this.chartThirdNutData.datasets[0].backgroundColor.fill("#2A7948");
      this.chartThirdNutData.datasets[0].backgroundColor[index] = "#000000";

      await useGraphStore().fetchChartNetworkData(
        4,
        code,
        country,
        region,
        city
      );
    },

    async fetchResiFirstActivity(
      newActivity: string,
      country?: string,
      region?: string,
      city?: string
    ) {
      // reset second and third layer
      this.chartSecNutResiData = new ChartRegionResiData2();
      this.chartThirdNutResiData = new ChartRegionResiData3();

      newActivity = newActivity.split(/[0-9]/)[0];
      this.chartResiRing = newActivity;
      const index = this.chartNutResiData.datasets[0].label.findIndex(
        (item) => item === newActivity.trim()
      );
      const code = this.chartNutResiData.datasets[0].code[index];
      this.chartDataNetworkResi.datasets[0].backgroundColor.fill("#4BEA88");
      this.chartDataNetworkResi.datasets[0].backgroundColor[index] = "#209B4F";

      await useGraphStore().fetchChartNetworkResiData(
        2,
        code,
        country,
        region,
        city
      );
    },
    async fetchResiSecActivity(
      newActivity: string,
      country?: string,
      region?: string,
      city?: string
    ) {
      // reset third layer
      this.chartThirdNutResiData = new ChartRegionResiData3();

      newActivity = newActivity.split(/[0-9]/)[0];
      this.chartSecResiRing = newActivity;
      const index = this.chartSecNutResiData.datasets[0].label.findIndex(
        (item) => item === newActivity.trim()
      );
      const code = this.chartSecNutResiData.datasets[0].code[index];
      this.chartDataNetworkResi2.datasets[0].backgroundColor.fill("#06B348");
      this.chartDataNetworkResi2.datasets[0].backgroundColor[index] = "#225937";

      await useGraphStore().fetchChartNetworkResiData(
        3,
        code,
        country,
        region,
        city
      );
    },
    async fetchResiThirdActivity(
      newActivity: string,
      country?: string,
      region?: string,
      city?: string
    ) {
      newActivity = newActivity.split(/[0-9]/)[0];
      this.chartThirdResiRing = newActivity;
      const index = this.chartThirdNutResiData.datasets[0].label.findIndex(
        (item) => item === newActivity.trim()
      );
      const code = this.chartThirdNutResiData.datasets[0].code[index];

      this.chartDataNetworkResi3.datasets[0].backgroundColor.fill("#2A7948");
      this.chartDataNetworkResi3.datasets[0].backgroundColor[index] = "#000000";

      await useGraphStore().fetchChartNetworkResiData(
        4,
        code,
        country,
        region,
        city
      );
    },

    async fetchRegionFirstActivity(
      newActivity: string,
      country?: string,
      region?: string,
      city?: string
    ) {
      // reset second and third layers
      this.chartSecRegionData = new ChartRegionResiData2();
      this.chartThirdRegionData = new ChartRegionResiData3();

      newActivity = newActivity.split(/[0-9]/)[0];
      this.chartRegionRing = newActivity;
      const index = this.chartRegionData.datasets[0].label.findIndex(
        (item) => item === newActivity.trim()
      );
      const code = this.chartRegionData.datasets[0].code[index];
      this.chartRegionData.datasets[0].backgroundColor.fill("#4BEA88");
      this.chartRegionData.datasets[0].backgroundColor[index] = "#209B4F";
      await useGraphStore().fetchChartRegionData(
        2,
        code,
        country,
        region,
        city
      );
    },
    async fetchRegionSecActivity(
      newActivity: string,
      country?: string,
      region?: string,
      city?: string
    ) {
      // reset third layer
      this.chartThirdRegionData = new ChartRegionResiData3();

      newActivity = newActivity.split(/[0-9]/)[0];
      this.chartSecRegionRing = newActivity;
      const index = this.chartSecRegionData.datasets[0].label.findIndex(
        (item) => item === newActivity.trim()
      );
      const code = this.chartSecRegionData.datasets[0].code[index];
      this.chartSecRegionData.datasets[0].backgroundColor.fill("#06B348");
      this.chartSecRegionData.datasets[0].backgroundColor[index] = "#225937";
      await useGraphStore().fetchChartRegionData(
        3,
        code,
        country,
        region,
        city
      );
    },
    async fetchRegionThirdActivity(
      newActivity: string,
      country?: string,
      region?: string,
      city?: string
    ) {
      newActivity = newActivity.split(/[0-9]/)[0];
      this.chartThirdRegionRing = newActivity;
      const index = this.chartThirdRegionData.datasets[0].label.findIndex(
        (item) => item === newActivity.trim()
      );
      const code = this.chartThirdRegionData.datasets[0].code[index];

      this.chartThirdRegionData.datasets[0].backgroundColor.fill("#2A7948");
      this.chartThirdRegionData.datasets[0].backgroundColor[index] = "#000000";
      await useGraphStore().fetchChartRegionData(
        4,
        code,
        country,
        region,
        city
      );
    },

    async fetchRegionResiFirstActivity(
      newActivity: string,
      country?: string,
      region?: string,
      city?: string
    ) {
      // reset second and third layers
      this.chartSecRegionResiData = new ChartRegionResiData2();
      this.chartThirdRegionResiData = new ChartRegionResiData3();

      newActivity = newActivity.split(/[0-9]/)[0];
      this.chartRegionResiRing = newActivity;
      const index = this.chartRegionResiData.datasets[0].label.findIndex(
        (item) => item === newActivity.trim()
      );
      const code = this.chartRegionResiData.datasets[0].code[index];
      this.chartDataRegResi.datasets[0].backgroundColor.fill("#4BEA88");
      this.chartDataRegResi.datasets[0].backgroundColor[index] = "#209B4F";

      await useGraphStore().fetchChartRegionResiData(
        2,
        code,
        country,
        region,
        city
      );
    },
    async fetchRegionResiSecActivity(
      newActivity: string,
      country?: string,
      region?: string,
      city?: string
    ) {
      // reset third layer
      this.chartThirdRegionResiData = new ChartRegionResiData3();

      newActivity = newActivity.split(/[0-9]/)[0];
      this.chartSecRegionResiRing = newActivity;
      const index = this.chartSecRegionResiData.datasets[0].label.findIndex(
        (item) => item === newActivity.trim()
      );
      const code = this.chartSecRegionResiData.datasets[0].code[index];
      this.chartDataRegResi2.datasets[0].backgroundColor.fill("#06B348");
      this.chartDataRegResi2.datasets[0].backgroundColor[index] = "#225937";

      await useGraphStore().fetchChartRegionResiData(
        3,
        code,
        country,
        region,
        city
      );
    },
    async fetchRegionResiThirdActivity(
      newActivity: string,
      country?: string,
      region?: string,
      city?: string
    ) {
      newActivity = newActivity.split(/[0-9]/)[0];
      this.chartThirdRegionResiRing = newActivity;
      const index = this.chartThirdRegionResiData.datasets[0].label.findIndex(
        (item) => item === newActivity.trim()
      );
      const code = this.chartThirdRegionResiData.datasets[0].code[index];
      this.chartDataRegResi3.datasets[0].backgroundColor.fill("#2A7948");
      this.chartDataRegResi3.datasets[0].backgroundColor[index] = "#000000";

      await useGraphStore().fetchChartRegionResiData(
        4,
        code,
        country,
        region,
        city
      );
    },

    async fetchApplyModal() {
      this.graphId = useGlobalDatasStore().graphModal.id;
      this.applyModelRegion = useGlobalDatasStore().graphModal.region;
    },
  },
});
