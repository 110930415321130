<template>
  <GoogleMap
    :api-key="gmaps_api_key"
    style="width: 100%; height: 100%"
    :center="{ lat: lat ? lat : 51.1657, lng: lon ? lon : 10.4515 }"
    :zoom="lat || lon ? 8 : 5"
  >
    <Marker v-if="lat && lon" :options="{ position: { lat: lat, lng: lon} }" />
  </GoogleMap>
</template>

<script setup lang="ts">
import { GoogleMap, Marker } from "vue3-google-map";
import { inject } from "vue";

const gmaps_api_key: string | undefined =
  inject("gmaps_api_key") != null ? inject("gmaps_api_key") : undefined;
defineProps<{
  lat?: number;
  lon?: number;
}>();
</script>
