<script lang="ts" setup>
import { useLoader } from "@/stores/loader";
import { computed } from "vue";

const { isBusy } = useLoader();

const loading = computed(() => isBusy());
</script>

<template>
  <div v-if="loading" class="spinner-position">
    <div class="text-center">Fetching...</div>
    <b-spinner variant="success" type="grow"></b-spinner>
  </div>
</template>

<style scoped>
.spinner-position {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  /* position: absolute; */
}
</style>
