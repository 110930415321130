import { defineStore } from "pinia";
import {
  getOurCluster,
  addCluster,
  editCluster,
  getOurClusterOverview,
} from "@/api/cluster";
import { Cluster, ClusterOverview } from "@/types";
import { inviteToCluster } from "@/api/user";
import { useGlobalDatasStore } from "./global_datas";
import { reactive } from "vue";

export const useClusterEditStore = defineStore({
  id: "cluster_edit",
  state: () => ({
    cluster: reactive(new Cluster()),
    clusterOverview: new ClusterOverview(),
    showInviteModal: false,
    email_to_invite: "",
    modified: false,
  }),
  actions: {
    async fetchOurCluster() {
      const cluster = await getOurCluster();
      if (cluster == null || Object.keys(cluster).length === 0) {
        console.log("cluster is empty", cluster);
        this.clearCluster();
      } else {
        this.cluster = cluster;
        if (this.cluster.contact_email_personnal === null)
          this.cluster.contact_email_personnal = "";

        if (this.cluster.contact_phone === null)
          this.cluster.contact_phone = "";

        if (this.cluster.website === null) this.cluster.website = "";
      }
    },
    async fetchOurClusterOverview() {
      this.clusterOverview = await getOurClusterOverview();
    },
    async invite() {
      const res = await inviteToCluster(this.email_to_invite);
      if ("error" in res) {
        useGlobalDatasStore().showToast(res["error"], "error");
      } else {
        useGlobalDatasStore().showToast(res["ok"], "success");
      }
    },
    clearCluster() {
      this.cluster = new Cluster();
    },
    updateLogo(logoId: number | null) {
      this.cluster.logo_id = logoId;
      this.modified = true;
    },
    async addOrEditCluster(callback: (status: number) => void = () => {}) {
      console.log(this.cluster);
      if (this.cluster.id == -1) {
        return addCluster(this.cluster, callback);
      } else {
        return editCluster(this.cluster, callback);
      }
    },
  },
});
