import { request_api } from "@/api/request";
import type { Biolink } from "@/types";

export async function listBiolinks(params: Record<string, unknown>) {
  params["perPage"] = 20;
  return await request_api("biolinks", {
    method: "GET",
    queryStringGet: params,
  });
}

export async function listBiolinksSummary(company_id: number) {
  return await request_api("biolinks_summary", {
    method: "GET",
    queryStringGet: {"companyFilter":company_id.toString()},
  });
}

export async function getBiolink(biolink_id: number) {
  return await request_api("biolinks/" + biolink_id, {
    method: "GET",
  });
}

export async function editBiolink(
  biolink: Biolink,
  callback: (status: number) => void
) {
  const res = await request_api(
    "biolinks",
    { method: "PUT", body: biolink },
    callback
  );

  return res ? res.id : false;
}

// Create a new biolink in the db, return the biolink id
export async function createBiolink(
  biolink: { name: string; description: string; companyA: any; companyB: any; outputA: any; inputB: any; type: number; category: number; outcome: number; confidential: boolean; },
  callback: (status: number) => void
) {
  const res = await request_api(
    "biolinks",
    { method: "POST", body: biolink },
    callback
  );

  return res ? res.id : false;
}
