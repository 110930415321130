import { request_api } from "@/api/request";

export async function deleteDocument(id: number) {
  return await request_api("document/" + id.toString(), {
    method: "DELETE",
  });
}

export async function getDocumentsForCompany(id: number) {
  return await request_api("get_documents_for_company/" + id.toString(), {
    method: "GET",
  });
}

export async function getDocumentsForSolution(id: number) {
  return await request_api("get_documents_for_solution/" + id.toString(), {
    method: "GET",
  });
}
