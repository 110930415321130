<template>
  <div
    :class="
      'modal-cluster ' +
      (globalStore.modal_full_height ? 'modal-cluster-full-height ' : '') +
      (globalStore.last_modal_type == 'cluster' ? 'modal-front ' : '')
    "
    v-if="globalStore.showClusterModal"
  >
    <b-row>
      <b-col class="cluster-title">Network</b-col>
      <b-col style="text-align: right">
        <b-button
          variant="outline-secondary"
          v-on:click="globalStore.hideClusterModal"
          ><i class="bi bi-x-lg" style="color: black"></i
        ></b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="modal-image-col"
        ><img
          class="rounded-circle"
          width="64"
          :src="globalStore.clusterModal.logo_id == null ? API_URL+'thumbtail/150/82461F/FFFFFF?text=' + (globalStore.clusterModal.name as string).charAt(0) : getThumbtailLink(globalStore.clusterModal.logo_id)"
        />
      </b-col>
      <b-col
        ><span class="modal-name">{{ globalStore.clusterModal.name }}</span
        ><br />
        <span class="modal-url">{{ globalStore.clusterModal.website }}</span>
      </b-col>
    </b-row>
    <br />
    <div class="modal-content-div">
      <span class="modal-label">Address</span><br />
      <span class="modal-value"
        >{{ globalStore.clusterModal.address.street_number }}
        {{ globalStore.clusterModal.address.street_name }},
        {{ globalStore.clusterModal.address.city }},
        {{ globalStore.clusterModal.address.npa }},
        {{ globalStore.clusterModal.address.country }}</span
      ><br />
      <hr />
      <b-row v-if="globalStore.clusterModal.network_type">
        <b-col cols="4" class="modal-label">Network type</b-col>
        <b-col class="modal-value-medium">{{
          globalStore.getNetworkType(globalStore.clusterModal.network_type)
        }}</b-col>
      </b-row>
      <b-row v-if="globalStore.clusterModal.year">
        <b-col cols="4" class="modal-label">Year of the establishment</b-col>
        <b-col class="modal-value-medium">{{
          globalStore.clusterModal.year
        }}</b-col>
      </b-row>
      <hr />
      <span
        class="modal-label"
        v-if="globalStore.clusterModal.description.length > 0"
        >Description</span
      ><br />
      <span class="modal-value-small">{{
        globalStore.clusterModal.description
      }}</span
      ><br />
      <hr />
      <span class="modal-label">Contact</span><br />
      <a
        :href="'mailto:' + globalStore.clusterModal.contact_email_general"
        v-if="
          globalStore.clusterModal.contact_email_general &&
          globalStore.clusterModal.contact_email_general.length > 0
        "
        class="modal-contact"
        ><i class="bi bi-envelope"></i>
        {{ globalStore.clusterModal.contact_email_general }}</a
      ><br />
      <a
        :href="'mailto:' + globalStore.clusterModal.contact_email_personnal"
        v-if="
          globalStore.clusterModal.contact_email_personnal &&
          globalStore.clusterModal.contact_email_personnal.length > 0
        "
        class="modal-contact"
        ><i class="bi bi-envelope"></i>
        {{ globalStore.clusterModal.contact_email_personnal }}</a
      ><br />
      <a
        :href="'tel:' + globalStore.clusterModal.contact_phone"
        v-if="
          globalStore.clusterModal.contact_phone &&
          globalStore.clusterModal.contact_phone.length > 0
        "
        class="modal-contact"
        ><i class="bi bi-telephone"></i>
        {{ globalStore.clusterModal.contact_phone }}</a
      >

      <hr />
      <span class="modal-label">Members</span>
      <ListMembers
        :clusterId="globalStore.clusterModal.id"
        :show-add-button="true"
        :show-header="false"
        :limited-list="0"
        :compact-view="true"
      ></ListMembers>
    </div>
  </div>
</template>
<script setup lang="ts">
import ListMembers from "../ListMembers.vue";
import { useGlobalDatasStore } from "@/stores/global_datas";
import { getThumbtailLink } from "@/utils/utils";
import { API_URL } from "@/config";
import { useRouter } from "vue-router";

const router = useRouter();
const globalStore = useGlobalDatasStore();

router.beforeEach((to, from, next) => {
  globalStore.hideClusterModal();
  next();
});
</script>
<style scoped>
.modal-cluster {
  position: fixed;
  top: 68px;
  right: 0px;
  bottom: 0px;
  width: 580px;
  background-color: white;
  border: 1px solid var(--gray-200);
  padding: 20px;
  z-index: 2000;
}

.modal-cluster-full-height {
  top: 0px !important;
}

.modal-front {
  z-index: 2001 !important;
}

.cluster-title {
  color: var(--gray-500);
  font-size: 16px;
  padding-top: 10px;
}

.modal-image-col {
  width: 90px;
  flex: none;
}

.modal-name {
  font-size: 24px;
  color: var(--gray-900);
  line-height: 32px;
}

.modal-url {
  font-size: 18px;
  color: var(--green-700);
}

.modal-label {
  color: var(--gray-500);
  font-size: 16px;
}

.modal-value {
  font-size: 18px;
  color: var(--gray-700);
}

.modal-value-small {
  font-size: 14px;
  color: var(--gray-700);
}

.modal-value-medium {
  font-size: 16px;
  color: var(--gray-700);
}

.modal-contact {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid gray;
  display: inline-block;
  margin: 5px 0;
  font-size: 16px;
  color: var(--gray-700);
}

.modal-content-div {
  overflow: scroll;
  height: calc(100% - 120px);
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
