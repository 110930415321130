<script setup lang="ts">
defineProps<{
  crtPage: number;
  totalPages: number;
  onPageChange: (page: number, index?: number) => void;
  index?: number;
}>();

function customRange(start: number, end: number) {
  const result = [];
  for (let i = start; i <= end; i++) {
    result.push(i);
  }
  return result;
}
</script>
<template>
  <b-row style="padding-top: 2px">
    <b-col>
      <b-button
        :disabled="crtPage <= 1"
        variant="secondary"
        v-on:click="onPageChange(crtPage - 1, index)"
      >
        <i class="bi bi-arrow-left"></i> Previous
      </b-button>
    </b-col>
    <b-col class="center-zone">
      <!-- If totalPages <=10: we show all pages -->
      <span
        v-if="totalPages <= 10"
        :class="n === crtPage ? 'active page' : 'page'"
        v-on:click="n === crtPage ? null : onPageChange(n, index)"
        v-for="n in totalPages"
        :key="n"
        >{{ n }}
      </span>
      <!-- If totalPages >10: we show 3 pages at the beginning, 3 at the end, and 3 around current page -->
      <div v-if="totalPages > 10">
        <!--If crtPage <= 5 show first max(3, crtPage+1) pages else only first 3 pages -->
        <span
          v-if="crtPage <= 5"
          :class="n === crtPage ? 'active page' : 'page'"
          v-on:click="n === crtPage ? null : onPageChange(n, index)"
          v-for="n in Math.max(3, crtPage + 1)"
          :key="n + 'a'"
          >{{ n }}
        </span>
        <span
          :class="n === crtPage ? 'active page' : 'page'"
          v-on:click="n === crtPage ? null : onPageChange(n, index)"
          v-else
          v-for="n in 3"
          :key="n"
          >{{ n }}
        </span>
        <span class="page-separator">...</span>

        <!--If crtPage>5 and crtPage<totalPages-4 show middle (crt-1, crt, crt+1) -->
        <span
          v-if="crtPage > 5 && crtPage < totalPages - 4"
          :class="n === crtPage ? 'active page' : 'page'"
          v-on:click="n === crtPage ? null : onPageChange(n, index)"
          v-for="n in customRange(crtPage - 1, crtPage + 1)"
          :key="n"
          >{{ n }}
        </span>
        <span
          class="page-separator"
          v-if="crtPage > 5 && crtPage < totalPages - 4"
          >...
        </span>

        <!--If crtPage <= 5 show first max(3, crtPage+1) pages else only last 3 pages -->
        <span
          v-if="crtPage >= totalPages - 4"
          :class="n === crtPage ? 'active page' : 'page'"
          v-on:click="n === crtPage ? null : onPageChange(n, index)"
          v-for="n in customRange(
            Math.min(crtPage - 1, totalPages - 2),
            totalPages
          )"
          :key="n + 'a'"
          >{{ n }}
        </span>
        <span
          :class="n === crtPage ? 'active page' : 'page'"
          v-on:click="n === crtPage ? null : onPageChange(n, index)"
          v-else
          v-for="n in customRange(totalPages - 2, totalPages)"
          :key="n"
          >{{ n }}
        </span>
      </div>
    </b-col>
    <b-col class="right-zone">
      <b-button
        :disabled="crtPage >= totalPages"
        variant="secondary"
        v-on:click="onPageChange(crtPage + 1, index)"
      >
        Next <i class="bi bi-arrow-right"></i>
      </b-button>
    </b-col>
  </b-row>
</template>
<style>
.right-zone {
  text-align: right;
}

.center-zone {
  text-align: center;
}

.active {
  color: green;
  cursor: default !important;
}

.page {
  padding: 5px;
  margin: 5px;
  cursor: pointer;
}

.page-separator {
  padding: 5px;
  margin: 5px;
}
</style>
