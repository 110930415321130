import {API_URL} from "@/config";
import { ApiQueryParams } from "./api_types";

const defaultCallback = () => {
};

export async function request_api(
    route: string,
    parameters: ApiQueryParams,
    callback: (status: number) => void = defaultCallback
) {


    // Need to check if every variable has been passed    
    //const {method, queryStringGet, body, accept, contentType, parseResponse } = parameters;

    const apiParameters = new ApiQueryParams()
    if(parameters.method){
        apiParameters.method = parameters.method;
    }
    if(parameters.queryStringGet){
        apiParameters.queryStringGet = parameters.queryStringGet;
    }
    if(parameters.body){
        apiParameters.body = parameters.body;
    }
    if(parameters.accept){
        apiParameters.accept = parameters.accept;
    }
    if(parameters.contentType){
        apiParameters.contentType = parameters.contentType;
    }
    if(parameters.parseResponse){
        apiParameters.parseResponse = parameters.parseResponse;
    }


    const {method, queryStringGet, body, accept, contentType, parseResponse } = apiParameters;


 /*    const method = Object.prototype.hasOwnProperty.call(parameters, "method")
        ? parameters["method"]
        : "GET";
    const queryStringGet =
        Object.prototype.hasOwnProperty.call(parameters, "queryStringGet") && method == "GET"
            ? parameters["queryStringGet"]
            : null;
    const body = Object.prototype.hasOwnProperty.call(parameters, "body") ? parameters["body"] : null;
    const accept = Object.prototype.hasOwnProperty.call(parameters, "accept")
        ? parameters["accept"]
        : "application/json";
    const contentType = Object.prototype.hasOwnProperty.call(parameters, "contentType")
        ? parameters["contentType"]
        : "application/json";
    const parseResponse = Object.prototype.hasOwnProperty.call(parameters, "parseResponse")
        ? parameters["parseResponse"]
        : "json";
 */


    const recordQueryToString: Record<string, string> = {};

    for(const key in queryStringGet){
        recordQueryToString[key] = queryStringGet[key]+"";
    }

    const url = new URL(`${API_URL}${route}`);
    if (method == "GET" && queryStringGet) {
        url.search = new URLSearchParams(recordQueryToString).toString();
    }

    const headers = new Headers();
    if (accept) headers.append("Accept", accept);
    if (contentType) headers.append("Content-Type", contentType);
    //
    // if (addAuthHeaders) {
    //     const authHeaders = getAuthHeaders();
    //     if (!authHeaders) return null;
    //     for (const [key, value] of authHeaders.entries())
    //         headers.append(key, value);
    // }

    try {
        const parameters = {
            method,
            mode: "cors",
            credentials: "include",
            headers,
        };
        if (body) {
            // @ts-ignore
            parameters["body"] =
                body instanceof FormData || contentType !== "application/json"
                    ? body
                    : JSON.stringify(body);
        }

        // @ts-ignore
        const response = await fetch(url, parameters);

        if (response.ok)
            if (parseResponse === "json") return await response.json();
            else return await response.text();
        else {
            callback(response.status);
            return await response.json()
        }
    } catch (e) {
        return null;
    }
}
