import { defineStore } from "pinia";
import { ref } from "vue";

export const useLoader = defineStore("loader", () => {
  const jobs = ref(0);

  const isBusy = () => jobs.value > 0;

  const add = () => {
    jobs.value++;
  };

  const done = () => {
    if (jobs.value > 0) jobs.value--;
  };

  return { isBusy, add, done };
});
