<template>
  <metainfo>
    <template v-slot:title="{ content }"
      >{{ content ? `${content} | vcg.ai` : `vcg.ai` }}
    </template>
  </metainfo>
  <div v-if="globalStore.logged_in === null">Loading...</div>

  <LeftMenu
    class="left-menu-tr"
    v-if="isMenuDisplayed() && !$route.path.includes('sign-up')"
    :category="crtCategory"
    :onCategoryChange="changeCategory"
  />

  <ToastManager
    v-if="globalStore.toast != null"
    :toast="globalStore.toast"
  ></ToastManager>
  <ModalCompany></ModalCompany>

  <ApplyModelModal></ApplyModelModal>
  <AddMembersCluster></AddMembersCluster>
  <ModalVirtualCompany></ModalVirtualCompany>
  <ModalCluster></ModalCluster>
  <ModalAlternativeInOut></ModalAlternativeInOut>
  <ModalAlternativeVc></ModalAlternativeVc>
  <ModalEditVirtualCompany></ModalEditVirtualCompany>
  <ModalEditTechnology></ModalEditTechnology>
  <ModalGraphs></ModalGraphs>
  <ApplyModelModal />
  <ModalValueChainNode></ModalValueChainNode>

  <ModalTechnology></ModalTechnology>
  <ModalGraphView></ModalGraphView>
  <VirtualProcessModal></VirtualProcessModal>
  <ModalUser></ModalUser>
  <ModalAddEditUser></ModalAddEditUser>
  <ModalSolutions></ModalSolutions>
  <ModalEditVirtualProcess></ModalEditVirtualProcess>
  <DialogWrapper :transition-attrs="{ name: 'dialog' }" />
  <SignIn
    v-if="
      globalStore.logged_in === false &&
      !$route.path.includes('sign-up') &&
      !$route.path.includes('confirm') &&
      !$route.path.includes('password-reset')
    "
  ></SignIn>
  <Guidelines
    v-else-if="
      globalStore.logged_in === true &&
      mustAcceptGuidelines() &&
      !$route.path.includes('sign-up')
    "
  />
  <ClusterInvitation
    v-else-if="
      globalStore.logged_in === true &&
      globalStore.logged_user.cluster_invitation === 1 &&
      !$route.path.includes('sign-up')
    "
  />
  <router-view
    :key="$route.path"
    v-else-if="
      globalStore.logged_in === true ||
      $route.path.includes('sign-up') ||
      $route.path.includes('confirm') ||
      $route.path.includes('password-reset')
    "
  ></router-view>
</template>

<script setup lang="ts">
import { ref, onMounted, defineAsyncComponent } from "vue";
import { useGlobalDatasStore } from "./stores/global_datas";
import { DialogWrapper } from "vue3-promise-dialog";
import ModalCluster from "./components/global/ModalCluster.vue";

const ModalEditVirtualProcess = defineAsyncComponent(
  () => import("@/components/global/ModalEditVirtualProcess.vue")
);
const ClusterInvitation = defineAsyncComponent(
  () => import("@/views/ClusterInvitation.vue")
);
const SignIn = defineAsyncComponent(() => import("./views/SignIn.vue"));
const ToastManager = defineAsyncComponent(
  () => import("./components/global/ToastManager.vue")
);
const Guidelines = defineAsyncComponent(() => import("@/views/Guidelines.vue"));
const LeftMenu = defineAsyncComponent(
  () => import("@/components/LeftMenu.vue")
);
const ModalCompany = defineAsyncComponent(
  () => import("./components/global/ModalCompany.vue")
);
const ModalVirtualCompany = defineAsyncComponent(
  () => import("./components/global/ModalVirtualCompany.vue")
);
const ModalEditVirtualCompany = defineAsyncComponent(
  () => import("./components/global/ModalEditVirtualCompany.vue")
);
const ModalEditTechnology = defineAsyncComponent(
  () => import("./components/global/ModalEditTechnology.vue")
);
const ModalGraphs = defineAsyncComponent(
  () => import("./components/global/ModalGraphs.vue")
);
const ModalTechnology = defineAsyncComponent(
  () => import("./components/global/ModalTechnology.vue")
);
const VirtualProcessModal = defineAsyncComponent(
  () => import("./components/global/ModalVirtualProcess.vue")
);
const ModalAlternativeInOut = defineAsyncComponent(
  () => import("./components/global/ModalAlternativeInOut.vue")
);
const ModalAlternativeVc = defineAsyncComponent(
  () => import("./components/global/ModalAlternativeVc.vue")
);
const ModalGraphView = defineAsyncComponent(
  () => import("./components/global/ModalGraphView.vue")
);
const ApplyModelModal = defineAsyncComponent(
  () => import("@/components/graph/modal/ApplyModelModal.vue")
);
const ModalValueChainNode = defineAsyncComponent(
  () => import("./components/global/ModalValueChainNode.vue")
);
const ModalUser = defineAsyncComponent(
  () => import("./components/global/ModalUser.vue")
);
const ModalAddEditUser = defineAsyncComponent(
  () => import("./components/global/ModalAddEditUser.vue")
);
const ModalSolutions = defineAsyncComponent(
  () => import("./components/global/ModalSolutions.vue")
);
const AddMembersCluster = defineAsyncComponent(
  () => import("@/components/graph/modal/AddMembersCluster.vue")
);
const globalStore = useGlobalDatasStore();

const crtCategory = ref("browse");

function changeCategory(newCategory: string) {
  crtCategory.value = newCategory;
}

function mustAcceptGuidelines() {
  return (
    globalStore.logged_user.guidelineversion < globalStore.guideline_version
  );
}

function isMenuDisplayed() {
  return (
    globalStore.logged_in === true &&
    globalStore.logged_user.guidelineversion >= globalStore.guideline_version &&
    globalStore.logged_user.cluster_invitation == 0
  );
}

onMounted(() => {
  globalStore.fetchIsLoggedIn(true);
  globalStore.fetchGuidelines(true);

  // cold start, diesel
  globalStore.showToast("", "passive", 0);
});
</script>

<style>
@import "@/assets/base.css";
@import "@/assets/bootstrap-select.min.css";

body,
html {
  width: 100%;
  height: 100%;
}

#app {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 !important;
}

.navbar {
  margin-left: 82px;
}

/*.left-menu-tr {*/
/*  transition: width .5s;*/
/*  width: 7%;*/
/*}*/

/*.left-menu-tr:hover {*/
/*  width: 20%;*/
/*}*/
</style>
