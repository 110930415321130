import {request_api} from "@/api/request";

export async function listValueChains(filters: Record<string, unknown>) {
    return await request_api("valuechains", {
        method: "GET",
        queryStringGet: filters,
    });
}

export async function getValueChain(id: number) {
    return await request_api("valuechains/" + id, {
        method: "GET",
    });
}

export async function saveValueChainCandidates(input_data: object) {
    return await request_api("valuechains/candidate", {
        method: "POST",
        body: input_data,
    });
}

export async function getValueChainSummary(id: number) {
    return await request_api("valuechain_summary/" + id, {
        method: "GET",
    });
}

export async function addInputToVC(vc_id: number, input_data: object) {
    return await request_api("valuechains/input/" + vc_id, {
        method: "POST",
        body: input_data,
    });
}

export async function editInputFromVC(vc_id: number, input_data: object) {
    return await request_api("valuechains/input/" + vc_id, {
        method: "PUT",
        body: input_data,
    });
}

export async function editOutputFromVC(vc_id: number, output_data: object) {
    return await request_api("valuechains/output/" + vc_id, {
        method: "PUT",
        body: output_data,
    });
}

export async function editCompanyDesc(vc_id: number, company_data: object) {
    return await request_api("valuechains/description/" + vc_id, {
        method: "PUT",
        body: company_data,
    });
}

export async function editCompanyKeywords(vc_id: number, company_data: object) {
    return await request_api("valuechains/keywords/" + vc_id, {
        method: "PUT",
        body: company_data,
    });
}

export async function editCompanyNaces(vc_id: number, company_data: object) {
    return await request_api("valuechains/nace_codes/" + vc_id, {
        method: "PUT",
        body: company_data,
    });
}

export async function editCompanyName(vc_id: number, company_data: object) {
    return await request_api("valuechains/company_name/" + vc_id, {
        method: "PUT",
        body: company_data,
    });
}


export async function addOutputToVC(vc_id: number, output_data: object) {
    return await request_api("valuechains/output/" + vc_id, {
        method: "POST",
        body: output_data,
    });
}

export async function getInputById(input_id: number) {
    return await request_api("companies/input/" + input_id, {
        method: "GET",
    });
}

export async function getOutputById(input_id: number) {
    return await request_api("companies/output/" + input_id, {
        method: "GET",
    });
}

export async function getSimilarValueChains(companyId: number, filters: Record<string, unknown>) {
    return await request_api("similarity/valuechains/" + companyId, {
        method: "GET",
        queryStringGet: filters,
    });
}

export async function getSimilarValueChainsFromInputOutput(params: Record<string, unknown>) {
    return await request_api("similarity/input-output", {
        method: "GET",
        queryStringGet: params,
    });
}

export async function deleteValueChain(valueChainId: number){
    return await request_api("valuechains/" + valueChainId, {
        method: "DELETE",
    });
}

export async function deleteInput(inputId: number) {
    return await request_api("valuechains/input/" + inputId, {
        method: "DELETE",
    });
}

export async function deleteOutput(inputId: number) {
    return await request_api("valuechains/output/" + inputId, {
        method: "DELETE",
    });
}