import { createApp } from "vue";
import { createPinia } from "pinia";
// @ts-ignore
import DKToast from "vue-dk-toast";
import { vue3Debounce } from "vue-debounce";
//@ts-ignore
import vSelect from "vue-select";
import "bootstrap";
import "vue-select/dist/vue-select.css";

//Used for rete library: https://github.com/retejs/rete/issues/247 add regenerator-runtime package
import "regenerator-runtime";

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import { createMetaManager } from "vue-meta";

import "maz-ui/css/main.css";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";

import "./assets/scss/custom.scss";

import App from "./App.vue";
import Maintenance from "./views/Maintenance.vue";
import router from "@/router";
import { PromiseDialog } from "vue3-promise-dialog";
import { BootstrapVueNext } from "bootstrap-vue-next";
import { i18n } from "./i18n";

const maintenanceMode = false;

const app = createApp(maintenanceMode ? Maintenance : App);

const sentry_env = import.meta.env.VITE_SENTRY_ENV || "production";
const sentry_release = import.meta.env.VITE_SENTRY_RELEASE || "default_release";
const gmaps_api_key = import.meta.env.VITE_GMAPS_API_KEY || "";

// disable log when in production
if (sentry_env === "production") {
  console.log = function () {};
}

Sentry.init({
  app,
  environment: sentry_env,
  release: sentry_release,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        "localhost",
        "deepvcg-frontend.kube.isc.heia-fr.ch",
        "deepvcg-frontend-staging.kube.isc.heia-fr.ch",
        /^\//,
      ],
    }),
  ],
  tracesSampleRate: 1.0,
  tracingOptions: {
    trackComponents: true,
  },
  // Vue specific
  attachProps: true,
  attachStacktrace: true,
});

app.directive("debounce", vue3Debounce({ lock: true }));
app.use(BootstrapVueNext);
app.component("v-select", vSelect);
app.component("MazPhoneNumberInput", MazPhoneNumberInput);
app.use(createPinia());
app.use(router);
app.use(i18n);
app.use(createMetaManager());
app.provide("gmaps_api_key", gmaps_api_key);
// @ts-ignore
app.use(PromiseDialog);

app.use(DKToast, {
  max: 5,
  positionX: "left",
  positionY: "bottom",
  disableClick: false,
  pauseOnHover: true,
});

app.mount("#app");
