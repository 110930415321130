import {request_api} from "@/api/request";

export async function getCounts(period: string) {
    return await request_api("statistics/counts/" + period, {
        method: "GET",
    });
}

export async function getLoginCounts(period: string) {
    return await request_api("statistics/users_activity/" + period, {
        method: "GET",
    });
}

export async function getLastExportTime() {
    return await request_api("csv_export_time", {
        method: "GET",
        parseResponse: "text",
    });
}
