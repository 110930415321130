import {request_api} from "@/api/request";

export async function getSimilarValueChains(params: Record<string, unknown>) {
    return await request_api("similarity/value-chains", {
        method: "GET",
        queryStringGet: params,
    });
}

export async function getSimilarityMatrix(vc_id: number) {
    return await request_api("similarity/value-chains/"+vc_id, {
        method: "GET",
    });
}
export async function getSimilarCompaniesFromInputOutput(params: Record<string, unknown>) {
    return await request_api("similarity/input-output", {
        method: "GET",
        queryStringGet: params,
    });
}

export async function getSimilarFromInputOutputModel(params: Record<string, unknown>) {
    return await request_api("similarity/model-input-output", {
        method: "GET",
        queryStringGet: params,
    });
}