export class Address {
  street_name = "";
  street_number = "";
  city = "";
  npa = "";
  country = "";
  latitude = 0;
  longitude = 0;
}

export enum UserType {
  Default = 0,
  Company = 1,
  SolutionProvider = 2,
  ClusterUser = 3,
  ClusterAdmin = 4,
  SuperAdmin = 5,
}

export class UserDetails {
  id = 0;
  address: Address = new Address();
  name = "";
  description = "";
  company: Company = new Company();
}

export class UserSummary {
  cluster_id: number | null = null;
  cluster_invitation = 0;
  deactivate = 0;
  email = "";
  password = "";
  guidelineversion = 0;
  id = 0;
  last_activity = "";
  name = "";
  surname = "";
  needchange = 0;
  usertype_id: UserType = UserType.Default;
  usertype_name = "";
  organisation_name: string | null = null;
  country: string | null = null;
  latitude: number | null = null;
  longitude: number | null = null;
  company_id: number | null = null;
}

export class CandidateSummary {
  id_vc = -1;
  id_gr = -1;
  id_c = -1;
  latitude = 0;
  longitude = 0;
  company_name = "";
  similarity = -1;
}
export class GraphDetails {
  confidential = 0;
  created_at = "";
  updated_at = "";
  creator_name = "";
  creator_surname = "";
  description = null;
  id = -1;
  is_model = -1;
  name = "";
  nb_nodes = 0;
  region = "";
  status_id = 0;
  status_value = "";
  support_request = 0;
}
export class Cluster {
  id = -1;
  name = "";
  website = "";
  address: Address = new Address();
  description = "";
  logo_id: number | null = null;
  year: number | null = null;
  network_type: number | null = null;
  contact_email_general = "";
  contact_email_personnal = "";
  contact_phone = "";
  VAT = "";
  market = "";
  employees_range: number | null = null;
  keywords: string[] = [];
}

export class Input {
  id = -1;
  general_name = "";
  description = "";
  taric_id = undefined as string | undefined;
  taric: string[] = [];
  ewc_id = undefined as string | undefined;
  ewc: string[] = [];
  input_type_id = 1;
  biological = 0;
  seasonality = 0;
  //value_chain_id = 0;
  annual_quantity: number | undefined | string = undefined;
  annual_quantity_from: undefined | string = undefined;
  annual_quantity_to: undefined | string = undefined;
  quantity_unit_id: number | undefined = undefined;
  reused = 0;
  reused_kg: number | undefined = undefined;
  reused_percent: number | undefined = undefined;
  keywords = [];
  source = "";
  sustainable_sourcing_certificate = true;
  sustainable_sourcing_certificates: Document[] = [];
  data_sources_documents: Document[] = [];
  application_cascading_principle = "";
  flag_want_replace = false;
  from_whom: string[] = [];
  naces: string[] = [];
  hazardous = false;
}

export class Output {
  id = -1;
  general_name = "";
  description = "";
  taric_id = undefined as string | undefined;
  taric: string[] = [];
  ewc_id = null as string | null;
  ewc: string[] = [];
  output_type_id = 3;
  seasonality = 0;
  annual_quantity: undefined | string = undefined;
  annual_quantity_from: undefined | string = undefined;
  annual_quantity_to: undefined | string = undefined;
  quantity_unit_id: string | undefined = undefined;
  flag_alternative = false;
  for_whom: string[] = [];
  naces: string[] = [];
  hazardous = false;
  circular_principle_type_id = 1;
  waste_type_id = 1;
  recycled_content: number | undefined = undefined;
  current_treatment = "";
  repairability_rating: number | null = null;
  keywords: string[] = [];
}

export class TaricEwc {
  id = "" as string;
  cn_num = "" as string;
  description = "" as string;
}

export class Process {
  id = -1;
  general_name = "";
  description = "";
  technology_description = "";
  input_characteristics = "";
  technical_name = "";
  annual_quantity: number | undefined | string = undefined;
  annual_quantity_from: undefined | string = undefined;
  annual_quantity_to: undefined | string = undefined;
  quantity_unit_id: number | undefined = undefined;
  flag_offer = false;
  trl_level = 9;
}

export class Descriptor {
  id = -1;
  name = "";
  description = "";
  id_confidence_type = 0;
  confidence_info = "";
  confidence_color = "";
  inputs: Input[] = [];
  outputs: Output[] = [];
  processes: Process[] = [];
  documents: Document[] = [];
  company: Company = new Company();
  trl_level = "9";
  solution_type = 0;
  solving = 0;
  treatment = 0;
  primary_nace: string | null = "";
  additionnal_naces: string[] = [];
  keywords: string[] = [];
  input_characteristics = "";
  process_description = "";
  mass_ratio = 0;
}

export class ValueChainSummary {
  id = 0;
  name = "";
  company_id = -1;
  company_name = "";
  company_type = "";
  company_longitude = 0;
  company_latitude = 0;
  total_revenue = 0;
  currency = "EUR";
  inputs = 0;
  outputs = 0;
  processes = 0;
  lastUpdate = "";
  description = "";
  similarity = 0;
}

export class ModelSummary {
  id = 0;
  name = "";
  nb_nodes = 0;
  status = "";
}

export class TechnologyListItem {
  company_id = 0;
  company_name = "";
  name = "";
  id = 0;
  classifications = "";
  keywords = "";
  trl_level = 0;
  primary_nace = "";
  additionnal_naces = "";
  biolinks = 0;
  model_biolinks = 0;
  nonmodel_biolinks = 0;
  updated_at = "";
}

export class Classification {
  id = -1;
  name = "";
  keywords: string[] = [];
}

export class Document {
  id = -1;
  name = "";
  size = -1;
  public = false;
  cluster_share = true;
  file = "";
  user_id = -1;
  company_id = -1;
  upload_percent = 0;
  upload_finished = false;
  upload_error = false;
  force_redraw = 0;
}

export class DocumentData {
  id = -1;
  name = "";
  size = -1;
  public = false;
  cluster_share = true;
  file = "";
}

export class Company {
  id = -1;
  name = "";
  website = "";
  address: Address = new Address();
  description = "";
  logo_id: number | null = null;
  keywords: string[] = [];
  id_number = "";
  company_type: number | undefined = undefined;
  employees_range: number | undefined = undefined;
  contact_email = "";
  contact_phone = "";
  primary_nace: string | null = "";
  additionnal_naces: string[] = [];
  documents: Document[] = [];
  certificates: number[] = [];
  id_confidence_type = 0;
  confidence_info = "";
  confidence_color = "";
  clusters: number[] = [];
  valuechains: Descriptor[] = [];
  virtual_company = 0;
  confidential = false;
  cluster_share = false;
  is_solution_provider = false;
}

export class ModelStatus {
  id_model_status = -1;
  status = "";
  trl = null;
  contributor: ContributorRef = new ContributorRef();
  has_valorisation = false;
  has_cost_saving = false;
  has_pos_env_impact = false;
  has_soc_impact = false;
  documents: Document[] = [];
  application_classification = [];
  processes_classification = [];
  support_request = 0;
  support_other_info = "";
}

export class GraphName {
  name = '';
}

export class ContributorRef {
  id_contributor = -1;
  contributor_name = "";
  id_contributor_ref_type = -1;
}

export class DescriptorsNameList {
  inputs: [[string, number]] = [["", -1]];
  outputs: [[string, number]] = [["", -1]];
  processes: [[string, number]] = [["", -1]];
}

export class CompanySummary {
  id = 0;
  latitude = 0;
  longitude = 0;
  name = "";
  website = "";
  company_type = "";
  type_id = -1;
  logo_id: number | null = null;
  country = "";
  city = "";
  inputs = 0;
  outputs = 0;
  is_solution_provider = 0;
  processes = 0;
  biolinks = 0;
  valuechains = 0;
  valuechain_name = ""; // === technology === "process" when is solution provider
  public_biolinks = 0;
  private_biolinks = 0;
  bookmarks: number[] = [];
  in_my_cluster = false;
  id_confidence_type = 0;
  confidence_info = "";
  confidence_color = "";
  created_at = "";
  updated_at = "";
  primary_nace: string | null = "";
  additionnal_naces: string[] = [];
  naces: string[] = [];
  revenue = 0;
  revenue_currency = "EUR";
  nb_solutions: number | null = null;
  checked = "false";
}

export class VirtualCompanySummary {
  id = 0;
  name = "";
  website = "";
  company_type = "";
  logo_id: number | null = null;
  country = "";
  inputs = 0;
  outputs = 0;
  is_solution_provider = 0;
  processes = 0;
  biolinks = 0;
  biolinks_summary = [];
  valuechains = 0;
  valuechain_name = ""; // === technology === "process" when is solution provider
  model_biolinks = 0;
  nonmodel_biolinks = 0;
  bookmarks: number[] = [];
  in_my_cluster = false;
  id_confidence_type = 0;
  confidence_info = "";
  confidence_color = "";
  created_at = "";
  updated_at = "";
}

export class MapSummary {
  id = 0;
  name = "";
  latitude = 0.0;
  longitude = 0.0;
  isClickedCompany = false;
  isSelected = false;
  isCandidate = false;
}

export class ClusterSummary {
  id = 0;
  name = "";
  website = "";
  logo_id: number | null = null;
  companies = 0;
  country = "";
  contact_email_general = "";
  biolinks = 0;
  public_biolinks = 0;
  private_biolinks = 0;
  created_at = "";
  updated_at = "";
}

export class SimilarValueChainSummary extends ValueChainSummary {
  similarity = 0;
  general_name = "";
  nb_nodes = 0;
  status = "";
  descriptor_id = 0;
}

export class ToastData {
  type: "success" | "error" | "passive" | undefined = "success";
  message = "";
  duration = 0;
}

export class Biolink {
  id = -1;
  category = 0;
  company_a: Company = new Company();
  company_b: Company = new Company();
  created_at = "";
  updated_at = "";
  name = "";
  description = "";
  comment = "";
  output: Output = new Output();
  input: Input = new Input();
  type = 0;
  outcome = 0;
}

export class ClusterOverview {
  nb_company = 0;
  nb_micro = 0;
  nb_sme = 0;
  nb_large_firm = 0;
  nb_corp = 0;
  nb_research = 0;
  nb_other = 0;
}
