import { defineStore } from "pinia";
import {
  listCompanies,
  addCompanyToMyCluster,
  deleteCompanyFromMyCluster,
  listCompaniesMap,
  deleteCompany,
  addCompaniesToMyCluster,
  deleteCompaniesToMyCluster,
} from "@/api/company";
import { Company, CompanySummary, MapSummary } from "@/types";
import { useGlobalDatasStore } from "./global_datas";
import { useLoader } from "./loader";

export const useCompaniesListStore = defineStore({
  id: "companies_list",
  state: () => ({
    companies: [] as CompanySummary[],
    loaded: false,
    crtPage: 1,
    nbPages: 1,
    sortColumn: "annual_revenue",
    sortOrder: "desc",
    typeSelected: "",
    regionSelected: "",
    citySelected: null as null | string,
    countrySelected: "",
    naceSearch: null as null | string,
    textSearch: "",
    feedstock: 0,
    showMap: false,
    companies_map: [] as MapSummary[],
    clusterFilter: null as null | number,
    showedCompany: new Company(),
    companyIds: [] as number[],
    checked: false,
    isResidual: null as null | number,
    loader: useLoader(),
  }),
  actions: {
    resetFilter() {
      this.textSearch = "";
      this.regionSelected = "";
      this.citySelected = null;
      this.countrySelected = "";
      this.typeSelected = "";
      this.feedstock = 0;
      this.sortColumn = "annual_revenue";
      this.sortOrder = "desc";
      this.crtPage = 1;
      this.naceSearch = null;
      this.showMap = false;
      this.isResidual = null;
      this.fetchCompanies();
    },
    resetFilterMembers() {
      this.textSearch = "";
      this.typeSelected = "";
      this.sortColumn = "annual_revenue";
      this.sortOrder = "desc";
      this.crtPage = 1;
      this.naceSearch = null;
      this.showMap = false;
      this.isResidual = null;
      this.fetchCompanies();
    },
    async fetchCompanies() {
      if (this.showMap) {
        return this.fetchCompaniesForMap();
      }
      this.loader.add();
      this.loaded = false;
      const params: Record<string, unknown> = {
        page: this.crtPage,
        perPage: 100,
        sortColumn: this.sortColumn,
        sortOrder: this.sortOrder,
      };
      if (this.typeSelected) {
        params["typeFilter"] = this.typeSelected;
      }
      if (this.regionSelected) {
        params["regionFilter"] = this.regionSelected;
      }
      if (this.countrySelected) {
        params["countryFilter"] = this.countrySelected;
      }
      if (this.naceSearch) {
        params["naceFilter"] = this.naceSearch;
      }
      if (this.citySelected) {
        params["cityFilter"] = this.citySelected;
      }
      if (this.clusterFilter) {
        params["clusterFilter"] = this.clusterFilter;
      }
      if (this.textSearch) {
        params["textFilter"] = this.textSearch;
      }
      if (this.isResidual !== null) {
        params["is_residual"] = this.isResidual;
      }

      const companies = await listCompanies(params);
      this.companies = companies.items;
      this.nbPages = companies.nb_pages;
      this.crtPage = companies.page;
      this.loaded = true;
      this.loader.done();
    },
    async fetchCompaniesForMap() {
      const params: Record<string, unknown> = {};
      if (this.typeSelected != "") {
        params["typeFilter"] = this.typeSelected;
      }
      if (this.regionSelected != "") {
        params["regionFilter"] = this.regionSelected;
      }
      if (this.naceSearch != null) {
        params["naceFilter"] = this.naceSearch;
      }
      if (this.clusterFilter != null) {
        params["clusterFilter"] = this.clusterFilter;
      }
      if (this.textSearch != "") {
        params["textFilter"] = this.textSearch;
      }
      this.companies_map = await listCompaniesMap(params);
    },
    async changePage(newPage: number) {
      this.crtPage = newPage;
      this.fetchCompanies();
    },
    async changeCountry(newCountry: string) {
      this.countrySelected = newCountry;
      //this.fetchCompanies();
      this.changePage(1);
    },
    async changeRegion(newRegion: string) {
      this.regionSelected = newRegion;
      //this.fetchCompanies();
      this.changePage(1);
    },
    async changeType(newType: string) {
      this.typeSelected = newType;
      //this.fetchCompanies();
      this.changePage(1);
    },
    async changeNace(newNace: string | null) {
      this.naceSearch = newNace;
      //this.fetchCompanies();
      this.changePage(1);
    },
    async changeClusterFilter(newCluster: number | null) {
      this.clusterFilter = newCluster;
      //this.fetchCompanies();
      this.changePage(1);
    },
    async changeFreeText(newText: string) {
      this.textSearch = newText.trim();
      //this.fetchCompanies();
      this.changePage(1);
    },
    async addCompanyToMyClusterAction(company_id: number) {
      return await addCompanyToMyCluster(company_id);
    },
    async addCompaniesToMyClusterAction(company_ids: Array<number>) {
      return await addCompaniesToMyCluster(company_ids);
    },

    async deleteCompanyFromMyClusterAction(company_id: number) {
      return await deleteCompanyFromMyCluster(company_id);
    },

    async deleteCompaniesFromMyClusterAction(company_ids: Array<number>) {
      return await deleteCompaniesToMyCluster(company_ids);
    },
    async deleteCompanyAction(company_id: number) {
      return await deleteCompany(company_id);
    },
    async getCompanyids(id: number) {
      if (id != -1) {
        this.companyIds.push(id);
      }
    },
    async RemoveFromClusterNetwork() {
      console.log(this.companyIds, "companiesStore.companyIds");
      if (this.companyIds.length == 0) {
        useGlobalDatasStore().showToast("Select any company", "error");
      } else {
        this.deleteCompaniesFromMyClusterAction(this.companyIds)
          .then((res) => {
            if ("error" in res) {
              this.companyIds = [];
              useGlobalDatasStore().showToast(res["error"], "error");
            } else if ("message" in res) {
              useGlobalDatasStore().showToast(res["message"], "success");
              this.companyIds = [];
              this.fetchCompanies();
            }
          })
          .catch(() => {
            useGlobalDatasStore().showToast(
              "Cannot remove from  your cluster",
              "error"
            );
          });
      }
    },
  },
});
