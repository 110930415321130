import { defineStore } from "pinia";
import {
  listEmployeesRanges,
  listNaces,
  listCompanyTypes,
  listNetworkTypes,
  listInputTypes,
  listOutputTypes,
  listQuantityUnits,
  listConfidencesTypes,
  listTags,
  listModelStatus,
  listApplicationTags,
  listProcessTags,
  listUserTypes,
  listWasteTypes,
  listCircularTypes,
  listSupportRequest,
  listOutputVirtualTypes,
} from "@/api/fixed_datas";
import {
  ToastData,
  Company,
  Biolink,
  Cluster,
  ContributorRef,
  Descriptor,
  UserSummary,
  GraphDetails,
  Input,
  Output,
  Process,
} from "@/types";
import {
  getGuidelinesText,
  getGuidelinesVersion,
  isLoggedIn,
  logout,
} from "@/api/user";
import { getCompany, listCompanies } from "@/api/company";
import {
  getDocumentsForCompany,
  getDocumentsForSolution,
} from "@/api/document";
import { listCountries } from "@/api/fixed_datas";
import Fuse from "fuse.js";
import { getCluster, listClustersForFilter } from "@/api/cluster";
import { getBiolink } from "@/api/biolink";
import {
  listContributorsRefs,
  getGraphDetails,
  getGraphCompanies,
  listBioSummary,
} from "@/api/graph";
import { getValueChain } from "@/api/value_chains";
import { getLastExportTime } from "@/api/statistics";
import { ModalType, openConfirmDialog } from "@/utils/utils";
import { useGraphStore } from "./graph";
import { useGraphsListStore } from "./graphs_list";

class InOutType {
  id = -1;
  type = "";
  description: string | null = null;
}

class WasteType {
  id = -1;
  name = "";
}

class CircularType {
  id = -1;
  name = "";
}

class BiolinkModelStatus {
  id = -1;
  text = "";
  value = "";
}

class RequestSupportModel {
  id = -1;
  value = "";
}

class ValueText {
  value = -1;
  text = "";
}

class ConfidenceType {
  id = 0;
  info = "";
  color = "";
}

class Nace {
  text = "";
  code = "";
  include = "";
  exclude = "";
}

export const useGlobalDatasStore = defineStore({
  id: "global_datas",
  state: () => ({
    naces: [] as Nace[],
    company_types: [] as ValueText[],
    companies: [],
    network_types: [] as ValueText[],
    employees_ranges: [] as ValueText[],
    user_types: [] as ValueText[],
    regions: [],
    countries: [],
    clusters: [],
    confidence_types: [] as ConfidenceType[],
    tags: [],
    application_tags: [],
    processes_tags: [],
    quantity_units: [],
    input_types: [] as InOutType[],
    output_types: [] as InOutType[],
    output_types_virtual: [] as InOutType[],
    waste_types: [] as WasteType[],
    circular_types: [] as CircularType[],
    biolink_model_status: [] as BiolinkModelStatus[],
    request_support_model: [] as RequestSupportModel[],
    contributors_refs: [] as ContributorRef[],
    biolinks_status: [] as ValueText[],
    biolinks_category: [] as ValueText[],
    biolinks_outcome: [] as ValueText[],
    solution_types_values: [] as ValueText[],
    solving_values: [] as ValueText[],
    treatment_values: [] as ValueText[],
    logged_in: null as boolean | null,
    logged_user: {} as UserSummary,
    toast: null as ToastData | null,
    guideline_version: -1,
    tou_text: "",
    tou_accepted: false,
    data_policy_text: "",
    data_policy_accepted: false,
    privacy_policy_text: "",
    privacy_policy_accepted: false,
    showCompanyModal: false,
    showVirtualCompanyModal: false,
    showTechnologyModal: false,
    showVirtualProcessModal: false,
    showClusterModal: false,
    showBiolinkModal: false,
    showAlternativeModal: false,
    showAlternativeModalInOut: false,
    modal_full_height: false,
    companyModal: new Company(),
    virtualCompanyModal: new Company(),
    clusterModal: new Cluster(),
    biolinkModal: new Biolink(),
    technologyModal: new Descriptor(),
    virtualProcessModal: new Descriptor(),
    last_modal_type: "company",
    invitation_cluster_name: "",
    last_export: "loading",
    showGraphModal: false,
    addEditVirtualModal: false,
    createVirtualModal: false,
    editVirtualModal: false,
    modified: false,
    showModal: false,
    addEditTechnologyModal: false,
    createTechnologyModal: false,
    editTechnologyModal: false,
    showDescriptorModal: false,
    graphModal: new GraphDetails(),
    graphCompanies: false,
    graphModalDetails: false,
    visible: true,
    showInputModal: false,
    showProcessModal: false,
    showOutputModal: false,
    crtInput: new Input(),
    crtOutput: new Output(),
    crtProcess: new Process(),
    crtDescriptor: new Descriptor(),
    addEditProcessModal: false,
    editProcessModal: false,
    createProcessModal: false,
    companyTotalCount: 0,
  }),
  actions: {
    async fetchNaces(force = false) {
      if (this.naces.length == 0 || force) {
        const naces = await listNaces();
        this.naces = naces
          .filter((item: { level: number }) => {
            return item.level > 1;
          })
          .map(
            (item: {
              code: string;
              description: string;
              include: string;
              exclude: string;
            }) => {
              return {
                text: item.code + " " + item.description,
                code: item.code,
                include: item.include,
                exclude: item.exclude,
              };
            }
          );
      }
      console.log("NACES", this.naces);
    },
    async fetchCompanies(force = false) {
      if (this.companies.length == 0 || force) {
        const companies = await listCompanies({ perPage: 20 });
        this.companyTotalCount = companies.total_count;
        this.companies = companies.items.map(
          (item: { id: number; name: string }) => {
            return {
              id: item.id,
              name: item.name,
            };
          }
        );
      }
    },

    async fetchRegions(force = false) {
      if (this.regions.length == 0 || force) {
        const regions = await listCountries();
        this.regions = regions.map((item: { country: string }) => {
          return {
            text: item.country,
            value: item.country,
          };
        });
      }
    },
    async fetchCountries(force = false) {
      if (this.countries.length == 0 || force) {
        const countries = await listCountries();
        this.countries = countries.map((item: { country: string }) => {
          return {
            text: item.country,
            value: item.country,
          };
        });
      }
    },
    async fetchClusters(force = false) {
      if (this.clusters.length == 0 || force) {
        const clusters = await listClustersForFilter();
        this.clusters = clusters.map((item: { name: string; id: number }) => {
          return {
            text: item.name,
            value: item.id,
          };
        });
      }
    },
    async fetchConfidenceTypes(force = false) {
      if (this.confidence_types.length == 0 || force) {
        this.confidence_types = await listConfidencesTypes();
      }
    },
    async fetchEmployeesRanges(force = false) {
      if (this.employees_ranges.length == 0 || force) {
        const employees_range = await listEmployeesRanges();
        this.employees_ranges = employees_range.map(
          (item: { value: string; id: number }) => {
            return {
              text: item.value,
              value: item.id,
            };
          }
        );
      }
    },
    async fetchCompanyTypes(force = false) {
      if (this.company_types.length == 0 || force) {
        const types = await listCompanyTypes();
        this.company_types = types.map((item: { name: string; id: number }) => {
          return {
            text: item.name,
            value: item.id,
          };
        });
      }
    },
    async fetchUserTypes(force = false) {
      if (this.user_types.length == 0 || force) {
        const types = await listUserTypes();
        this.user_types = types.map((item: { name: string; id: number }) => {
          return {
            text: item.name,
            value: item.id,
          };
        });
      }
    },
    async fetchNetworkTypes(force = false) {
      if (this.network_types.length == 0 || force) {
        const network_types = await listNetworkTypes();
        this.network_types = network_types.map(
          (item: { value: string; id: number }) => {
            return {
              text: item.value,
              value: item.id,
            };
          }
        );
      }
    },
    async fetchQuantityUnits(force = false) {
      if (this.quantity_units.length == 0 || force) {
        const quantity_units = await listQuantityUnits();
        this.quantity_units = quantity_units.map(
          (item: { name: string; id: number }) => {
            return {
              text: item.name,
              value: item.id,
            };
          }
        );
      }
    },
    async fetchInputTypes(force = false) {
      if (this.input_types.length == 0 || force) {
        const input_types = await listInputTypes();
        input_types.forEach((input_type: { [x: string]: string }) => {
          input_type["value"] = "" + input_type["id"];
          input_type["html"] =
            input_type["type"] +
            (input_type["description"] != null
              ? '<br/><span class="radio-description">' +
                input_type["description"] +
                "</span>"
              : "");
        });
        this.input_types = input_types;
      }
    },
    async fetchOutputTypes(force = false) {
      if (this.output_types.length == 0 || force) {
        const output_types = await listOutputTypes();
        output_types.forEach((output_type: { [x: string]: string }) => {
          output_type["value"] = "" + output_type["id"];
          output_type["html"] =
            output_type["type"] +
            (output_type["description"] != null
              ? '<br/><span class="radio-description">' +
                output_type["description"] +
                "</span>"
              : "");
        });
        this.output_types = output_types;
      }
    },
    async fetchOutputVirtualTypes(force = false) {
      if (this.output_types_virtual.length == 0 || force) {
        const output_types_virtual = await listOutputVirtualTypes();
        output_types_virtual.forEach((output_type: { [x: string]: string }) => {
          output_type["value"] = "" + output_type["id"];
          output_type["html"] =
            output_type["type"] +
            (output_type["description"] != null
              ? '<br/><span class="radio-description">' +
                output_type["description"] +
                "</span>"
              : "");
        });
        this.output_types_virtual = output_types_virtual;
      }
    },

    async fetchWasteTypes(force = false) {
      if (this.waste_types.length == 0 || force) {
        const waste_types = await listWasteTypes();
        waste_types.forEach((waste_type: { [x: string]: string }) => {
          waste_type["value"] = "" + waste_type["id"];
          waste_type["html"] = waste_type["name"];
        });
        this.waste_types = waste_types;
      }
    },

    async fetchCircularTypes(force = false) {
      if (this.circular_types.length == 0 || force) {
        const circular_types = await listCircularTypes();
        circular_types.forEach((circular_type: { [x: string]: string }) => {
          circular_type["value"] = "" + circular_type["id"];
          circular_type["html"] = circular_type["name"];
        });
        this.circular_types = circular_types;
      }
    },
    async fetchTags(force = false) {
      if (this.tags.length == 0 || force) {
        const types = await listTags();
        this.tags = types.map((item: { keyword: string }) => {
          return item.keyword;
        });
      }
    },
    async fetchApplicationTags(force = false) {
      if (this.application_tags.length == 0 || force) {
        const types = await listApplicationTags();
        this.application_tags = types.map((item: { tag: string }) => {
          return item.tag;
        });
      }
    },
    async fetchProcessTags(force = false) {
      if (this.processes_tags.length == 0 || force) {
        const types = await listProcessTags();
        this.tags = types.map((item: { tag: string }) => {
          return item.tag;
        });
      }
    },
    fetchClassificationValues() {
      this.solution_types_values = [
        { value: 0, text: "Machine" },
        { value: 1, text: "Material" },
        { value: 2, text: "Process" },
      ];
      this.solving_values = [
        { value: 0, text: "Byproducts" },
        { value: 1, text: "Processes" },
      ];
      this.treatment_values = [
        { value: 0, text: "Optimization" },
        { value: 1, text: "Reprocessing" },
      ];
    },
    fetchBiolinksStatus() {
      this.biolinks_status = [
        { value: 0, text: "Not tested" },
        { value: 1, text: "In progress" },
        { value: 2, text: "Successful" },
        { value: 3, text: "Not successful" },
      ];
      this.biolinks_category = [
        { value: 1, text: "Business case 1: success probability 75%" },
        { value: 2, text: "Business case 2: success probability 50%" },
        { value: 3, text: "Business case 3: success probability 25%" },
        { value: 4, text: "Business case 1: success probability 10%" },
      ];
      this.biolinks_outcome = [
        { value: 0, text: "None" },
        { value: 1, text: "Worked" },
        { value: 2, text: "Commercial" },
        { value: 3, text: "Did not work" },
      ];
    },
    async fetchModelStatus() {
      const modelStatus = await listModelStatus();
      console.log("ModelStatus value:", modelStatus);

      modelStatus.forEach((e: { id: number; text: string; value: string }) => {
        e.text = e.value;
      });

      this.biolink_model_status = modelStatus;

      /*    this.biolink_model_status = [
                   {
                       text: "Idea",
                       value: "Idea"
                   }, {
                       text: "In development",
                       value: "In development"
                   },
                       {
                           text: "Implementable",
                           value: "Implementable"
                       }
               ] */
    },

    async fetchRequestSupport() {
      const requestSupport = await listSupportRequest();
      console.log("requestSupport value:", requestSupport);
      this.request_support_model = requestSupport.map(
        (item: { value: string; id: number }) => {
          return {
            id: item.id,
            value: item.value,
          };
        }
      );
    },
    async fetchContributorsRefs() {
      const res = await listContributorsRefs();
      this.contributors_refs = res;
    },
    async fetchIsLoggedIn(force = false) {
      if (this.logged_in == null || force) {
        const logged_in = await isLoggedIn();
        this.logged_in = logged_in.logged_in;
        this.logged_user = logged_in.user;
      }
    },
    async logout() {
      const graphStore = useGraphStore();
      const graphListStore = useGraphsListStore();
      
      graphStore.resetFilters();
      graphListStore.resetFilter();
      graphListStore.resetRegionFilters();

      localStorage.clear();
      sessionStorage.clear();
      
      const logged_in = await logout();
      this.logged_in = logged_in.logged_in;
      this.logged_user = logged_in.user;
    },
    async fetchGuidelines(force = false) {
      if (this.logged_in || force) {
        this.guideline_version = (await getGuidelinesVersion()).version;
        const guidelines_text = await getGuidelinesText();
        this.tou_text = guidelines_text.tou_text;
        this.data_policy_text = guidelines_text.data_policy_text;
        this.privacy_policy_text = guidelines_text.privacy_policy_text;
      }
    },
    async fetchInvitationClusterName() {
      if (this.logged_user.cluster_id != null) {
        const res = await getCluster(this.logged_user.cluster_id);
        this.invitation_cluster_name = res["name"];
      }
    },
    getInputType(id: number) {
      let value = "";
      this.input_types.forEach((element) => {
        if (element.id == id) {
          value = element.type;
        }
      });
      return value;
    },
    getOutputType(id: number) {
      let value = "";
      this.output_types.forEach((element) => {
        if (element.id == id) {
          value = element.type;
        }
      });
      this.output_types_virtual.forEach((element) => {
        if (element.id == id) {
          value = element.type;
        }
      });
      return value;
    },
    getCompanyType(id: number) {
      let value = "";
      this.company_types.forEach((element) => {
        if (element.value == id) {
          value = element.text;
        }
      });
      return value;
    },
    getUserType(id: number) {
      let value = "";
      this.user_types.forEach((element) => {
        if (element.value == id) {
          value = element.text;
        }
      });
      return value;
    },
    getNetworkType(id: number) {
      let value = "";
      this.network_types.forEach((element) => {
        if (element.value == id) {
          value = element.text;
        }
      });
      return value;
    },
    getEmployeesRange(id: number) {
      let value = "";
      this.employees_ranges.forEach((element) => {
        if (element.value == id) {
          value = element.text;
        }
      });
      return value;
    },
    getBiolinkStatus(id: number) {
      let value = "";
      this.biolinks_status.forEach((element) => {
        if (element.value == id) {
          value = element.text;
        }
      });
      return value;
    },
    getBiolinkCategory(id: number) {
      let value = "";
      this.biolinks_category.forEach((element) => {
        if (element.value == id) {
          value = element.text;
        }
      });
      return value;
    },
    getBiolinkOutcome(id: number) {
      let value = "";
      this.biolinks_outcome.forEach((element) => {
        if (element.value == id) {
          value = element.text;
        }
      });
      return value;
    },

    getNaceText(code: string) {
      let value = "";
      this.naces.forEach((element) => {
        if (element.code == code) {
          value = element.text;
        }
      });
      return value;
    },
    getSolutionType(id: number) {
      let value = "";
      this.solution_types_values.forEach((element) => {
        if (element.value == id) {
          value = element.text;
        }
      });
      return value;
    },
    getSolving(id: number) {
      let value = "";
      this.solving_values.forEach((element) => {
        if (element.value == id) {
          value = element.text;
        }
      });
      return value;
    },
    getTreatment(id: number) {
      let value = "";
      this.treatment_values.forEach((element) => {
        if (element.value == id) {
          value = element.text;
        }
      });
      return value;
    },
    getConfidence(id: number) {
      let value = new ConfidenceType();
      this.confidence_types.forEach((element) => {
        if (element.id == id) {
          value = element;
        }
      });
      return value;
    },
    showToast(
      message: string,
      type: "success" | "error" | "passive" | undefined = "success",
      duration = 3000
    ) {
      this.toast = {
        type: type,
        message: message,
        duration: duration,
      };
    },
    async fetchAndShowCompanyModal(id: number, full_height = false) {
      const company = await getCompany(id);
      company.additionnal_naces = company.additionnal_naces.map(
        (item: { nace_code: string }) => {
          return item.nace_code;
        }
      );
      company.keywords = company.keywords.map((item: { keyword: string }) => {
        return item.keyword;
      });
      console.log("hi", company);
      company.valuechains.forEach(
        (valuechain: { inputs: any[]; outputs: any[] }) => {
          valuechain.inputs.forEach((input) => {
            // input.from_whom = input.from_whom.map((item: { value: string; }) => {
            //     return item.value;
            // });
          });
          valuechain.outputs.forEach((output) => {
            // output.for_whom = output.for_whom.map((item: { value: string; }) => {
            //     return item.value;
            // });
          });
        }
      );
      company.company_type = company._company_type_ref;
      company.employees_range = company._employees_range_ref;
      const documents = await getDocumentsForCompany(id);
      if (documents)
        documents.forEach(
          (document: {
            public: number | boolean;
            cluster_share: number | boolean;
          }) => {
            document.public = document.public == 1;
            document.cluster_share = document.cluster_share == 1;
          }
        );
      company.documents = documents;

      const biolinks = await listBioSummary(id, 0);
      company.biolinks = biolinks;
      this.modal_full_height = full_height;
      this.companyModal = company;
      this.last_modal_type = "company";
      this.showCompanyModal = true;
    },
    async fetchAndShowAlternativeModal(
      id: number,
      mode: string,
      _full_height = false
    ) {
      this.showAlternativeModal = true;
      this.last_modal_type = "alternative";
    },
    setShowAlternativeModalInOut() {
      this.showAlternativeModalInOut = true;
      this.last_modal_type = "alternative";
    },
    // async fetchAndShowVirtualCompanyModal(id: number, full_height = false) {
    //     const company = await getCompany(id);
    //     console.log(company,'company')
    //     company.additionnal_naces = company.additionnal_naces?.map((item: { nace_code: string; }) => {
    //         return item.nace_code;
    //     });
    //     company.keywords = company.keywords?.map((item: { keyword: string; }) => {
    //         return item.keyword;
    //     });
    //     company.valuechains?.forEach((valuechain: { inputs: any[]; outputs: any[]; }) => {
    //         valuechain.inputs?.forEach((input) => {
    //             // input.from_whom = input.from_whom.map((item: { value: string; }) => {
    //             //     return item.value;
    //             // });
    //         });
    //         valuechain.outputs?.forEach((output) => {
    //             // output.for_whom = output.for_whom.map((item: { value: string; }) => {
    //             //     return item.value;
    //             // });
    //         });
    //     });
    //     company.company_type = company._company_type_ref;
    //     company.employees_range = company._employees_range_ref;
    //     const documents = await getDocumentsForCompany(id);
    //     if (documents)
    //         documents.forEach((document: { public: number | boolean; cluster_share: number | boolean; }) => {
    //             document.public = document.public == 1;
    //             document.cluster_share = document.cluster_share == 1;
    //         });
    //     company.documents = documents;
    //     const biolinks = await listBiolinksSummary(id,0);
    //     company.biolinks = biolinks;
    //     this.modal_full_height = full_height;
    //     this.virtualCompanyModal = company;
    //     this.last_modal_type = "virtual_company";
    //     this.showVirtualCompanyModal = true;
    //     this.editVirtualModal = false;
    //     this.addEditVirtualModal = false;
    //     this.createVirtualModal= false;

    // },
    addSolutionNaceEntry(idx: number) {
      this.virtualCompanyModal.valuechains[idx].additionnal_naces.push("");
      //this.modified = true;
    },
    removeSolutionNaceEntry(idx: number, to_delete: string) {
      const index =
        this.virtualCompanyModal.valuechains[idx].additionnal_naces.indexOf(
          to_delete
        );
      if (index > -1) {
        // only splice array when item is found
        this.virtualCompanyModal.valuechains[idx].additionnal_naces.splice(
          index,
          1
        ); // 2nd parameter means remove one item only
      }
      this.modified = true;
    },
    addNaceEntry() {
      this.virtualCompanyModal.additionnal_naces.push("");
      //this.modified = true;
    },
    removeNaceEntry(to_delete: string) {
      const index =
        this.virtualCompanyModal.additionnal_naces.indexOf(to_delete);
      if (index > -1) {
        // only splice array when item is found
        this.virtualCompanyModal.additionnal_naces.splice(index, 1); // 2nd parameter means remove one item only
      }
      this.modified = true;
    },
    async fetchAndEditVirtualCompanyModal(id: number, full_height = false) {
      if (id == 0) {
        this.editVirtualModal = false;
        this.createVirtualModal = true;
        this.virtualCompanyModal = new Company();
        this.virtualCompanyModal.virtual_company = 1;
        console.log(this.virtualCompanyModal, "company edit ");
      } else {
        const company = await getCompany(id);

        company.additionnal_naces = company.additionnal_naces.map(
          (item: { nace_code: string }) => {
            return item.nace_code;
          }
        );
        company.keywords = company.keywords.map((item: { keyword: string }) => {
          return item.keyword;
        });
        company.valuechains.forEach(
          (valuechain: { inputs: any[]; outputs: any[] }) => {
            valuechain.inputs.forEach((input) => {
              // input.from_whom = input.from_whom.map((item: { value: string; }) => {
              //     return item.value;
              // });
            });
            valuechain.outputs.forEach((output) => {
              // output.for_whom = output.for_whom.map((item: { value: string; }) => {
              //     return item.value;
              // });
            });
          }
        );
        company.company_type = company._company_type_ref;
        company.employees_range = company._employees_range_ref;
        // const documents = await getDocumentsForCompany(id);
        // if (documents)
        //     documents.forEach((document: { public: number | boolean; cluster_share: number | boolean; }) => {
        //         document.public = document.public == 1;
        //         document.cluster_share = document.cluster_share == 1;
        //     });
        //     company.documents = documents;
        const biolinks = await listBioSummary(id, 0);
        company.biolinks = biolinks;

        this.virtualCompanyModal = company;
        console.log(this.virtualCompanyModal, "company edit ");
        this.editVirtualModal = true;
        this.createVirtualModal = false;
      }

      this.showVirtualCompanyModal = false;
      this.last_modal_type = "virtual_edit_company";
      this.addEditVirtualModal = true;
      this.modal_full_height = full_height;
    },
    async fetchAndShowClusterModal(id: number, full_height = false) {
      const cluster = await getCluster(id);
      this.modal_full_height = full_height;
      this.clusterModal = cluster;
      this.last_modal_type = "cluster";
      this.showClusterModal = true;
    },
    async fetchAndShowBiolinkModal(id: number, full_height = false) {
      const biolink = await getBiolink(id);
      console.log(biolink, "biolink");
      this.modal_full_height = full_height;
      this.biolinkModal = biolink.biolink;
      this.last_modal_type = "biolink";
      this.showBiolinkModal = true;
    },

    async fetchAndShowTechnologyModal(id: number, full_height = false) {
      const technology = await getValueChain(id);
      technology.additionnal_naces = technology.additionnal_naces.map(
        (item: { nace_code: string }) => {
          return item.nace_code;
        }
      );
      technology.keywords = technology.keywords.map(
        (item: { keyword: string }) => {
          return item.keyword;
        }
      );
      const documents = await getDocumentsForSolution(id);
      if (documents)
        documents.forEach(
          (document: {
            public: number | boolean;
            cluster_share: number | boolean;
          }) => {
            document.public = document.public == 1;
            document.cluster_share = document.cluster_share == 1;
          }
        );
      technology.documents = documents;
      this.modal_full_height = full_height;
      this.technologyModal = technology;
      this.last_modal_type = "technology";
      this.showTechnologyModal = true;
      this.editTechnologyModal = false;
      this.addEditTechnologyModal = false;
      this.createTechnologyModal = false;
    },
    addSolutionTechnologyNaceEntry(idx: number) {
      this.technologyModal.company.valuechains[idx].additionnal_naces.push("");
      //this.modified = true;
    },
    removeSolutionTechnologyNaceEntry(idx: number, to_delete: string) {
      const index =
        this.technologyModal.company.valuechains[idx].additionnal_naces.indexOf(
          to_delete
        );
      if (index > -1) {
        // only splice array when item is found
        this.technologyModal.company.valuechains[idx].additionnal_naces.splice(
          index,
          1
        ); // 2nd parameter means remove one item only
      }
      this.modified = true;
    },
    addNaceTechnologyEntry() {
      this.technologyModal.company.additionnal_naces.push("");
      console.log("addNaceTechnologyEntry");
    },
    removeNaceTechnologyEntry(to_delete: string) {
      const index =
        this.technologyModal.company.additionnal_naces.indexOf(to_delete);
      if (index > -1) {
        // only splice array when item is found
        this.technologyModal.company.additionnal_naces.splice(index, 1); // 2nd parameter means remove one item only
      }
      this.modified = true;
    },

    async fetchAndEditTechnologyModal(id: number, full_height = false) {
      if (id == 0) {
        this.editTechnologyModal = false;
        this.createTechnologyModal = true;
        this.technologyModal.company.is_solution_provider = true;
        this.technologyModal = new Descriptor();
        this.technologyModal.company.is_solution_provider = true;
      } else {
        const technology = await getValueChain(id);
        technology.additionnal_naces = technology.additionnal_naces.map(
          (item: { nace_code: string }) => {
            return item.nace_code;
          }
        );
        technology.keywords = technology.keywords.map(
          (item: { keyword: string }) => {
            return item.keyword;
          }
        );
        const documents = await getDocumentsForSolution(id);
        if (documents)
          documents.forEach(
            (document: {
              public: number | boolean;
              cluster_share: number | boolean;
            }) => {
              document.public = document.public == 1;
              document.cluster_share = document.cluster_share == 1;
            }
          );

        technology.documents = documents;
        this.editTechnologyModal = true;
        this.createTechnologyModal = false;
        this.technologyModal = technology;

        const company = await getCompany(this.technologyModal.company.id);
        company.additionnal_naces = company.additionnal_naces.map(
          (item: { nace_code: string }) => {
            return item.nace_code;
          }
        );
        company.keywords = company.keywords.map((item: { keyword: string }) => {
          return item.keyword;
        });
        company.valuechains.forEach(
          (valuechain: { inputs: any[]; outputs: any[] }) => {
            valuechain.inputs.forEach((input) => {
              input.naces = input.naces.map((item: { nace_code: string }) => {
                return item.nace_code;
              });

              input.taric = input.taric.map((item: { idtaric: string }) => {
                return item.idtaric;
              });
              input.ewc = input.ewc.map((item: { idewc: string }) => {
                return item.idewc;
              });
              input.hazardous = input.hazardous == 1;
            });
            valuechain.outputs.forEach((output) => {
              output.naces = output.naces.map((item: { nace_code: string }) => {
                return item.nace_code;
              });
              output.taric = output.taric.map((item: { idtaric: string }) => {
                return item.idtaric;
              });
              output.ewc = output.ewc.map((item: { idewc: string }) => {
                return item.idewc;
              });
              output.hazardous = output.hazardous == 1;
            });
          }
        );
        company.is_solution_provider = company.is_solution_provider == 1;
        company.company_type = company._company_type_ref;
        company.employees_range = company._employees_range_ref;
        this.technologyModal.company = company;
        const documentsCompany = await getDocumentsForCompany(id);
        documentsCompany.forEach(
          (document: {
            public: number | boolean;
            cluster_share: number | boolean;
          }) => {
            document.public = document.public == 1;
            document.cluster_share = document.cluster_share == 1;
          }
        );
        this.technologyModal.company.documents = documents;
        // if (this.technologyModal.company.contact_phone === null)
        //     this.technologyModal.contact_phone = ""

        // if (this.technologyModal.contact_email === null)
        //     this.company.contact_email = ""

        // if (this.company.id_number === null)
        //     this.company.id_number = ""

        // if (this.company.website === null)
        //     this.company.website = ""
        // this.company.confidential = company.confidential == 1;
        // this.company.cluster_share = company.cluster_share == 1;
        if (this.technologyModal.company.is_solution_provider) {
          for (
            let i = 0;
            i < this.technologyModal.company.valuechains.length;
            i++
          ) {
            this.technologyModal.company.valuechains[i].additionnal_naces =
              this.technologyModal.company.valuechains[i].additionnal_naces.map(
                (item: any) => {
                  return item.nace_code;
                }
              );
            const documents = await getDocumentsForSolution(
              this.technologyModal.company.valuechains[i].id
            );
            documents.forEach(
              (document: {
                public: number | boolean;
                cluster_share: number | boolean;
              }) => {
                document.public = document.public == 1;
                document.cluster_share = document.cluster_share == 1;
              }
            );
            this.technologyModal.company.valuechains[i].documents = documents;
            this.technologyModal.company.valuechains[i].keywords =
              this.technologyModal.company.valuechains[i].keywords.map(
                (item: any) => {
                  return item.keyword;
                }
              );
          }
        } else {
          for (
            let i = 0;
            i < this.technologyModal.company.valuechains.length;
            i++
          ) {
            this.technologyModal.company.valuechains[i].documents = [];
            this.technologyModal.company.valuechains[i].additionnal_naces = [];
            this.technologyModal.company.valuechains[i].keywords = [];
          }
        }
      }
      this.modal_full_height = full_height;

      this.last_modal_type = "technology";
      this.showTechnologyModal = false;
      this.addEditTechnologyModal = true;
      this.modal_full_height = full_height;
    },
    async deleteDescriptorTechnology(idx: number) {
      await openConfirmDialog(
        "Delete value chain confirmation",
        "Are you sure you want to delete this value chain and all its inputs, processes and outputs ?",
        ModalType.Danger
      ).then((res) => {
        if (res) {
          this.technologyModal.company.valuechains.splice(idx, 1);
          this.modified = true;
        }
      });
    },

    hideCompanyModal() {
      this.showCompanyModal = false;
      this.companyModal = new Company();
    },
    hideVirtualCompanyModal() {
      this.showVirtualCompanyModal = false;
      this.editVirtualModal = false;
      this.createVirtualModal = false;
      this.addEditVirtualModal = false;
      this.virtualCompanyModal = new Company();
    },
    hideAlternativeModal() {
      this.showAlternativeModal = false;
    },
    hideAlternativeModalInOut() {
      this.showAlternativeModalInOut = false;
    },
    hideClusterModal() {
      this.showClusterModal = false;
      this.clusterModal = new Cluster();
    },
    hideBiolinkModal() {
      this.showBiolinkModal = false;
      this.biolinkModal = new Biolink();
    },
    hideTechnologyModal() {
      this.editTechnologyModal = false;
      this.createTechnologyModal = false;
      this.addEditTechnologyModal = false;
      this.showTechnologyModal = false;
      this.technologyModal = new Descriptor();
    },
    hideVirtualProcessModal() {
      this.showVirtualProcessModal = false;
      this.editProcessModal = false;
      this.createProcessModal = false;
      this.addEditProcessModal = false;
      this.virtualCompanyModal = new Company();
    },

    naceFuseSearch(options: readonly unknown[], search: string) {
      const fuse = new Fuse(options, {
        keys: ["text", "include"],
        shouldSort: true,
        threshold: 0.0,
        ignoreLocation: true,
      });
      return fuse.search(search).map(({ item }) => item);
    },
    regionFuseSearch(options: readonly unknown[], search: string) {
      const fuse = new Fuse(options, {
        keys: ["text", "include"],
        shouldSort: true,
      });
      return fuse.search(search).map(({ item }) => item);
    },
    async fetchLastExport(force = false) {
      this.last_export = await getLastExportTime();
    },
    async hideGraphModal() {
      this.showGraphModal = false;
      this.graphModal = new GraphDetails();
    },
    async fetchAndShowGraphModal(id: number, full_height = false) {
      const graph = await getGraphDetails(id);
      const companies = await getGraphCompanies(id);
      this.modal_full_height = full_height;
      this.graphModal = graph;
      this.graphCompanies = companies;
      this.last_modal_type = "graph";
      this.showGraphModal = true;
      this.visible = true;
    },
  },
});
