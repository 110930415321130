import { API_URL } from "@/config";
import ConfirmDialog from "@/components/global/confirm-modal/ConfirmDialog.vue";
import { openDialog } from "vue3-promise-dialog";
import { Socket } from "rete";
import moment from "moment";

export function isSuperAdmin(roleNb: number) {
  return roleNb == 5;
}

export function isClusterAdmin(roleNb: number) {
  return roleNb == 4;
}

export function isClusterUser(roleNb: number) {
  return roleNb == 3;
}

export function isNotEmpty(str: string | Array<string>) {
  return str.length > 0;
}

export function isEmail(str: string) {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(str);
}

export function isOnlyDigits(s: string) {
  return /^\d+$/.test(s);
}

export function isOnlyLetters(str: string) {
  return /^[A-Za-z]*$/.test(str);
}

export function isStringAPositiveNumber(str: string) {
  const num = Number(str);

  return (Number.isInteger(num) || !isNaN(parseFloat(str))) && num > 0;
}

export function isString(v: unknown) {
  return typeof v === "string" || v instanceof String;
}

export const cnValidate = (cn: string) => {
  return cn.length === 12 && isOnlyDigits(cn);
};

export const ewcValidate = (ewc: string) => {
  const pattern = new RegExp("^\\d\\d \\d\\d( \\d\\d)?\\*?$");
  return pattern.test(ewc);
};

export function isWebsite(str: string) {
  return (
    /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(
      str
    ) && str.indexOf(" ") === -1
  );
}

export function vSelectValidationClass(str: string | Array<string> | null) {
  if (str !== null && isNotEmpty(str)) {
    return "border rounded-lg border-success";
  } else {
    return "border rounded-lg border-danger";
  }
}

export function kebab(str: string | string[]) {
  const replace = (s: string) => s.toLowerCase().replace(/ /g, "-");

  return Array.isArray(str) ? str.map(replace) : replace(str);
}

export function getThumbtailLink(logo_id: number) {
  return API_URL + "logo/thumbnail/" + logo_id;
}

export function getDocumentLink(document_id: number) {
  return API_URL + "document/" + document_id;
}

export function getExportCsvLink() {
  return API_URL + "csv_export";
}

export function formatDate(date: string) {
  if (date) return moment(new Date(date)).format("LLL") + " GMT";
  else return "";
}

export function formatDateWithoutTimeAndZone(date: string) {
  if (date) return moment(new Date(date)).format("LL");
  else return "";
}

export enum ModalType {
  Sucess = "success",
  Warning = "warning",
  Danger = "danger",
}

export async function openConfirmDialog(
  title: string,
  message: string,
  modalType: ModalType
) {
  return await openDialog(ConfirmDialog, { title, message, modalType });
}

export const outputTypeSocket = new Socket("output");
export const inputTypeSocket = new Socket("input");

outputTypeSocket.combineWith(inputTypeSocket);

export function validate_password(password: string) {
  const errors: boolean[] = new Array(5).fill(false);
  if (!/[A-Z]/.test(password)) errors[0] = true;

  if (!/[a-z]/.test(password)) errors[1] = true;

  if (!/\d/.test(password)) errors[2] = true;

  if (!/[#?!@$%^&*-]/.test(password)) errors[3] = true;

  if (password.length < 8) errors[4] = true;

  return errors;
}

export function getCountriesList() {
  return [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia (Plurinational State of)",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "British Virgin Islands",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "China, Hong Kong Special Administrative Region",
    "China, Macao Special Administrative Region",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Cook Islands",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czechia",
    "Côte d'Ivoire",
    "Democratic People's Republic of Korea",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Holy See",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia (Federated States of)",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Republic of Korea",
    "Republic of Moldova",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Réunion",
    "Saint Barthélemy",
    "Saint Helena",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French Part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Sark",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "State of Palestine",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen Islands",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Tajikistan",
    "Thailand",
    "The former Yugoslav Republic of Macedonia",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom of Great Britain and Northern Ireland",
    "United Republic of Tanzania",
    "United States Minor Outlying Islands",
    "United States Virgin Islands",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela (Bolivarian Republic of)",
    "Viet Nam",
    "Wallis and Futuna Islands",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
    "Åland Islands",
  ];
}
