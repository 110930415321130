import {request_api} from "@/api/request";
import type {GraphName, ModelStatus} from "@/types";

export async function listGraphs(params: Record<string, unknown>) {
    return await request_api("graphs", {
        method: "GET",
        queryStringGet: params,
    });
}
export async function listClusterBiolinks(filters: Record<string, unknown>,cluster_id:number) {
    return await request_api("cluster/" + cluster_id + "/biolinks", {
        method: "GET",
        queryStringGet: filters,
    });
  }

  export async function listClusterBiolinksModels(filters: Record<string, unknown>,cluster_id:number) {
    filters['includeContributor'] = 'yes'
    return await request_api("cluster/" + cluster_id + "/biolink_models", {
        method: "GET",
        queryStringGet: filters,
    });
  }

export async function listBiolinksSummary(params: Record<string, unknown>) {
    return await request_api("graphs", {
      method: "GET",
      queryStringGet: params,
    });
  }
  export async function listBioSummary(company_id: number,model:number) {
    return await request_api("graphs", {
      method: "GET",
      queryStringGet: {"companyFilter":company_id.toString(),"model":model},
    });
  }
export async function createGraph(graphData: object) {
    return await request_api("graphs", {
        method: "POST",
        body: graphData,
    });
}

export async function updateGraph(graph_id: number, graphData: object) {
    return await request_api("graphs/" + graph_id, {
        method: "PUT",
        body: graphData,
    });
}

export async function duplicateGraph(graph_id: number, graphData: object) {
    return await request_api("graphs/" + graph_id + "/duplicate", {
        method: "POST",
        body: graphData,
    });
}


export async function getGraph(id: number) {
    return await request_api("graphs/" + id, {
        method: "GET",
    });
}
export async function getGraphDetails(id: number) {
    return await request_api("graphs/" + id + "/details", {
        method: "GET",
    });
}
export async function getGraphCompanies(id: number) {
    return await request_api("graphs/" + id + "/companies", {
        method: "GET",
    });
}
export async function getCircularPotential(id: number, params: Record<string, unknown>) {
    return await request_api("graphs/" + id + "/circularmarket", {
        method: "GET",
        queryStringGet: params,
    });
}

export async function getCircularCompanies(id:number,node_id: number,params: Record<string, unknown>) {
    return await request_api("graphs/" + id + "/circularmarket/" + node_id , {
        method: "GET",
        queryStringGet: params,
    });
}
export async function getCircularPotentialRegion(id: number,region: string) {
    return await request_api("graphs/" + id + "/circularmarket/" + region  , {
        method: "GET",
    });
}
export async function getCircularCompaniesRegion(id:number,region: string, node_id: number,params: Record<string, unknown>) {
    return await request_api("graphs/" + id + "/circularmarket/" + region + "/" + node_id , {
        method: "GET",
        queryStringGet: params,
    });
}
export async function getGraphLight(id: number) {
    return await request_api("graphs/light/" + id, {
        method: "GET",
    });
}

export async function deleteGraph(graph_id: number) {
    return await request_api("graphs/" + graph_id, {
        method: "DELETE",
    });
}

export async function getModel(id: number) {
    return await request_api(`graphs/${id}/model`, {
        method: "GET",
    },)
}

export async function createModel(graph_id: number, modelData: ModelStatus) {
    return await request_api(`graphs/${graph_id}/model`, {
        method: "POST",
        body: modelData,
    });
}

export async function listContributorsRefs() {
    return await request_api(`graphs/contributors-refs`, {
        method: "GET",
    });
}

export async function addVirtualCompany(data: object) {
    return await request_api(`graphs/company`, {
        method: "POST",
        body: data,
    });
}

export async function getApplicationRegionTags(region:string){
    return await request_api(`graphs/region/${region}/application`, {
        method: "GET",
    })
}

export async function getApplicationTags(){
    return await request_api(`graphs/application`, {
        method: "GET",
    })
}

export async function getProcessRegionTags(region:string){
    return await request_api(`graphs/region/${region}/process`, {
        method: "GET",
    })
}

export async function getProcessTags(){
    return await request_api(`graphs/process`, {
        method: "GET",
    })
}
export async function getCompanyAppTags(application_id:number){
    return await request_api(`graphs/application/${application_id}`, {
        method: "GET",
    })
}
export async function getCompanyAppRegionTags(application_id:number,region:string){
    return await request_api(`graphs/region/${region}/application/${application_id}`, {
        method: "GET",
    })
}

export async function getCompanyProcessTags(process_id:number){
    return await request_api(`graphs/process/${process_id}`, {
        method: "GET",
    })
}
export async function getCompanyProcessRegionTags(process_id:number,region:string){
    return await request_api(`graphs/region/${region}/process/${process_id}`, {
        method: "GET",
    })
}
export async function addModelToMyCluster(graph_id: number) {
    return await request_api("graphs/cluster", {
        method: "POST",
        body: {graph_id: graph_id},
    });
}

export async function deleteModelToMyCluster(graph_id: number) {
    return await request_api("graphs/cluster", {
        method: "DELETE",
        body: {graph_id: graph_id},
    });
}

export async function chartFirstLayer(params: Record<string, unknown>) {
    return await request_api(`cluster_chart_layer_1`, {
        method: "GET",
        queryStringGet: params,
    });
}
export async function chartSecondLayer(params: Record<string, unknown>,code: string) {
    return await request_api(`cluster_chart_layer_2/${code}`, {
        method: "GET",
        queryStringGet: params,
    });
}

export async function chartThirdLayer(params: Record<string, unknown>,code: string) {
    return await request_api(`cluster_chart_layer_3/${code}`, {
        method: "GET",
        queryStringGet: params,
    });
}

export async function chartFourthLayer(params: Record<string, unknown>,code: string) {
    return await request_api(`cluster_chart_layer_4/${code}`, {
        method: "GET",
        queryStringGet: params,
    });
}   

export async function getSolutionsWithNace(code: string) {
    return await request_api(`biolink_models_for_nace/${code}`, {
        method: "GET"
    });
} 


export async function chartFirstTypeLayer(params: Record<string, unknown>) {
    return await request_api(`cluster_chart_layer_size_1`, {
        method: "GET",
        queryStringGet: params,
    });
}
export async function chartSecondTypeLayer(params: Record<string, unknown>,code: string) {
    return await request_api(`cluster_chart_layer_size_2/${code}`, {
        method: "GET",
        queryStringGet: params,
    });
}
export async function updateGraphName(graph_id: number, data: GraphName) {
    return await request_api(`graphs/${graph_id}/new`, {
        method: "PATCH",
        body: data,
    });
}
export async function deleteCandidateFromProject(company_id: number, value_chain_id: number, graph_id: string) {
        return await request_api(`graphs/${graph_id}/candidate`, {
        method: "DELETE",
        body: {company_id: company_id, value_chain_id: value_chain_id},
    });
}
