import { defineStore } from "pinia";
import {
  listOurMembers,
  deleteCompanyFromMyCluster,
  listOurMembersMap,
  listMembers,
  listMembersMap,
} from "@/api/company";
import { getDocumentsForCompany } from "@/api/document";
import { Company, CompanySummary, MapSummary } from "@/types";
import { getCompany } from "@/api/company";
import { useLoader } from "./loader";

export const useMembersListStore = defineStore({
  id: "members_list",
  state: () => ({
    companies: [] as CompanySummary[],
    loaded: false,
    crtPage: 1,
    nbPages: 1,
    sortColumn: "name",
    sortOrder: "asc",
    typeSelected: "",
    regionSelected: "",
    textSearch: "",
    naceSearch: "",
    clusterId: 0, //if id is <1, then we'll fetch our cluster's companies
    showedCompany: new Company(),
    companies_map: [] as MapSummary[],
    showMap: false,
    loader: useLoader(),
  }),
  actions: {
    resetFilter() {
      this.textSearch = "";
      this.regionSelected = "";
      this.typeSelected = "";
      this.sortColumn = "name";
      this.sortOrder = "asc";
      this.crtPage = 1;
      this.naceSearch = "";
      this.showMap = false;
      this.fetchMembers();
    },
    async fetchMembers() {
      if (this.showMap) {
        return this.fetchCompaniesForMap();
      }
      this.loader.add();
      this.loaded = false;
      const params: { [id: string]: unknown } = {
        page: this.crtPage,
        perPage: 10,
        sortColumn: this.sortColumn,
        sortOrder: this.sortOrder,
      };
      if (this.typeSelected != "") {
        params["typeFilter"] = this.typeSelected;
      }
      if (this.regionSelected != "") {
        params["regionFilter"] = this.regionSelected;
      }
      if (this.naceSearch != null) {
        params["naceFilter"] = this.naceSearch;
      }
      if (this.textSearch != "") {
        params["textFilter"] = this.textSearch;
      }

      const companies =
        this.clusterId < 1
          ? await listOurMembers(params)
          : await listMembers(this.clusterId, params);
      this.companies = companies.items;
      this.nbPages = companies.nb_pages;
      this.crtPage = companies.page;
      this.loaded = true;
      this.loader.done();
    },

    async fetchCompaniesForMap() {
      const params: { [id: string]: unknown } = {};
      if (this.typeSelected != "") {
        params["typeFilter"] = this.typeSelected;
      }
      if (this.regionSelected != "") {
        params["regionFilter"] = this.regionSelected;
      }
      if (this.naceSearch != null) {
        params["naceFilter"] = this.naceSearch;
      }
      if (this.textSearch != "") {
        params["textFilter"] = this.textSearch;
      }

      this.companies_map =
        this.clusterId < 1
          ? await listMembersMap(this.clusterId, params)
          : await listOurMembersMap(params);
    },
    async changePage(newPage: number) {
      this.crtPage = newPage;
      this.fetchMembers();
    },
    async changeFreeText(newText: string) {
      this.textSearch = newText.trim();
      //this.fetchOurMembers();
      this.changePage(1);
    },
    async changeRegion(newRegion: string) {
      this.regionSelected = newRegion;
      //this.fetchOurMembers();
      this.changePage(1);
    },
    async changeType(newType: string) {
      this.typeSelected = newType;
      //this.fetchOurMembers();
      this.changePage(1);
    },
    async changeNace(newNace: string) {
      this.naceSearch = newNace;
      //this.fetchOurMembers();
      this.changePage(1);
    },

    //automatically goes into mode "cluster based on Id" if id given >0
    async setClusterId(id: number) {
      this.clusterId = id;
    },

    //goes back into  mode "cluster logged user is a part of" => to quit this mode, provide a cluster idy>0
    async setMyCluster() {
      this.clusterId = 0;
    },

    async fetchExistingCompany(id: number) {
      const company = await getCompany(id);
      company.additionnal_naces = company.additionnal_naces.map(
        (item: { nace_code: string }) => {
          return item.nace_code;
        }
      );
      company.keywords = company.keywords.map((item: { keyword: string }) => {
        return item.keyword;
      });
      company.valuechains.forEach(
        (valuechain: { inputs: any[]; outputs: any[] }) => {
          valuechain.inputs.forEach((input) => {
            input.from_whom = input.from_whom.map((item: { value: number }) => {
              return item.value;
            });
          });
          valuechain.outputs.forEach((output) => {
            output.for_whom = output.for_whom.map((item: { value: number }) => {
              return item.value;
            });
          });
        }
      );
      company.company_type = company._company_type_ref;
      company.employees_range = company._employees_range_ref;
      const documents = await getDocumentsForCompany(id);
      documents.forEach(
        (document: {
          public: number | boolean;
          cluster_share: number | boolean;
        }) => {
          document.public = document.public == 1;
          document.cluster_share = document.cluster_share == 1;
        }
      );
      company.documents = documents;
      this.showedCompany = company;
    },

    //TODO: only possible in "ours" mode (else throw error? do nothing?)
    async deleteCompanyFromMyClusterAction(company_id: number) {
      return await deleteCompanyFromMyCluster(company_id);
    },
  },
});
