import { request_api } from "@/api/request";

export async function listCountries() {
  return await request_api("data/countries", {
    method: "GET",
  });
}

export async function listNaces() {
  return await request_api("data/naces", {
    method: "GET",
  });
}

export async function listCompanyTypes() {
  return await request_api("data/company-types", {
    method: "GET",
  });
}

export async function listSupportRequest() {
  return await request_api("data/support-status", {
    method: "GET",
  });
}

export async function listUserTypes() {
  return await request_api("data/user-types", {
    method: "GET",
  });
}

export async function listInputTypes() {
  return await request_api("data/input-types", {
    method: "GET",
  });
}

export async function listWasteTypes() {
  return await request_api("data/waste-types", {
    method: "GET",
  });
}

export async function listCircularTypes() {
  return await request_api("data/circular-principle-types", {
    method: "GET",
  });
}
export async function listOutputTypes() {
  return await request_api("data/output-types", {
    method: "GET",
  });
}

export async function listOutputVirtualTypes() {
  return await request_api("data/output-types-virtual", {
    method: "GET",
  });
}

export async function listNetworkTypes() {
  return await request_api("data/network-types", {
    method: "GET",
  });
}

export async function listQuantityUnits() {
  return await request_api("data/quantity-units", {
    method: "GET",
  });
}

export async function listConfidencesTypes() {
  return await request_api("data/confidence-types", {
    method: "GET",
  });
}

export async function listEmployeesRanges() {
  return await request_api("data/employees-ranges", {
    method: "GET",
  });
}

export async function listTags() {
  return await request_api("data/tags", {
    method: "GET",
  });
}

export async function listApplicationTags() {
  return await request_api("data/application_tags", {
    method: "GET",
  });
}

export async function listProcessTags() {
  return await request_api("data/process_tags", {
    method: "GET",
  });
}

export async function listModelStatus() {
  return await request_api("data/model-status", {
    method: "GET",
  });
}