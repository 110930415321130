import {request_api} from "@/api/request";

export async function signUp(
    userData: object,
    callback: (status: number) => void = () => {
    }
) {
    return await request_api(
        "user/signup",
        {
            method: "POST",
            body: userData,
        },
        callback
    );
}

export async function signIn(
    userData: object,
    callback: (status: number) => void = () => {
    }
) {
    return await request_api(
        "user/signin",
        {
            method: "POST",
            body: userData,
        },
        callback
    );
}

export async function resetPassword(email: string) {
    return await request_api(
        "user/reset-password/" + email,
        {
            method: "GET",
        }
    );
}

export async function confirmResetPassword(token: string, newPassword: string) {
    return await request_api(
        "user/confirm-reset-password/" + token,
        {
            method: "POST",
            body: {"password": newPassword}
        }
    );
}

export async function confirmEmail(token: string) {
    return await request_api(
        "user/confirm/" + token,
        {
            method: "GET",
        }
    );
}

export async function isLoggedIn() {
    return await request_api("user/logged-in", {
        method: "GET",
    });
}

export async function logout() {
    return await request_api("user/logout", {
        method: "GET",
    });
}

export async function getGuidelinesText() {
    return await request_api("user/guidelines-text", {
        method: "GET",
    });
}

export async function getGuidelinesVersion() {
    return await request_api("user/guidelines-version", {
        method: "GET",
    });
}

export async function acceptGuidelines() {
    return await request_api("user/guidelines-version", {
        method: "POST",
        body: {agreecb: "on"},
    });
}

export async function acceptClusterInvitation() {
    return await request_api("user/cluster_invitation", {
        method: "POST",
        body: {decision: "accept"},
    });
}

export async function rejectClusterInvitation() {
    return await request_api("user/cluster_invitation", {
        method: "POST",
        body: {decision: "reject"},
    });
}

export async function inviteToCluster(email: string) {
    return await request_api("user/invite", {
        method: "POST",
        body: {email: email},
    });
}

export async function changeName(
    params: object,
    callback: (status: number) => void = () => {
    }
) {
    return await request_api(
        "user/username",
        {
            method: "POST",
            body: params,
        },
        callback
    );
}

export async function changePassword(
    params: object,
    callback: (status: number) => void = () => {
    }
) {
    return await request_api(
        "user/password",
        {
            method: "POST",
            body: params,
        },
        callback
    );
}


export async function listUsers(filters: Record<string, unknown>){
    return await request_api(
        "user",
        {
            method: "GET",
            queryStringGet: filters,
        }
    );
}

export async function getUserById(id: number) {
    return await request_api("user/" + id, {
        method: "GET",
    });
}

export async function deactivateUser(user_id: number, deactivate: number) {
    return await request_api("user/"+ user_id, {
        method: "PUT",
        body: {deactivate: deactivate},
    });
}

export async function addUser(userData: object) {
    console.log(userData);
    return await request_api("user", {
        method: "POST",
        body: userData,
    });
    
}  

export async function editUser(userData: object) {
    console.log(userData,'userData')
    return await request_api("user", {
        method: "PUT",
        body: userData,
    });
}