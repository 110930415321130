import {request_api} from "@/api/request";

export async function listCompanies(filters: Record<string, unknown>) {
    return await request_api("companies", {
        method: "GET",
        queryStringGet: filters,
    });
}

export async function listCompaniesMap(filters: Record<string, unknown>) {
    return await request_api("companies/map", {
        method: "GET",
        queryStringGet: filters,
    });
}

export async function listOurMembers(params: Record<string, unknown>) {
    params["perPage"] = 20;
    return await request_api("our-cluster/companies", {
        method: "GET",
        queryStringGet: params,
    });
}

export async function listOurMembersMap(filters: Record<string, unknown>) {
    return await request_api("our-cluster/companies/map", {
        method: "GET",
        queryStringGet: filters,
    });
}

export async function listMembers(cluster_id:number, params: Record<string, unknown>) {
    params["perPage"] = 20;
    return await request_api("cluster/"+cluster_id+"/companies", {
        method: "GET",
        queryStringGet: params,
    });
}

export async function listMembersForMap(cluster_id:number) {
    return await request_api("cluster/"+cluster_id+"/companies", {
        method: "GET",
    });
}

export async function listMembersMap(cluster_id:number, filters: Record<string, unknown>) {
    return await request_api("cluster/"+cluster_id+"/companies/map", {
        method: "GET",
        queryStringGet: filters,
    });
}


export async function listCompanyForBookmark(
    bookmark_id: number,
    params: Record<string, unknown>
) {
    params["perPage"] = 20;
    return await request_api("bookmarks/companies/" + bookmark_id, {
        method: "GET",
        queryStringGet: params,
    });
}

export async function getCompany(id: number) {
    return await request_api("companies/" + id, {
        method: "GET",
    });
}

export async function addCompany(companyData: object) {
    return await request_api("companies", {
        method: "POST",
        body: companyData,
    });
}

export async function editCompany(companyData: object) {
    return await request_api("companies", {
        method: "PUT",
        body: companyData,
    });
}

export async function deleteCompany(company_id: number) {
    return await request_api("companies/"+company_id, {
        method: "DELETE",
    });
}

export async function getInputById(inputId: number) {
    return await request_api("companies/input/" + inputId, {
        method: "GET",
    });
}

export async function getOutputById(outputId: number) {
    return await request_api("companies/output/" + outputId, {
        method: "GET",
    });
}

export async function addCompanyToMyCluster(company_id: number) {
    return await request_api("companies/cluster", {
        method: "POST",
        body: {company_id: company_id},
    });
}

export async function addCompaniesToMyCluster(company_ids: Array<number> ) {
    return await request_api("companies/cluster", {
        method: "POST",
        body: {company_ids: company_ids},
    });
}

export async function deleteCompaniesToMyCluster(company_ids: Array<number> ) {
    return await request_api("companies/cluster", {
        method: "DELETE",
        body: {company_ids: company_ids},
    });
}


export async function deleteCompanyFromMyCluster(company_id: number) {
    return await request_api("companies/cluster", {
        method: "DELETE",
        body: {company_id: company_id},
    });
}

export async function listCompaniesIdName() {
    return await request_api("companiesIdName", {
        method: "GET"
    });
}

export async function getSolutions(company_id: string) {
    return await request_api("companies/solutions/"+company_id, {
        method: "GET",
    });
}

export async function getBiolinkModels(company_id: string) {
    return await request_api("companies/biolink_models/"+company_id, {
        method: "GET",
    });
}