<template>
  <Box>
    <form class="box" onsubmit="return false">
      <div class="header">
        <slot name="header"></slot>
      </div>
      <div class="body">
        <slot name="body"></slot>
      </div>
      <div class="footer">
        <b-button
          type="button"
          class="p-button-raised"
          style="margin-right: 10px"
          @click="closeDialog(null)"
          >{{ cancelLabel }}
        </b-button>
        <b-button
          :variant="modalType"
          type="submit"
          class="p-button-raised"
          @click="closeDialog()"
          :disabled="!valid"
          >{{ okLabel }}
        </b-button>
      </div>
    </form>
  </Box>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import Box from "@/components/global/confirm-modal/Box.vue";
import { closeDialog } from "vue3-promise-dialog";
import type { ModalType } from "@/utils/utils";

export default defineComponent({
  components: { Box },
  props: {
    valid: {
      type: Boolean,
      default: true,
    },
    okLabel: {
      type: String,
      default: "OK",
    },
    cancelLabel: {
      type: String,
      default: "CANCEL",
    },
    modalType: {
      type: String as PropType<ModalType>,
      default: "danger",
    },
  },
  setup() {
    return {
      closeDialog,
    };
  },
});
</script>

<style scoped lang="scss">
.box {
  .header {
    color: black;
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    height: 50px;
    padding: 10px;
    font-size: 20px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }
}
</style>
