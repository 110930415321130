import {request_api} from "@/api/request";

export async function listClusters(params: Record<string, unknown>) {
    params["perPage"] = 20;
    return await request_api("clusters", {
        method: "GET",
        queryStringGet: params,
    });
}

export async function listClustersMap(params: Record<string, unknown>) {
    return await request_api("clusters/map", {
        method: "GET",
        queryStringGet: params,
    });
}

export async function listClustersForFilter() {
    return await request_api("clusters/filter", {
        method: "GET",
    });
}

export async function getOurCluster() {
    return await request_api("our-cluster", {
        method: "GET",
    });
}

export async function getOurClusterOverview() {
    return await request_api("our-cluster/overview", {
        method: "GET",
    });
}

export async function getCluster(id: number) {
    return await request_api("clusters/" + id, {
        method: "GET",
    });
}

export async function getClusterCompanies(id: number) {
    return await request_api("clusters/" + id +"/companies", {
        method: "GET",
    });
}

// export async function getClusterOverview(id: number) {
//     return await request_api("get_cluster_overview/" + id, {
//         method: "GET",
//     });
// }

export async function addCluster(
    clusterData: object,
    callback: (status: number) => void = () => {
    }
) {
    return await request_api(
        "clusters",
        {
            method: "POST",
            body: clusterData,
        },
        callback
    );
}

export async function editCluster(
    clusterData: object,
    callback: (status: number) => void = () => {
    }
) {
    return await request_api(
        "clusters",
        {
            method: "PUT",
            body: clusterData,
        },
        callback
    );
}
