import { createI18n } from "vue-i18n";

import { Locales } from "@/utils/locales";
import en from "@/locales/en.json";
import { useNumberFormats } from "@/composables/useNumberFormats";

const getUserLocale = (): Locales => {
  const lang = localStorage.getItem("language_setting") || navigator.language;
  switch (lang) {
    case Locales.EN:
      return Locales.EN;
    case Locales.DE:
      return Locales.DE;
    default:
      return Locales.EN;
  }
};

const { numberFormats } = useNumberFormats();

export const locale = getUserLocale();

export const i18n = createI18n<[typeof en], Locales.EN>({
  locale,
  fallbackLocale: Locales.EN,
  legacy: false,
  numberFormats,
  messages: {
    [Locales.EN]: en,
  },
});
