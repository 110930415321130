import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Home.vue"),
    },
    {
      path: "/sign-up",
      name: "signup",
      component: () => import("@/views/SignUp.vue"),
    },
    {
      path: "/confirm",
      name: "confirm",
      component: () => import("@/views/ConfirmEmail.vue"),
    },
    {
      path: "/password-reset",
      name: "password-reset",
      component: () => import("@/views/PasswordReset.vue"),
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("@/views/Profile.vue"),
    },
    {
      path: "/create-graph",
      name: "create-graph",
      component: () => import("@/views/GraphCreationView.vue"),
      props: { isModel: false },
    },
    {
      path: "/potential-market/:id",
      name: "potential-market",
      component: () =>
        import("@/components/solutions/ListBiolinkModelsDashBoard.vue"),
    },
    {
      path: "/create-biolink-model",
      name: "create-biolink-model",
      component: () => import("@/views/GraphCreationView.vue"),
      props: { isModel: true },
    },
    {
      path: "/saved-graphs",
      name: "saved-graphs",
      component: () => import("@/views/ListGraphsView.vue"),
    },
    {
      path: "/graph",
      name: "graph",
      component: () => import("@/views/GraphView.vue"),
    },
    {
      path: "/biolink-model",
      name: "model",
      component: () => import("@/views/GraphView.vue"),
    },
    {
      path: "/graph/:id",
      component: () => import("@/views/GraphView.vue"),
      props: { isModel: true },
    },
    {
      path: "/solutions",
      name: "browse-solutions",
      component: () => import("@/views/SolutionsView.vue"),
      redirect: "/solutions/biolink-models",
      children: [
        {
          path: "biolink-models",
          name: "biolinks-models",
          component: () =>
            import("@/components/solutions/ListBiolinkModels.vue"),
        },
        {
          path: "technologies",
          name: "technologies",
          component: () =>
            import("@/components/solutions/ListTechnologies.vue"),
        },
      ],
    },
    {
      path: "/modeling",
      name: "modeling",
      component: () => import("@/views/ModelingDataView.vue"),
      redirect: "/modeling/virtual-companies",
      children: [
        {
          path: "virtual-companies",
          name: "virtual-companies",
          component: () => import("@/components/ListVirtualCompanies.vue"),
        },
        {
          path: "virtual-processes",
          name: "virtual-processes",
          component: () => import("@/components/ListVirtualProcesses.vue"),
        },
      ],
    },
    {
      path: "/organisation",
      name: "organisation",
      component: () => import("@/views/ClusterOrgView.vue"),
      redirect: "/organisation/profile",
      children: [
        {
          path: "profile",
          name: "profile",
          component: () => import("@/views/OurCluster.vue"),
        },
        {
          path: "users",
          name: "users",
          component: () => import("@/views/ClusterUsers.vue"),
        },
      ],
    },
    {
      path: "/network",
      name: "network",
      component: () => import("@/views/ClusterOrgView.vue"),
      redirect: "/network/region",
      children: [
        {
          path: "members",
          name: "members",
          component: () => import("@/views/ClusterNetwork.vue"),
        },
        {
          path: "region",
          name: "region",
          component: () => import("@/components/DashBoardRegion.vue"),
        },
      ],
    },

    {
      path: "/models",
      component: () => import("@/views/ClusterModels.vue"),
    },
    {
      path: "/technologies",
      component: () => import("@/views/ClusterSolutions.vue"),
    },

    {
      path: "/browse",
      component: () => import("@/views/BrowseView.vue"),
      name: "browse",
      redirect: "/browse/companies",
      children: [
        {
          path: "companies",
          name: "browse-companies",
          component: () => import("@/components/ListCompanies.vue"),
          props: { showHeader: true },
        },
        {
          path: "virtual-companies",
          name: "browse-virtual-companies",
          component: () => import("@/components/ListVirtualCompanies.vue"),
        },
        {
          path: "clusters",
          name: "browse-clusters",
          component: () => import("@/components/ListClusters.vue"),
        },
        /*   {
                    path: "biolinks",
                    name: "browse-biolinks",
                    component: () => import("@/components/ListBiolinks.vue"),
                },
                {
                    path: "biolinks/:companyid(\\d+)",
                    name: "browse-biolinks-company",
                    component: () => import("@/components/ListBiolinks.vue"),
                } */
      ],
    },
    {
      path: "/browse/companies/add",
      component: () => import("@/views/CompanyAdd.vue"),
    },
    {
      path: "/browse/companies/edit/:id(\\d+)",
      component: () => import("@/views/CompanyEdit.vue"),
    },
    {
      path: "/browse/virtual-companies/add",
      component: () => import("@/views/VirtualCompanyAdd.vue"),
    },
    {
      path: "/browse/virtual-companies/edit/:id(\\d+)",
      component: () => import("@/views/VirtualCompanyEdit.vue"),
    },
    {
      path: "/browse/biolinks/edit/:id(\\d+)",
      component: () => import("@/views/BiolinkEdit.vue"),
    },
    {
      path: "/statistics",
      component: () => import("@/views/Statistics.vue"),
    },
    {
      path: "/users",
      component: () => import("@/views/Users.vue"),
    },
    {
      path: "/export",
      component: () => import("@/views/Export.vue"),
    },
    {
      path: "/our-members/add",
      component: () => import("@/views/OurMembersAdd.vue"),
    },
    {
      path: "/our-members/edit/:id(\\d+)",
      component: () => import("@/views/OurMembersEdit.vue"),
    },
    {
      path: "/our-cluster",
      component: () => import("@/views/OurCluster.vue"),
    },
    {
      path: "/overview",
      component: () => import("@/views/OurClusterOverview.vue"),
    },
    {
      path: "/network/companies",
      component: () => import("@/views/ClusterOrgCompanies.vue"),
      props: { showHeader: true },
    },
    // {
    //     path: "/members",
    //     component: () => import("@/views/ClusterNetwork.vue"),
    // },
    {
      path: "/my-folders",
      component: () => import("@/views/MyBookmarks.vue"),
    },
    {
      path: "/my-folders/:id(\\d+)",
      component: () => import("@/views/BookmarksListView.vue"),
    },
    {
      path: "/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("@/views/AboutView.vue"),
    },
    {
      path: "/:catchAll(.*)",
      component: () => import("@/views/PageNotFound.vue"),
    },
  ],
});

export default router;
