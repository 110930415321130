<template>
  <b-row class="search-bar" v-if="showHeader">
    <b-col sm="6" md="4" xxl="2">
      <b-row> Industry type</b-row>
      <b-row>
        <b-form-select
          :options="globalStore.company_types"
          v-on:change="membersStore.changeType"
        >
          <b-form-select-option value="">View all</b-form-select-option>
        </b-form-select>
      </b-row>
    </b-col>
    <b-col sm="6" md="4" xxl="2">
      <b-row> Country</b-row>
      <b-row>
        <b-form-select
          :options="globalStore.regions"
          v-on:change="membersStore.changeRegion"
        >
          <b-form-select-option value="">View all</b-form-select-option>
        </b-form-select>
      </b-row>
    </b-col>

    <b-col sm="6" md="4" xxl="2">
      <b-row> NACE Codes</b-row>
      <b-row>
        <v-select
          :filter="globalStore.naceFuseSearch"
          :options="globalStore.naces"
          :reduce="(nace: any) => nace.code"
          :get-option-label="(nace: any) => nace.text"
          v-model="naceSearch"
        >
        </v-select>
      </b-row>
    </b-col>
    <b-col sm="6" md="4" xxl="2">
      <b-row
        class="inline"
        title="Search in name and description of company, name and description of descriptors and general name of inputs/processes/outputs"
      >
        Search text <i class="bi bi-info-circle"></i
      ></b-row>
      <b-row>
        <b-form-input
          type="search"
          placeholder="Search"
          v-model="textSearch"
        ></b-form-input>
      </b-row>
    </b-col>

    <b-col align-self="end">
      <div>
        <button
          class="btn btn-primary"
          v-on:click="
            () => {
              resetFilters();
            }
          "
        >
          Reset Filters
        </button>
      </div>
    </b-col>
  </b-row>
  <b-row v-if="showHeader">
    <b-col style="text-align: right"
      >Display mode: List
      <div class="form-check form-switch inlineblock">
        <input
          class="form-check-input"
          type="checkbox"
          id="flexSwitchCheckDefault"
          v-model="showMap"
        />
        <label class="form-check-label" for="flexSwitchCheckDefault">Map</label>
      </div>
    </b-col>
  </b-row>
  <b-table-simple responsive v-if="membersStore.loaded && !showMap">
    <b-thead>
      <b-tr>
        <b-th></b-th>
        <b-th></b-th>
        <b-th @click="changeSort('name')"
          >Company
          <i
            v-if="membersStore.sortColumn === 'name'"
            :class="
              membersStore.sortOrder === 'asc'
                ? 'bi bi-arrow-down-short'
                : 'bi bi-arrow-up-short'
            "
          ></i>
        </b-th>
        <b-th @click="changeSort('type')"
          >Type
          <i
            v-if="membersStore.sortColumn === 'type'"
            :class="
              membersStore.sortOrder === 'asc'
                ? 'bi bi-arrow-down-short'
                : 'bi bi-arrow-up-short'
            "
          ></i>
        </b-th>
        <b-th @click="changeSort('country')"
          >Country
          <i
            v-if="membersStore.sortColumn === 'country'"
            :class="
              membersStore.sortOrder === 'asc'
                ? 'bi bi-arrow-down-short'
                : 'bi bi-arrow-up-short'
            "
          ></i>
        </b-th>
        <b-th @click="changeSort('iop_sum')" v-if="!compactView"
          >Activity
          <i
            v-if="membersStore.sortColumn === 'iop_sum'"
            :class="
              membersStore.sortOrder === 'asc'
                ? 'bi bi-arrow-down-short'
                : 'bi bi-arrow-up-short'
            "
          ></i>
        </b-th>
        <b-th @click="changeSort('revenue')" v-if="!compactView"
          >Revenue
          <i
            v-if="membersStore.sortColumn === 'revenue'"
            :class="
              membersStore.sortOrder === 'asc'
                ? 'bi bi-arrow-down-short'
                : 'bi bi-arrow-up-short'
            "
          ></i>
        </b-th>
        <!-- <b-th @click="changeSort('biolinks')" v-if="!compactView"
          >Projects
          <i
            v-if="membersStore.sortColumn === 'biolinks'"
            :class="
              membersStore.sortOrder === 'asc'
                ? 'bi bi-arrow-down-short'
                : 'bi bi-arrow-up-short'
            "
          ></i>
        </b-th> -->
        <b-th>Actions</b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <b-tr
        v-for="company of membersStore.companies"
        :key="company.id"
        class="valign-middle"
        v-on:click="() => globalStore.fetchAndShowCompanyModal(company.id)"
      >
        <b-td v-on:click.stop="">
          <b-form-checkbox
            v-model="company.checked"
            value="true"
            unchecked-value="false"
            @change="getCompanyids(company.id, company.checked)"
            :disabled="company.in_my_cluster == true"
          ></b-form-checkbox>
        </b-td>
        <b-td
          ><img
            class="rounded-circle"
            width="40"
            :src="company['logo_id'] == null ? API_URL+'thumbtail/150/F6821F/FFFFFF?text=' + (company['name'] as string).charAt(0) : getThumbtailLink(company['logo_id'])"
          />
        </b-td>
        <b-td
          >{{ company["name"] }}<br /><a
            class="website"
            target="_blank"
            :href="
              /^https?:/.test(company['website'])
                ? company['website']
                : 'https://' + company['website']
            "
            >{{ company["website"] }}</a
          ></b-td
        >
        <b-td>{{ company.company_type }}</b-td>
        <b-td>{{ company.country }}</b-td>
        <b-td v-if="!compactView">
          <!-- <span class="capacity capacity-inputs"
            >{{ company["inputs"] }} In</span
            >
            <span class="capacity capacity-outputs"
            >{{ company["outputs"] }} Out</span
            >
            <span class="capacity capacity-processes"
            >{{ company["processes"] }} Proc</span
            > -->
          {{
            globalStore
              .getNaceText(
                company.naces
                  .toLocaleString()
                  .split(",")
                  .filter((nace) => nace.length > 0)[0]
              )
              .split(/ (.*)/)[1]
          }}
        </b-td>
        <b-td>
          {{ formatNumber(company.revenue, company.revenue_currency) }}
          <!-- v-on:click.stop="" -->
          <!-- <b-button variant="success" v-on:click="graphListStore.fetchAndShowClusterModal(company.id,1)"  class="modal-button btn btn-success" style="width: 130px;">
          {{company.nb_solutions  }}&nbsp;Solutions</b-button> -->
        </b-td>
        <!-- <b-td v-if="!compactView">
          <span>{{
            company["biolinks"] > 0 ? company["biolinks"] + " BioLinks" : "None"
          }}</span
          ><br />
          <span v-if="company['biolinks'] > 0"
            >{{ company["public_biolinks"] }}&nbsp;Public |
            {{ company["private_biolinks"] }}&nbsp;Private</span
          >
        </b-td> -->
        <b-td
          v-if="!compactView"
          class="members-action-icons"
          v-on:click.stop=""
        >
          <b-button
            style="color: red"
            title="Remove this company from my cluster"
            v-on:click="() => removeCompanyFromMyCluster(company.id)"
            class="text-decoration-none btn-light"
          >
            <i
              class="bi bi-journal-x clickable-icon"
              style="color: red"
            />Remove
          </b-button>
          <!-- <router-link :to="'/our-members/edit/' + company['id']" v-if="showHeader" >
              <span><i class="bi bi-pen clickable-icon"/></span>
            </router-link> -->
        </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
  <!-- <h3 class="text-center" v-if="!membersStore.loaded && !showMap">
    Loading...
  </h3> -->
  <Loader v-if="!membersStore.loaded && !showMap" />
  <h3
    class="text-center"
    v-if="membersStore.loaded && membersStore.companies.length === 0"
  >
    No results found matching the search criteria. Please reset the filters and
    try again.
  </h3>
  <Divider v-if="membersStore.loaded" />
  <Map
    :datas="membersStore.companies_map"
    v-if="showMap"
    :showModal="globalStore.fetchAndShowCompanyModal"
  ></Map>
  <Pagination
    v-if="!showMap && membersStore.loaded"
    :crtPage="membersStore.crtPage"
    :totalPages="membersStore.nbPages"
    :onPageChange="membersStore.changePage"
  />
</template>
<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import Loader from "./loader/Loader.vue";
import Pagination from "@/components/global/Pagination.vue";
import { useMembersListStore } from "@/stores/members_list";
import { getThumbtailLink } from "@/utils/utils";
import { useGlobalDatasStore } from "@/stores/global_datas";
import { ModalType, openConfirmDialog } from "@/utils/utils";
import { API_URL } from "@/config";
import Map from "@/components/Map.vue";
import Divider from "@/components/global/Divider.vue";
import { useGraphsListStore } from "@/stores/graphs_list";
import { useCompaniesListStore } from "@/stores/companies_list";
const props = defineProps<{
  showAddButton: boolean;
  showHeader: boolean;
  compactView: boolean;
  clusterId: number;
}>();

const membersStore = useMembersListStore();
const graphListStore = useGraphsListStore();
const globalStore = useGlobalDatasStore();
const companiesStore = useCompaniesListStore();

const textSearch = ref("");
const naceSearch = ref("");
const regionSelected = ref("");

const showMap = ref(false);

watch(textSearch, (searchVal) => {
  membersStore.changeFreeText(searchVal);
});
watch(naceSearch, (naceVal) => {
  membersStore.changeNace(naceVal);
});
watch(regionSelected, (regionVal) => {
  membersStore.changeRegion(regionVal);
});

watch(
  () => props.clusterId,
  (clusterId) => {
    membersStore.setClusterId(clusterId);
    membersStore.fetchMembers();
  }
);

function resetFilters() {
  textSearch.value = "";
  naceSearch.value = "";
  regionSelected.value = "";
  membersStore.resetFilter();
}

onMounted(() => {
  showMap.value = false;
  membersStore.showMap = false;
  membersStore.setClusterId(props.clusterId);
  membersStore.fetchMembers();
  globalStore.fetchRegions();
  globalStore.fetchCompanyTypes();
  globalStore.fetchNaces();
  membersStore.loaded = true;
});

watch(showMap, (showMapVal, state) => {
  membersStore.showMap = showMapVal;
  membersStore.fetchMembers();
  return state;
});

function getCompanyids(id: number, checked: string) {
  console.log(checked, "companiesStore.companyIds");
  if (checked == "true") {
    companiesStore.companyIds.push(id);
    console.log(companiesStore.companyIds, "companiesStore.companyIds push");
  } else {
    companiesStore.companyIds = companiesStore.companyIds.filter(
      (x) => x !== id
    );
    console.log(companiesStore.companyIds, "companiesStore.companyIds pop");
  }
}

function changeSort(sortColumn: string) {
  if (membersStore.sortColumn === sortColumn)
    if (membersStore.sortOrder === "asc") membersStore.sortOrder = "desc";
    else membersStore.sortOrder = "asc";
  else {
    membersStore.sortColumn = sortColumn;
    membersStore.sortOrder = "asc";
  }
  membersStore.fetchMembers();
}

function formatNumber(num: number, value: string) {
  if (value && num != 0) {
    if (value == "USD") {
      value = "EUR";
      const amount = 0.92;
      num = Math.round(num * amount);
    }
    return num.toLocaleString() + " " + value;
  }
}

function removeCompanyFromMyCluster(company_id: number) {
  openConfirmDialog(
    "Delete company from your network confirmation",
    "Are you sure you want to delete this company from your network ?",
    ModalType.Danger
  ).then((res) => {
    if (res) {
      membersStore
        .deleteCompanyFromMyClusterAction(company_id)
        .then((res2) => {
          if ("error" in res2) {
            globalStore.showToast(res2["error"], "error");
          } else if ("message" in res2) {
            globalStore.showToast(res2["message"], "success");
            membersStore.fetchMembers();
          }
        })
        .catch(() => {
          globalStore.showToast(
            "Cannot remove this company from your network",
            "error"
          );
        });
    }
  });
}
</script>
<style>
.vue-map {
  height: 500px !important;
}

.capacity {
  padding: 0px 5px;
  margin: 5px;
  border-radius: 16px;
}

.inlineblock {
  display: inline-block;
  padding-left: 45px;
}

.capacity-inputs {
  color: var(--green-700);
  background-color: var(--green-50);
}

.capacity-outputs {
  color: var(--blue-600);
  background-color: var(--blue-50);
}

.capacity-processes {
  color: var(--gray-700);
  background-color: var(--gray-100);
}

.valign-middle {
  vertical-align: middle;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

.col-separator {
  flex: 0 0 100px;
}

.gray {
  color: var(--gray-500);
}

a.website {
  font-size: 12px;
  line-height: 18px;
  color: var(--gray-500) !important;
}

.members-action-icons {
  font-size: 18px;
  color: var(--gray-900);
}

.members-action-icons .bi {
  padding-right: 10px;
}

.inline {
  display: inline-block;
}
</style>
