import { defineStore } from "pinia";
import {
  deleteGraph,
  listBiolinksSummary,
  listGraphs,
  getGraph,
  getCircularPotential,
  getGraphDetails,
  getApplicationTags,
  getCompanyAppTags,
  listClusterBiolinks,
  getApplicationRegionTags,
  getProcessTags,
  getProcessRegionTags,
  getCompanyProcessTags,
  addModelToMyCluster,
  deleteModelToMyCluster,
  getCircularPotentialRegion,
  getCompanyAppRegionTags,
  getCompanyProcessRegionTags,
  getCircularCompanies,
  getCircularCompaniesRegion,
  getSolutionsWithNace,
  listClusterBiolinksModels,
} from "@/api/graph";

import { getBiolinkModels, getSolutions } from "@/api/company";
import { isClusterAdmin, isClusterUser, isSuperAdmin } from "@/utils/utils";
import { useGlobalDatasStore } from "./global_datas";
import { useClusterEditStore } from "./cluster_edit";
import { useLoader } from "./loader";

class GraphSummary {
  added_by_me = 0;
  id = 0;
  name = "";
  company_name = "";
  nb_nodes = 0;
  total_revenue = 0;
  feedstock_revenue = 0;
  created_at: Date = new Date();
  updated_at: Date = new Date();
  contributor = "";
  status = "";
  region = "";
  application_tags = "";
  process_tags = "";
  contributor_organisation = "";
}

class GraphDetails {
  id = 0;
  name = "";
  company_name = "";
  nb_nodes = 0;
  created_at: Date = new Date();
  updated_at: Date = new Date();
  contributor = "";
  status_value = "";
  region = "";
  description = "";
  contributor_organisation = "";
  creator_name = "";
  creator_surname = "";
  is_model = 0;
  support_request = "";
}

class ChartData {
  labels = ["Cat1", "Cat2", "Cat3"];
  datasets = [
    {
      label: "Circular Market Potential (No.of companies)",
      backgroundColor: ["#06B348", "#b6d9c3", "#b6d9c3", "#b6d9c3", "#b6d9c3"],
      data: [20, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11],
    },
  ];
}

class Classification {
  id = -1;
  nb_companies = "";
  tag = "";
}

class CompanyDetails {
  id = -1;
  tag = "";
  annual_revenue = 0;
  annual_revenue_currency = "";
  employees = 0;
  nb_companies = 0;
}

export const useGraphsListStore = defineStore({
  id: "graphs_list",
  state: () => ({
    addedByMe: 0,
    graphs: [] as GraphSummary[],
    graphsSaved: [],
    loaded: false,
    crtPage: 1,
    nbPages: 1,
    sortColumn: "name",
    sortOrder: "desc",
    visibilitySelected: "all",
    statusSelected: "all",
    isModel: 0,
    expandedNodes: {} as Record<string, boolean>,
    searchText: "",
    countrySearch: "",
    regionSearch: "",
    citySearch: "",
    naceSearch: null as null | string,
    modal_full_height: false,
    last_modal_type: "company",
    showModal: false,
    visible: true,
    graphNodes: [] as any[],
    graphNodesCompanies: [] as any[],
    graphDetails: new GraphDetails(),
    graphId: null as null | number,
    companies_map: [] as any[],
    tags: [] as Classification[],
    chartData: new ChartData(),
    showChart: false,
    company_details: new CompanyDetails(),
    company_tag: "",
    showModalGraph: false,
    graph: new GraphSummary(),
    showSolutions: false,
    companies: [],
    biolinks: [],
    solutions: [],
    applicationTagFilter: null as null | number,
    processTagFilter: null as null | number,
    checked: true,
    processSelected: false,
    applicationSelected: false,
    is_solution_provider: 0,
    companyFilter: null as null | string,
    linksLoad: false,
    loader: useLoader(),
  }),
  actions: {
    setNodeVisibility(nodeId: string, isVisible: boolean) {
      this.expandedNodes[nodeId] = isVisible;
    },
    isNodeVisible(nodeId: string) {
      return !!this.expandedNodes[nodeId];
    },
    resetFilter() {
      this.visible = true;
      this.visibilitySelected = "all";
      this.statusSelected = "all";
      this.searchText = "";
      this.sortOrder = "desc";
      this.sortColumn = "name";
      this.crtPage = 1;
      this.naceSearch = null;
      this.applicationTagFilter = null;
      this.processTagFilter = null;
      this.processSelected = false;
      this.applicationSelected = false;
      this.companyFilter = "";
      this.fetchGraphs();
    },
    resetFilterWithoutFetch() {
      this.visible = true;
      this.visibilitySelected = "all";
      this.statusSelected = "all";
      this.searchText = "";
      this.sortOrder = "desc";
      this.sortColumn = "name";
      this.crtPage = 1;
      this.naceSearch = null;
      this.applicationTagFilter = null;
      this.processTagFilter = null;
      this.processSelected = false;
      this.applicationSelected = false;
      this.companyFilter = "";
    },
    resetRegionFilters() {
      this.regionSearch = "";
      this.countrySearch = "";
      this.citySearch = "";
    },
    async fetchGraphs() {
      this.loader.add();
      this.loaded = false;
      const params: { [id: string]: unknown } = {
        page: this.crtPage,
        perPage: 20,
        sortColumn: this.sortColumn,
        sortOrder: this.sortOrder,
        visibility: this.visibilitySelected,
        status: this.statusSelected,
        model: this.isModel,
      };
      if (this.companyFilter) {
        params["companyFilter"] = this.companyFilter;
      }
      if (this.searchText) {
        params["searchText"] = this.searchText;
      }
      if (this.naceSearch) {
        params["naceSearch"] = this.naceSearch;
      }
      if (this.applicationTagFilter && this.applicationSelected) {
        params["applicationTagFilter"] = this.applicationTagFilter;
      }
      if (this.processTagFilter && this.processSelected) {
        params["processTagFilter"] = this.processTagFilter;
      }
      if (this.countrySearch) {
        params["countryFilter"] = this.countrySearch;
      }
      if (this.regionSearch) {
        params["regionFilter"] = this.regionSearch;
      }
      if (this.citySearch) {
        params["cityFilter"] = this.citySearch;
      }
      if (this.companyFilter && this.isModel && this.linksLoad) {
        this.fetchAndShowModal(this.companyFilter, this.isModel, true);
      }
      if (
        !isSuperAdmin(useGlobalDatasStore().logged_user.usertype_id) &&
        this.isModel == 0
      ) {
        try {
          await useClusterEditStore().fetchOurCluster();

          if (useClusterEditStore().cluster.id != -1) {

             const graphs = await listClusterBiolinks(
              params,
              useClusterEditStore().cluster.id
            );
            console.log(graphs, "graphs received");
            this.graphs = graphs.items;
            this.nbPages = graphs.nb_pages;
            this.crtPage = graphs.page;
            this.loaded = true;
            this.loader.done();
          }
        } catch (error) {
          console.error("Greška prilikom dobijanja biolinks klastera:", error);
          this.loader.done();
        }
      } else {
        try {
          const graphs = await listGraphs(params);
          this.graphs = graphs ? graphs.items : [];
          this.nbPages = graphs ? graphs.nb_pages : 0;
          this.crtPage = graphs ? graphs.page : 0;
          this.loaded = true;
        } catch (error) {
          console.error("Greška prilikom dobijanja grafova:", error);
          this.graphs = [];
        } finally {
          this.loader.done();
        }
      }
    },

    async changePage(newPage: number) {
      this.crtPage = newPage;
      this.fetchGraphs();
    },
    async changePageModels(newPage: number) {
      this.crtPage = newPage;
      this.linksLoad = true;
      this.fetchGraphs();
      this.linksLoad = false;
    },
    async changeVisivility(newVisibility: string) {
      this.visibilitySelected = newVisibility;
      //this.fetchGraphs();
      this.changePage(1);
    },
    async changeStatusFilter(newStatus: string) {
      //this.statusSelected = newStatus;
      console.log(newStatus);
      //this.fetchGraphs();
      this.changePage(1);
    },
    async changeTextSearch(newText: string) {
      this.searchText = newText.trim();
      //this.fetchGraphs();
      this.changePage(1);
    },
    async changeNace(newNace: string | null) {
      this.naceSearch = newNace;
      this.changePage(1);
    },
    async deleteGraphAction(graph_id: number) {
      return await deleteGraph(graph_id);
    },

    async fetchAndShowModal(id: string, model: number, full_height = false) {
      if (model != this.isModel) {
        this.crtPage = 1;
      }
      const params: { [id: string]: unknown } = {
        page: this.crtPage,
        perPage: 20,
        sortColumn: this.sortColumn,
        sortOrder: this.sortOrder,

        model: this.isModel,
      };

      this.companyFilter = id;
      if (this.companyFilter != null) {
        params["companyFilter"] = this.companyFilter;
      }

      this.isModel = model;
      if (this.isModel != null) {
        params["model"] = this.isModel;
      }

      const biolinks = await listBiolinksSummary(params);
      console.log(biolinks, this.isModel, "bbb");
      this.modal_full_height = full_height;
      this.graphs = biolinks.items;
      this.nbPages = biolinks.nb_pages;
      this.crtPage = biolinks.page;
      this.last_modal_type = "graph";
      this.showModal = true;
      this.loaded = true;
    },
    async hideAndShowModal() {
      this.showModal = false;
      this.graphs = [];
    },
    async fetchAndShowGraph(id: number, full_height = false) {
      const biolinks = await getGraph(id);

      this.modal_full_height = full_height;
      this.graph = biolinks;
      this.last_modal_type = "graph-view";
      this.showModalGraph = true;
      this.loaded = true;
    },
    async hideAndShowGraph() {
      this.showModalGraph = false;
    },
    async fetchSavedProjects(force = false) {
      if (this.graphs.length == 0 || (force && this.isModel == 0)) {
        const params: { [id: string]: unknown } = {
          page: this.crtPage,
          perPage: 1000,
          sortColumn: this.sortColumn,
          sortOrder: this.sortOrder,
          visibility: this.visibilitySelected,
          status: this.statusSelected,
          model: this.isModel,
        };
        const graphs = await listGraphs(params);
        if (graphs.items) {
          this.graphsSaved = graphs.items.map(
            (item: { name: string; id: number }) => {
              return {
                text: item.name,
                value: item.id,
              };
            }
          );
        }
      }
    },
    async fetchAndShowClusterModal(company_id: number, model: number) {
      //show modal

      //const biolinks = await listBiolinksSummary(company_id,model);
      //this.graphs = biolinks.items;

      console.log(this.graphs, "graph");
      this.modal_full_height = true;
      this.showSolutions = true;
      this.last_modal_type = "solutions";
      this.loader.add();
      this.loaded = true;

      //code for solutions
      const id = company_id.toString();
      const solutions = await getSolutions(id);
      console.log(solutions, "solutions");
      this.solutions = solutions;
      this.solutions = solutions.map(
        (item: { vc_id: number; vc_name: string }) => {
          return {
            name: item.vc_name,
            id: item.vc_id,
          };
        }
      );
      const biolinks = await getBiolinkModels(id);
      this.graphs = biolinks;
      console.log(biolinks, "biolinks");
      this.loaded = true;
      this.loader.done();
      //code for companies

      this.companies = solutions.map(
        (item: {
          company_name: string;
          company_id: number;
          company_website: string;
        }) => {
          return {
            name: item.company_name,
            website: item.company_website,
            id: item.company_id,
          };
        }
      );
    },
    async hideClusterModal() {
      //show modal
      this.modal_full_height = false;
      this.showSolutions = false;
    },

    async changeRegion(newRegion: string) {
      this.regionSearch = newRegion;
      //this.fetchGraphs();
      console.log(this.fetchGraphs);
      this.changePage(1);
    },
    async changeCountry(newCountry: string) {
      this.countrySearch = newCountry;
      this.changePage(1);
    },
    async fetchCircularPotential(
      graph_id: number,
      region: string,
      include: string
    ) {
      this.loader.add();
      this.loaded = false;
      let markets = null;
      const params: { [id: string]: unknown } = {
        include,
      };
        if(this.countrySearch) params['countryFilter'] = this.countrySearch
        if(this.regionSearch) params['regionFilter'] = this.regionSearch
        if(this.citySearch) params['cityFilter'] = this.citySearch
      markets = await getCircularPotential(graph_id, params);
      this.graphNodes = markets;
      this.graphNodesCompanies = [];
      for (let i = 0; i < markets.length; i++) {
        const nodeCompany = await this.fetchNodesCompanies(
          i,
          graph_id,
          false,
          region,
          1,
          include,
          
        );
        this.graphNodesCompanies.push(nodeCompany);
      }
      const details = await getGraphDetails(graph_id);
      this.graphDetails = details;

      this.companies_map = [];
      markets.forEach((item: { companies: any[] }) => {
        return this.companies_map.push(item.companies);
      });

      this.loaded = true;
      this.loader.done();
    },

    async fetchNodesCompanies(
      index: number,
      graph_id: number,
      addOnly: boolean,
      region: string,
      pageNumber = 1,
      include = "all"
    ) {
      this.loader.add();
      this.loaded = false;
      const params: { [id: string]: unknown } = {
        page: pageNumber,
        sortColumn: 'annual_revenue',
        sortOrder: this.sortOrder,
        include,
      };
              if(this.countrySearch) params['countryFilter'] = this.countrySearch
        if(this.regionSearch) params['regionFilter'] = this.regionSearch
        if(this.citySearch) params['cityFilter'] = this.citySearch
      const company = await getCircularCompanies(
        graph_id,
        this.graphNodes[index].id,
          params,
      );

      if (addOnly) {
        this.graphNodesCompanies[index] = company;
      } else {
        return company;
      }
      // else {
      //   const company = await getCircularCompaniesRegion(
      //     graph_id,
      //     region,
      //     this.graphNodes[index].id,
      //     params
      //   );
      //   if (addOnly) {
      //     this.graphNodesCompanies[index] = company;
      //   } else {
      //     return company;
      //   }
      // }
      this.loader.done();
      this.loaded = true;
    },

    async leavePreviousValue() {
      this.graphNodes = [];
      this.graphDetails = new GraphDetails();
      this.companies_map = [];
      this.graphNodesCompanies = [];
    },
    async changeCountryGraph(newCountry: string) {
      this.countrySearch = newCountry;
      this.showChart = false;

      if (this.countrySearch == "" && this.applicationSelected) {
        this.tags = await getApplicationTags();
      }
      if (this.countrySearch != "" && this.applicationSelected) {
        this.tags = await getApplicationRegionTags(newCountry);
      }
      if (this.countrySearch == "" && this.processSelected) {
        this.tags = await getProcessTags();
      }
      if (this.countrySearch != "" && this.processSelected) {
        this.tags = await getProcessRegionTags(newCountry);
      }

      this.chartData.labels = this.tags.map((item: { tag: string }) => {
        return item.tag;
      });

      const obj = this.chartData.datasets;

      console.log(obj, "hi");
      const dataValue = this.tags.map((data: { nb_companies: string }) => {
        // const data_comp = parseInt(data.nb_companies, 10);
        // (data_comp*(1/100)).toString();
        // return (data_comp*(1/100)).toString() ;
        return data.nb_companies;
      });

      console.log(dataValue, "dataValue");
      Object.assign(obj[0], { data: dataValue });
      console.log(obj[0], this.chartData.datasets);
      this.showChart = true;

      this.handleClick(0);
    },

    async handleClick(id: number) {
      const tagId = this.tags[id]?.id;

      if (this.countrySearch == "" && this.applicationSelected) {
        const appCompany = await getCompanyAppTags(tagId);
        this.company_details = appCompany;
        this.applicationTagFilter = tagId;
        this.processTagFilter = null;
      }
      if (this.countrySearch != "" && this.applicationSelected) {
        const appCompany = await getCompanyAppRegionTags(
          tagId,
          this.countrySearch
        );
        this.company_details = appCompany;
        this.applicationTagFilter = tagId;
        this.processTagFilter = null;
      }
      if (this.countrySearch == "" && this.processSelected) {
        const processCompany = await getCompanyProcessTags(tagId);
        console.log(processCompany, tagId, "hi");
        this.company_details = processCompany;
        this.processTagFilter = tagId;
        this.applicationTagFilter = null;
      }
      if (this.countrySearch != "" && this.processSelected) {
        const processCompany = await getCompanyProcessRegionTags(
          tagId,
          this.countrySearch
        );
        this.company_details = processCompany;
        this.processTagFilter = tagId;
        this.applicationTagFilter = null;
      }
      this.fetchGraphs();
    },

    async toggleProcessSelected() {
      this.processSelected = true;
      this.applicationSelected = false;
      await this.changeCountryGraph(this.countrySearch);
    },
    async toggleApplicationSelected() {
      this.applicationSelected = true;
      this.processSelected = false;
      await this.changeCountryGraph(this.countrySearch);
    },
    async toggleAllSelected() {
      this.processSelected = false;
      this.applicationSelected = false;
      await this.changeCountryGraph(this.countrySearch);
    },

    async fetchClusterSavedGraphs() {
      this.loader.add();
      this.loaded = false;
      const params: { [id: string]: unknown } = {
        page: this.crtPage,
        perPage: 20,
        sortColumn: this.sortColumn,
        sortOrder: this.sortOrder,
        visibility: this.visibilitySelected,
        status: this.statusSelected,
        model: this.isModel,
      };
      if (this.searchText != "") {
        params["searchText"] = this.searchText;
      }

      if (this.naceSearch != null && this.naceSearch != "") {
        params["naceSearch"] = this.naceSearch;
      }
      if (this.regionSearch != null && this.regionSearch != "") {
        params["regionFilter"] = this.regionSearch;
      }

      if (
        isClusterAdmin(useGlobalDatasStore().logged_user.usertype_id) ||
        isClusterUser(useGlobalDatasStore().logged_user.usertype_id)
      ) {
        await useClusterEditStore().fetchOurCluster();
        console.log(
          useClusterEditStore().cluster,
          "useClusterEditStore().clusterOverview.id"
        );
        const graphs = await listClusterBiolinks(
          params,
          useClusterEditStore().cluster.id
        );
        this.graphs = graphs.items;
        this.nbPages = graphs.nb_pages;
        this.crtPage = graphs.page;
        this.loaded = true;
        this.loader.done();
      }
    },

    async addModelToMyClusterAction(id: number) {
      return await addModelToMyCluster(id);
    },
    async removeModelFromMyClusterAction(graph_id: number) {
      return await deleteModelToMyCluster(graph_id);
    },

    async fetchGraphWithNaces(code: string) {
      const graphs = await getSolutionsWithNace(code);
      this.graphs = graphs;
      this.nbPages = graphs.nb_pages;
      this.crtPage = graphs.page;
      this.loaded = true;
    },
  },
});
