import type { IntlNumberFormat } from "vue-i18n";

import { Locales } from "@/utils/locales";
import type { NumberFormatOptions } from "@intlify/core-base";

export function useNumberFormats() {
  const currencyFormat: NumberFormatOptions = {
    style: "currency",
    currency: "EUR",
    notation: "standard",
  };

  const currencyCompactFormat: NumberFormatOptions = {
    style: "currency",
    currency: "EUR",
    notation: "compact",
  };

  const decimalExactFormat: NumberFormatOptions = {
    style: "decimal",
  };

  const decimalFormat: NumberFormatOptions = {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const percentFormat: NumberFormatOptions = {
    style: "percent",
    useGrouping: false,
  };

  const percentExactFormat: NumberFormatOptions = {
    style: "percent",
    useGrouping: false,
    minimumFractionDigits: 2,
  };

  const numberFormats: {
    [key in Locales]: IntlNumberFormat;
  } = {
    [Locales.EN]: {
      currency: currencyFormat,
      "currency-compact": currencyCompactFormat,
      "decimal-exact": decimalExactFormat,
      decimal: decimalFormat,
      percent: percentFormat,
      "percent-exact": percentExactFormat,
    },
    [Locales.DE]: {
      currency: currencyFormat,
      "currency-compact": currencyCompactFormat,
      "decimal-exact": decimalExactFormat,
      decimal: decimalFormat,
      percent: percentFormat,
      "percent-exact": percentExactFormat,
    },
  };

  return { numberFormats };
}
