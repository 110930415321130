<template>
  <OkCancelBox
    :value="true"
    ok-label="YES"
    cancel-label="NO"
    :modalType="modalType"
  >
    <template #header>{{ title }}</template>
    <template #body>
      <div style="padding: 20px">
        {{ message }}
      </div>
    </template>
  </OkCancelBox>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import OkCancelBox from "@/components/global/confirm-modal/OkCancelBox.vue";
import type { ModalType } from "@/utils/utils";

export default defineComponent({
  components: { OkCancelBox },
  props: {
    title: String,
    message: String,
    modalType: String as PropType<ModalType>,
  },
  methods: {
    returnValue() {
      return true;
    },
  },
});
</script>

<style scoped lang="scss"></style>
